export const APP_FORMATTER = {
  decimalOptions: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
  currencyOptions: {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'symbol', // symbol | code | name
  },
};
