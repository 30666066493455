import { Injectable } from '@angular/core';
import { DataTransformAbstractUp } from 'app/modules/global/classes/data.transform.abstract.up';
import { FeedElementInterface } from '../../pages/feed.item.element/feed.item.element.interface';
import { FieldInterface } from 'app/modules/common/inputs/custom-fields/fields/fields.interface';

@Injectable({
  providedIn: 'root',
})
export class FeedElementDataTransformUp extends DataTransformAbstractUp {
  /**
   *Creates an instance of FeedDataTransformUp.
   * @memberof FeedDataTransformUp
   */
  constructor() {
    super();
  }
  /**
   *
   *
   * @param {FeedInterface} feed
   * @returns
   * @memberof FeedDataTransform
   */
  transform(el: FeedElementInterface): FeedElementInterface {
    if (el.feed) {
      this.normalizeFields(el.feed.fields, el);
    }
    return el;
  }
  /**
   *
   *
   * @protected
   * @param {FieldInterface[]} contents
   * @returns {FieldInterface[]}
   * @memberof FeedDataTransformUp
   */
  protected normalizeFields(fields: FieldInterface[], element: FeedElementInterface): FieldInterface[] {
    if (!Array.isArray(fields)) {
      return fields;
    }
    fields.map((field: FieldInterface) => {

      // // Formly v6
      // field.props = field.templateOptions;
      // delete field.templateOptions;

      if (field.type === 'input' && element.data && element.data[field.key]) {
        // FIX DATE BASED TYPES
        if (field.templateOptions.type === 'time') {
          element.data[field.key] = this.removeUTC(element.data[field.key].toString());
        }
        if (field.templateOptions.type === 'month') {
          element.data[field.key] = this.normalizeMonth(element.data[field.key].toString());
        }
        // if (field.templateOptions.type === 'date') {
        //   console.log('>>> DOWNLOAD element date transform', element.data[field.key]);
        //   // element.data[field.key] = this.normalizeDate(element.data[field.key].toString());
        // }
      }
      // REMOVE INVALID IMAGE ([])
      if (field.type === 'image' && Array.isArray(element.data[field.key])) {
        delete element.data[field.key];
      }
    });

    console.log('FEED ELEMENT GET TRANSFORM', fields);

    return fields;
  }
}
