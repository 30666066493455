import { FormControl, FormArray } from '@angular/forms';
import { WeekdayInterface } from '../datetime/datetime.interface';

// TODO: sync with scheduling.model.ts
enum SchedulingEnum {
  none = 'none',
  daily = 'daily',
  weekly = 'weekly',
  calendar = 'calendar',
  '24_7' = '24_7',
  disabled = 'disabled',
  poweredOff = 'poweredOff',
}

export interface TimeslotInterface {
  from?: string | Date;
  to?: string | Date;
  startAt?: string | Date;
  endAt?: string | Date;
  alwaysOn?: boolean;
  // [key: string]: any;
}

export class Timeslot implements TimeslotInterface {
  from?: string | Date;
  to?: string | Date;
  startAt?: string | Date;
  endAt?: string | Date;
  alwaysOn?: boolean;

  constructor(private _t?: TimeslotInterface) {
    // for (const k in this._t) {
    //   if (this._t.hasOwnProperty(k) && k !== 'alwaysOn') {
    //     this._t[k] = new Date(this._t[k]);
    //   }
    // }

    Object.assign(this, this._t);
    delete this._t;
  }
}

export interface TimeslotOptionsInterface {
  fromField?: string;
  toField?: string;
  validators?: any;
}

export class TimeslotOptions {
  fromField: string = 'from';
  toField: string = 'to';
  validators: any;

  constructor(private _t: TimeslotOptionsInterface) {
    Object.assign(this, this._t);
    delete this._t;
  }
}

export interface BrightnessSchedulingInterface {
  range?: TimeslotInterface;
  value?: number;
}

export interface WeeklySchedulingInterface extends WeekdayInterface {
  name?: string;
  weekday?: number;
  alwaysOn?: boolean;
  timeslots?: TimeslotInterface[];
}

export class WeeklyScheduling implements WeeklySchedulingInterface {
  name: string;
  weekday: number;
  alwaysOn: boolean = true;
  timeslots: TimeslotInterface[] = [];

  constructor(private _wS?: WeeklySchedulingInterface) {
    Object.assign(this, this._wS);
    this.timeslots = this.timeslots.map((ts, i) => {
      return new Timeslot(ts);
    });
    delete this._wS;
  }
}

export interface TimeslotAlwaysItemInterface {
  from?: Date | string;
  to?: Date | string;
  alwaysOn?: boolean;
}

export class TimeslotAlwaysItem {
  from: Date | string;
  to: Date | string;
  alwaysOn: boolean = false;

  constructor(private _conf?: TimeslotAlwaysItemInterface) {
    Object.assign(this, this._conf);

    // true default
    // if (!this.from && !this.to) {
    //   this.alwaysOn = true;
    // }

    delete this._conf;
  }
}

export interface TimeslotMinutesItemInterface {
  once?: number;
  timeslots?: TimeslotAlwaysItemInterface;
}

export class TimeslotMinutesItem {
  once: number = 0;
  timeslots: TimeslotAlwaysItemInterface = {};

  constructor(private _conf?: TimeslotMinutesItemInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export interface TimeslotsSchedulingInterface {
  multi?: boolean;
  schedulingType?: FormControl;
  timeslots?: FormArray;
  weeklyScheduling?: FormArray;
  calendarScheduling?: FormArray;
  min?: Date | string;
  max?: Date | string;
}

export interface TimeslotsCalendarInterface {
  startAt?: Date | string;
  endAt?: Date | string;
  default?: boolean;
  type?: SchedulingEnum;
  timeslots?: TimeslotInterface[];
  weeklySchedulings?: WeeklySchedulingInterface[];
}

export class TimeslotsCalendar implements TimeslotsCalendarInterface {
  startAt: Date | string;
  endAt: Date | string;
  type: SchedulingEnum = SchedulingEnum['24_7'];
  default: boolean;
  timeslots: TimeslotInterface[] = [];
  weeklySchedulings: WeeklySchedulingInterface[] = [];

  constructor(private _conf?: TimeslotsCalendarInterface) {
    Object.assign(this, this._conf);

    if (this.startAt) {
      this.startAt = new Date(this.startAt);
    }

    if (this.endAt) {
      this.endAt = new Date(this.endAt);
    }

    this.timeslots = this.timeslots.map((ts) => {
      return new Timeslot(ts);
    });

    this.weeklySchedulings = this.weeklySchedulings.map(week => {
      week = new WeeklyScheduling(week);
      return week;
    });

    delete this._conf;
  }
}
