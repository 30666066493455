import { FormArray, FormGroup } from '@angular/forms';

export interface DateRangeInterface {
  startAt?: string | Date;
  endAt?: string | Date;
  startTimeAt?: string | Date;
  endTimeAt?: string | Date;
}

export class DateRange implements DateRangeInterface {
  startAt: string | Date;
  endAt: string | Date;
  startTimeAt?: string | Date;
  endTimeAt?: string | Date;

  constructor(private _conf?: DateRangeInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export interface DatesRangeInterface {
  inMultiSelection: boolean;
  rangeCtrl?: FormArray;
  min?: Date | string;
  max?: Date | string;
  range?: DateRangeInterface[];
  required?: boolean;
  allRequired?: boolean;
  hasTime?: boolean;
}

export interface DateslotDialogDataInterface {
  group?: FormGroup;
  min?: Date | string;
  max?: Date | string;
  required?: boolean;
  hasTime?: boolean;
}
