import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthRepositoryService {

  /**
   *Creates an instance of AuthRepositoryService.
   * @param {HttpClient} _http
   * @memberof AuthRepositoryService
   */
  constructor(private _http: HttpClient) { }

  /**
   *
   *
   * @param {string} username
   * @param {string} password
   * @param {boolean} remember
   * @returns {Observable<any>}
   * @memberof AuthRepositoryService
   */
  loginCheck(_username: string, _password: string, _remember: boolean): Observable<any> {
    return this._http
      .post<any>('auth/login_check', {
        username : _username,
        password : _password,
        remember: _remember
      });
  }

  getPermissions(): Observable<any> {
    return this._http.get(`auth/init/permissions`);
  }

  /**
   *
   *
   * @param {string} email
   * @returns {Observable<any>}
   * @memberof AuthRepositoryService
   */
  recoverPassword(email: string): Observable<any> {
    return this._http
      .post<any>('auth/password/reset', {
        email : email
      });
  }

  confirmationResetPassword(token: string): Observable<any> {
      return this._http.get<any>(`auth/password/user-confirmation/${token}`);
  }

  /**
   *
   *
   * @private
   * @returns {Observable<any>}
   * @memberof AuthRepositoryService
   */
  getRemoteUserMe(): Observable<any> {
    //todo: return User type;
    return this._http.get('user/me');
  }

}
