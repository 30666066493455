import { NgModule } from '@angular/core';
import { GlobalModule } from 'app/modules/global/global.module';
import { ColorSquareComponent } from './color-square/color-square.component';
import { StatusDotComponent } from './status-dot/status-dot.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ScreenPreviewComponent } from './screen-preview/screen-preview.component';
import { StringModule } from '../string/string.module';
import { CoordinatesComponent } from './coordinates/coordinates.component';
import { LicenseIconComponent } from './license-icon/license-icon.component';
import { DatetimeModule } from 'app/modules/common/datetime/datetime.module';
import { DropdownComponent } from './dropdown/dropdown.component';
import { RouterModule } from '@angular/router';
import { FirmwareStatusIconComponent } from './firmware-status-icon/firmware-status-icon.component';
import { BoolComponent } from './bool/bool.component';
import { IconModule } from 'app/modules/global/components/icon/icon.module';
import { IntervalUpdaterComponent } from './interval-updater/interval-updater.component';
import { MonitorStatusIconComponent } from './monitor-status-icon/monitor-status-icon.component';
import { SystemHeadingComponent } from './system-heading/system-heading.component';

@NgModule({
  declarations: [
    CoordinatesComponent,
    ColorSquareComponent,
    StatusDotComponent,
    MonitorStatusIconComponent,
    SpinnerComponent,
    ScreenPreviewComponent,
    LicenseIconComponent,
    FirmwareStatusIconComponent,
    BoolComponent,
    IntervalUpdaterComponent,
    SystemHeadingComponent,
    DropdownComponent,
  ],
  imports: [GlobalModule, RouterModule, StringModule, DatetimeModule, IconModule],
  exports: [
    CoordinatesComponent,
    ColorSquareComponent,
    StatusDotComponent,
    MonitorStatusIconComponent,
    SpinnerComponent,
    ScreenPreviewComponent,
    LicenseIconComponent,
    FirmwareStatusIconComponent,
    BoolComponent,
    IntervalUpdaterComponent,
    SystemHeadingComponent,
    DropdownComponent,
  ],
})
export class UtilsModule { }
