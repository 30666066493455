import { Injectable } from '@angular/core';
import { FuseNavigationService } from '../../../@fuse/components/navigation/navigation.service';
import { Observable, of } from 'rxjs';
import { MapNavigationPermissions } from '../navigation-permission';

@Injectable({
  providedIn: 'root'
})
export class NavPermissionService {

  /**
   * @param {FuseNavigationService} _fuseNavigationService
   */
  constructor(private _fuseNavigationService: FuseNavigationService) {
  }

  /**
   * @param grants
   */
  updateNavigationFromPermissions(grants: string[]): Observable<string[]> {
    // console.log('updateNavigationFromPermissions', JSON.parse(localStorage.getItem('allPermissions')));
    const permsLog = [];
    const data = of(
      JSON.parse(localStorage.getItem('allPermissions')
      ).map(
        x => x.name
      ).filter(
        x => !grants.includes(x)).forEach(
          (elem) => {
            if (Object.keys(MapNavigationPermissions).includes(elem)) {
              permsLog.push(MapNavigationPermissions[elem]);
              this._fuseNavigationService.updateNavigationItem(MapNavigationPermissions[elem], {
                hidden: true
              });
            }
          })
    );
    // console.log('removed from menu: ', permsLog);
    return data;
  }

}
