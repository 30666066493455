import { Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, ViewContainerRef } from '@angular/core';

/**
 *
 *
 * @export
 * @class HintTarasComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-hint-component-injector',
  templateUrl: './hint-component-injector.component.html',
})
export class HintComponentInjectorComponent implements OnInit {
  @Input() component: any | [any, any];

  constructor(private target: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    if (this.component) {
      let component, params;
      if (Array.isArray(this.component)) {
        component = this.component[0];
        params = this.component[1];
      } else {
        component = this.component;
      }

      console.log('comddd', component, params);

      const factory = this.componentFactoryResolver.resolveComponentFactory(component);
      const cmpRef = this.target.createComponent(factory);
      Object.assign(cmpRef.instance, params);
    }
  }
}
