import { Component, OnInit, Input, OnChanges, ContentChild, AfterContentInit, ViewChild, ElementRef, Renderer2, AfterViewInit, ViewContainerRef, ChangeDetectionStrategy, ChangeDetectorRef, } from '@angular/core';
import { IconsService, ICON_TYPE } from './icons.service';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit, OnChanges {
  @Input() icon: string;
  @Input() color: string;
  @Input() badge: string;
  @Input() badgeColor: string;

  svgIcon: string; // = 'reservations';
  normalIcon: string; // = 'close';

  // @ContentChild(Element, { read: Text, static: true }) innerIcon: Text | undefined;
  // @ViewChild('innerIcon', { static: false }) innerIcon;
  // constructor(private elt: ElementRef, private _vcf: ViewContainerRef, private renderer: Renderer2) {}

  constructor(private _iconService: IconsService, private _cdr: ChangeDetectorRef) {}

  ngOnInit() {
    const type = this._iconService.getType(this.icon);
    if (type === ICON_TYPE.svg) {
      this.svgIcon = this._iconService.getSvgIcon(this.icon);
    } else {
      this.normalIcon = this._iconService.getIcon(this.icon);
    }
    // console.log('ngOnInit Content text: ', this.innerIcon, this.icon);
  }

  // ngAfterViewInit() {
  //   var textNode = this.elt.nativeElement.childNodes[0];
  //   var textInput = textNode.nodeValue;
  //   console.log(textNode, textInput);

  //   // this._vcf.
  //   // this.renderer.appendChild(textNode, textInput.toUpperCase());
  // }

  // ngAfterContentInit() {
  //   setTimeout(() => {
  //     console.log('ngAfterContentInit Content text: ', this.innerIcon.nativeElement.innerText, this.icon);
  //   });
  // }

  ngOnChanges(changes) {
    if (changes.icon && !changes.icon.firstChange) {
      this._cdr.detectChanges();
    }
  }
}
