import { Injectable } from '@angular/core';
import {AuthRepositoryService} from '../../../repositories/auth-repository.service';

export interface UserPermission {
  id: string;
  name: string;
  title: string;
  category: string;
  level: number;
}

@Injectable({
  providedIn: 'root'
})
export class InitPermissionService {

  userPermissions: UserPermission[]; // TARAS: useless for now

  constructor(private _authRepositoryService: AuthRepositoryService) { }

  load(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this._authRepositoryService.getPermissions().subscribe((permissions: UserPermission[]) => {
        // console.log('all permissions downlaoded from SERVER');
        localStorage.setItem('allPermissions', JSON.stringify(permissions));
        this.userPermissions = permissions;
        resolve(true);
      });
    });
  }

}
