import { NgModule } from '@angular/core';
import { GlobalModule } from 'app/modules/global/global.module';
import { FilterFieldComponent } from './filter-field.component';
@NgModule({
    declarations: [
        FilterFieldComponent
    ],
    imports: [
        GlobalModule
    ],
    exports: [
        FilterFieldComponent
    ]
})
export class FilterFieldComponentModule {}
