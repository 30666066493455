<div id="forgot-password" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
  <div id="forgot-password-intro" fxFlex fxHide fxShow.gt-xs>
    <div class="logo" [@animate]="{ value: '*', params: { scale: '0.2' } }">
      <img src="assets/images/logos/fluid-logo.png" />
    </div>

    <div class="title" [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }">
      {{ 'Benvenuto su FLUID!' | translate }}
    </div>

    <div class="description" [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }">
      {{
        "Invieremo un'e-mail con un link per creare una nuova password (accertati di controllare anche la tua cartella
      spam/posta indesiderata)."
          | translate
      }}
      {{ 'Se non ricevi nulla contattare email' | translate }}
      <a [href]="'mailto:' + SUPPORT_EMAIL">{{ SUPPORT_EMAIL }}</a>
    </div>
  </div>

  <div id="forgot-password-form-wrapper" fusePerfectScrollbar
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }" fxLayout="column">
    <div fxFlex id="forgot-password-form">
      <div *ngIf="!recoveryMode">
        <div class="logo" fxHide.gt-xs>
          <img src="assets/images/logos/fluid-logo-alt.png" />
        </div>

        <div class="title">{{ 'Recupero Password' | translate }}</div>

        <div fxHide.gt-xs class="description" [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }">
          {{
            "Invieremo un'e-mail con un link per creare una nuova password (accertati di controllare anche la tua cartella
          spam/posta indesiderata)."
              | translate
          }}
          {{ 'Se non ricevi nulla contattare email' | translate }}
          <a [href]="'mailto:' + SUPPORT_EMAIL">{{ SUPPORT_EMAIL }}</a>
        </div>

        <form name="forgoPasswordForm" [formGroup]="forgotPasswordForm" novalidate>
          <mat-form-field>
            <input name="email" required matInput [placeholder]="'Email' | translate" formControlName="email"
              (keypress)="keypressEvent($event)" />
            <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
              {{ 'Obbligatorio' | translate }}
            </mat-error>
            <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
              {{ 'Indirizzo email non valido' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-error *ngIf="errorAuth">{{ 'Non abbiamo trovato la tua email' | translate }}</mat-error>

          <button #submitButton type="button" mat-raised-button class="submit-button" color="accent"
            aria-label="SEND RESET LINK" [form]="forgotPasswordForm" [onSubmit]="onSubmit"
            (beforeSubmit)="beforeSubmit()" (afterSubmit)="afterSubmit($event)" (afterError)="afterError($event)"
            [successMessage]="'Email inviata con successo' | translate">
            {{ 'INVIA RICHIESTA' | translate }}
          </button>
        </form>
      </div>
      <div *ngIf="recoveryMode">
        <div class="subtitle">{{ 'Email inviata' | translate }}</div>
      </div>
      <div class="login" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="flex-end center">
        <a class="link" [routerLink]="'/auth/login'">{{ 'Torna al login' | translate }}</a>
      </div>
    </div>

    <div class="text-right mt-24 mr-24">
      <img src="assets/images/positivessl_trust_seal_lg_120.png" width="120" />
    </div>

    <ng-container *ngIf="languages?.length && currentLanguage">
      <div class="pt-24 mb-24 pr-12 text-right">
        <!-- LANGUAGES -->
        <button mat-button class="language-button" [matMenuTriggerFor]="languageMenu">
          <div fxLayout="row" fxLayoutAlign="center center">
            <img class="flag mr-8" [src]="currentLanguage.imagePath" />
            <span class="iso text-uppercase">{{ currentLanguage.code }}</span>
          </div>
        </button>
        <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
          <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
            <span fxLayout="row" fxLayoutAlign="start center">
              <img class="flag mr-16" [src]="lang.imagePath" />
              <span class="iso">{{ lang.name }}</span>
            </span>
          </button>
        </mat-menu>
      </div>
    </ng-container>

  </div>
</div>