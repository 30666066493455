<span *ngIf="isResource" class="resource-icon">
  <app-icon icon="image" class="resource-icon-image" *ngIf="isImage"></app-icon>
  <app-icon icon="video" class="resource-icon-video" *ngIf="isVideo"></app-icon>
</span>
<span *ngIf="isDContent" class="dcontent-icon">
  <app-icon icon="dcontent" class="resource-icon-dcontent"></app-icon>
</span>
<span *ngIf="isNested" class="nested-icon">
  <app-icon icon="nested" class="resource-icon-nested yellow-700-fg"></app-icon>
</span>
