import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras, UrlSegment } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  logEnabled: boolean = false;

  constructor(
    private _location: Location,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) { }

  buildUrlFromSegments(segments: UrlSegment[], extras?: NavigationExtras) {
    if (!extras) { // SET CURRENT URL EXTRAS
      extras = {
        queryParams: this._router.getCurrentNavigation().extractedUrl.queryParams,
        fragment: this._router.getCurrentNavigation().extractedUrl.fragment,
      }
    }
    return this._router.createUrlTree(
      segments.map(v => v.path),
      extras,
    ).toString();
  }

  log(...args) {
    if (this.logEnabled) {
      console.log(...args);
    }
  }

  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this._router.navigate(commands, extras);
  }

  backwards(defaultRoute?: any) {
    this.log('values', this._location, this._router, this._activatedRoute);
    // this.log('values', this._router);
    const locationState = <any>this._location.getState();
    if (locationState) {
      this.log('current index', locationState.navigationId);
      if (locationState.navigationId === 1) {
        this.log('first page of history');
        this.goToParent(defaultRoute);
      } else {
        this.log('history back');
        this.goToPreviusHistoryState();
      }
    } else {
      this.log('location history non esiste');
      this.goToParent(defaultRoute);
    }
  }

  goToPreviusHistoryState() {
    this._location.back();
  }

  goToParent(defaultRoute?: string) {
    this.tryNavigateTo('../').then(data => {
      this.log('padre esiste');
    }, e1 => {
      this.log('padre NON esiste');
      if (defaultRoute) {
        this.log('defaultRoute definita');
        this.tryNavigateTo(defaultRoute).then(data => {
          this.log('defaultRoute esiste o redirect alla pagina principale');
        }, e2 => {
          throw new Error('Check your router configuration path: "**"');
        });
      } else {
        this.log('defaultRoute NON definita, rimango dove sono');
      }
    });
  }

  tryNavigateTo(routeName: string = '../', settings?: { params: any, options: any }) {
    return new Promise((resolve, reject) => {
      const defautSettings = {
        options: {
          relativeTo: this._activatedRoute
        }
      };

      // const opts = Object.assign(settings.options, defautSettings.options, settings.options);
      // const conf = Object.assign({}, defautSettings, settings);

      this._router.navigate([routeName, {}], defautSettings.options).then(data => {
        resolve(data);
        this.log('Route exists, redirection is done');
      })
        .catch(e => {
          reject(e);
          this.log('Route not found, redirection stopped with no error raised');
        });
    });
  }
}
