import { NgModule } from '@angular/core';
import { GlobalModule } from 'app/modules/global/global.module';
import { FilterFieldComponentModule } from './filter-field/filter-field.module';
import { FiltersPreviewComponentModule } from './filters-preview/filters-preview.module';
import { SortDirectionComponentModule } from './sort-direction/sort-direction.module';

@NgModule({
  imports: [GlobalModule, FilterFieldComponentModule, SortDirectionComponentModule, FiltersPreviewComponentModule],
  exports: [FilterFieldComponentModule, SortDirectionComponentModule, FiltersPreviewComponentModule],
})
export class FilterModule {}
