import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

export interface DateslotInterface {
  startAt?: string | Date;
  endAt?: string | Date;
}

export interface WeekdayInterface {
  weekday?: number;
}

export class Weekday implements WeekdayInterface {
  weekday: number;

  constructor(private _conf: WeekdayInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export interface MonthIndexInterface {
  index?: number;
}

export class MonthIndex implements MonthIndexInterface {
  index: number;

  constructor(private _conf: MonthIndexInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export enum TimepickerFormatEnum {
  h12 = 'h12',
  h24 = 'h24',
}

export const TimepickerFormatList = [
  {
    label: '15:55',
    value: TimepickerFormatEnum.h24,
  },
  {
    label: '03:55 PM',
    value: TimepickerFormatEnum.h12,
  },
];

export enum TimepickerPeriodEnum {
  am = 'am',
  pm = 'pm',
}

export const TimepickerPeriodList = [
  {
    label: _('AM@@12 hour format - morning'),
    value: TimepickerPeriodEnum.am,
  },
  {
    label: _('PM@@12 hour format - evening'),
    value: TimepickerPeriodEnum.pm,
  },
];

