import { Component, Input, OnInit } from '@angular/core';

/**
 *
 *
 * @export
 * @class DateComponent
 * @description Formatted date view
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent {
  @Input() date: Date;
  @Input() formatter: string = 'shortDate';
}
