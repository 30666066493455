import { Component, Input } from '@angular/core';
import { Metadata, Head } from 'app/models/resource.model';

@Component({
  selector: 'app-resource-metadata',
  templateUrl: './resource-metadata.component.html',
  styleUrls: ['./resource-metadata.component.scss']
})
export class ResourceMetadataComponent {

  @Input() metadata: Metadata;
  @Input() head: Head;

}
