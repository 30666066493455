<div fxLayout="column">
  <mat-form-field class="app-timepicker"
    [ngClass]="{ 'app-timepicker-nullable': nullable, 'app-timepicker-has-seconds': hasSeconds, 'app-timepicker-is-h12': isH12 }"
    fxFlex="100">
    <app-timepicker-input [placeholder]="placeholder" [formControl]="formControl" [date]="date" [format]="format"
      [disabled]="disabled" [required]="required" appearance="outline" (keyup.enter)="enterKey.emit()"
      (valueChanged)="valueChangedEvent($event)" [clearable]="nullable" [hasSeconds]="hasSeconds"
      (cleared)="onCleared($event)"></app-timepicker-input>
    <!-- <mat-hint>{{ formControl.value }}</mat-hint> -->
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error *ngIf="formControl.touched && formControl.invalid">
      <div *ngIf="formControl.hasError('duplicate')">{{ 'Duplicato' | translate }}</div>
      <div *ngIf="formControl.hasError('required')">{{ 'Obbligatorio' | translate }}</div>
      <div *ngIf="formControl.hasError('min')">{{ 'Minimo errato' | translate }}</div>
      <div *ngIf="formControl.hasError('max')">{{ 'Massimo errato' | translate }}</div>
    </mat-error>
  </mat-form-field>
  <!-- <pre>MIN: {{ min?.toLocaleString() }}</pre> -->
  <!-- <pre>MAX: {{ max?.toLocaleString() }}</pre> -->
  <!-- <pre>TOUCH: {{ formControl.touched }}</pre>
  <pre>ERRORS: {{ formControl.errors | json }}</pre> -->
</div>