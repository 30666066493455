import { NgModule } from '@angular/core';
import { TableListViewComponent } from './table-list-view.component';
import { GlobalModule } from 'app/modules/global/global.module';
import { TableInjectorModule } from '../injectors/table-component-injector';

@NgModule({
  declarations: [
    TableListViewComponent,
  ],
  imports: [
    GlobalModule,
    TableInjectorModule
  ],
  exports: [
    TableListViewComponent
  ]
})
export class TableListViewModule {}
