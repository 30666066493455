<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ 'Imposta periodo' | translate }}</span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-24 m-0" mat-dialog-content>
    <form name="timeslot" novalidate>
      <div class="mb-12">
        <app-dateslots [formArray]="rangeControl" [min]="min" [max]="max" [required]="required"
          [allRequired]="allRequired" [hasTime]="hasTime"></app-dateslots>
      </div>
      <div *ngIf="inMultiSelection" class="yellow-100 mb-12 p-12">
        {{ 'Attenzione! Tutte le date verranno sovrascritte.' | translate }}
      </div>
      <div *ngIf="hasTime" class="yellow-100 mb-12 p-12">
        {{ 'Attenzione! Gli orari impostati qui avranno la priorità su quelli impostati nella sezione "Orari di attività"' | translate }}
      </div>
    </form>
  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="matDialogRef.close()">{{ 'Annulla' | translate }}</button>
    <button color="primary" type="button" mat-raised-button (click)="save()">{{ 'Conferma' | translate }}</button>
  </div>
</div>