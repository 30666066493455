import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Permission, PermissionInterface } from '../core/auth/models/permission.model';
import { RoleInterface, User, UserInterface, UserSettingsInterface } from 'app/models/user.model';
import { GroupInterface } from 'app/models/group.model';
import { PaginatedResponse, PaginatedResponseInterface } from 'app/models/pagination.model';
import { CircuitInterface } from 'app/models/circuit.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserRepositoryService {
  /**
   *Creates an instance of UserRepositoryService.
   * @param {HttpClient} _http
   * @memberof UserRepositoryService
   */
  constructor(private _http: HttpClient) { }

  /**
   *
   *
   * @param {*} [_params={}]
   * @returns {Observable<UserInterface[]>}
   * @memberof UserRepositoryService
   */
  findAll(_params: HttpParams | any = {}): Observable<PaginatedResponse<UserInterface>> {


    // REDIRECT TO ANOTHER ENDPOINT
    if ('ids' in _params) {
      return this.getByIds(_params);
    }

    return this._http.get<PaginatedResponse<UserInterface>>('users', {
      params: _params,
    });
  }


  /**
   *
   *
   * @param {string} q
   * @returns {Observable<UserInterface[]>}
   * @memberof UserRepositoryService
   */
  search(q: string): Observable<UserInterface[]> {
    return this._http.get<UserInterface[]>(`users/${encodeURIComponent(q)}/search`);
  }

  /**
   *
   *
   * @param {*} [_params={}]
   * @returns {Observable<PaginatedResponseInterface<UserInterface>>}
   * @memberof UserRepositoryService
   */
  getByIds(_params: HttpParams | any = {}): Observable<PaginatedResponseInterface<UserInterface>> {
    return this._http.get<UserInterface[]>('users/byids', {
      params: _params,
    }).pipe(
      map((items: UserInterface[]) => {
        return new PaginatedResponse({
          pagination: {
            totalCount: items?.length || 0,
            current: 1,
            pageCount: 1,
          },
          items,
        });
      })
    );
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<UserInterface>}
   * @memberof UserRepositoryService
   */
  get(id: string): Observable<UserInterface> {
    return this._http.get<UserInterface>(`users/${id}`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<UserSettingsInterface>}
   * @memberof UserRepositoryService
   */
  getSettings(id: string): Observable<UserSettingsInterface> {
    return this._http.get<UserSettingsInterface>(`usersettings/${id}`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<UserInterface>}
   * @memberof UserRepositoryService
   */
  delete(id: string): Observable<UserInterface> {
    return this._http.delete<UserInterface>(`users/${id}`);
  }

  /**
   *
   *
   * @param {string} id
   * @param {UserInterface} data
   * @returns {Observable<UserInterface>}
   * @memberof UserRepositoryService
   */
  update(id: string, data: UserInterface): Observable<UserInterface> {
    return this._http.put(`users/${id}`, data);
  }

  /**
   *
   *
   * @param {string} id
   * @param {UserSettingsInterface} data
   * @returns {Observable<UserSettingsInterface>}
   * @memberof UserRepositoryService
   */
  updateSettings(id: string, data: UserSettingsInterface): Observable<any> {
    return this._http.put(`usersettings/${id}`, data);
  }

  /**
   *
   *
   * @param {UserInterface} data
   * @returns {Observable<UserInterface>}
   * @memberof UserRepositoryService
   */
  create(data: UserInterface): Observable<UserInterface> {
    return this._http.post(`users`, data);
  }

  /**
   *
   *
   * @param {UserSettingsInterface} data
   * @returns {Observable<UserSettingsInterface>}
   * @memberof UserRepositoryService
   */
  createSettings(data: UserSettingsInterface): Observable<any> {
    return this._http.post(`usersettings`, data);
  }

  getRoles(): RoleInterface[] {
    return [
      {
        label: 'Super Admin',
        value: 'ROLE_SUPER_ADMIN',
      },
      {
        label: 'Small Business',
        value: 'ROLE_SB',
      },
      {
        label: 'Utente Demo',
        value: 'ROLE_DEMO',
      },
      {
        label: 'Utente Vuoto',
        value: 'ROLE_EMPTY',
      },
      {
        label: 'Player',
        value: 'ROLE_PLAYER',
      },
    ];
  }

  /**
   * @param user
   */
  getPermissionTreeFromUser(user: UserInterface): Observable<PermissionInterface[]> {
    return this._http.get<PermissionInterface[]>(`users/${user.id}/permissions/tree`);
  }

  updatePermissionUser(id: string, data: UserInterface) {
    return this._http.put(`users/${id}/permissions`, data);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<GroupInterface[]>}
   * @memberof UserRepositoryService
   */
  getGroups(id: string): Observable<GroupInterface[]> {
    return this._http.get<GroupInterface[]>(`users/${id}/groups`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<{ groups: GroupInterface[] }>}
   * @memberof UserRepositoryService
   */
  updateGroups(id: string, groups: GroupInterface[]): Observable<any> {
    return this._http.patch(`users/${id}/groups`, { groups: groups });
  }

  /**
   *
   * @param text
   */
  usernameOrMailExist(text: string): Observable<any> {
    return this._http.get<any>(`user/exist?q=${text}`);
  }
}
