import { Injectable } from '@angular/core';
import { SystemInterface } from 'app/models/system.model';
import { DataTransformAbstractUp } from 'app/modules/global/classes/data.transform.abstract.up';
import { EditorialInterface, PlaylistInterface } from 'app/models/editorial.model';
import { PlaylistItemNestedInterface, PlaylistItemInterface } from 'app/modules/editorial/editorial.interface';
import {
  TimeslotInterface,
  TimeslotsCalendarInterface,
  WeeklySchedulingInterface,
} from 'app/modules/common/timeslot/timeslot.interface';
import { DateRangeInterface } from 'app/modules/common/datetime/dateslots/dateslot.interface';

@Injectable({
  providedIn: 'root',
})
export class SystemDataTransformUp extends DataTransformAbstractUp {
  /**
   *
   *
   * @param {SystemInterface} system
   * @returns
   * @memberof SystemDataTransformUp
   */
  transform(system: SystemInterface): SystemInterface {
    if (system.on) {
      system.on = this.removeUTC(system.on.toString());
    }
    if (system.off) {
      system.off = this.removeUTC(system.off.toString());
    }
    if (system.startAt) {
      system.startAt = this.removeUTC(system.startAt.toString());
    }
    if (system.endAt) {
      system.endAt = this.removeUTC(system.endAt.toString());
    }

    this.normalizeEditorials(system)
      .normalizePlaylists(system)
      .normalizeMonitor(system)
      .normalizeCalendarScheduling(system)
      .normalizeWeeklyScheduling(system)
      .normalizebrightnessScheduling(system);
    return system;
  }

  /**
   *
   *
   * @private
   * @param {SystemInterface} system
   * @returns {this}
   * @memberof SystemDataTransformDown
   */
  private normalizeCalendarScheduling(system: SystemInterface): this {
    system.calendarScheduling = this.calendarSchedulings(system.calendarScheduling);
    return this;
  }
  /**
   *
   *
   * @protected
   * @param {TimeslotsCalendar[]} calendarScheduling
   * @returns {TimeslotsCalendar[]}
   * @memberof SystemDataTransformDown
   */
  protected calendarSchedulings(calendarScheduling: TimeslotsCalendarInterface[]): TimeslotsCalendarInterface[] {
    if (!Array.isArray(calendarScheduling)) {
      return calendarScheduling;
    }
    calendarScheduling.map((calendar) => {
      if (calendar.startAt) {
        calendar.startAt = this.removeUTC(calendar.startAt.toString());
      }
      if (calendar.endAt) {
        calendar.endAt = this.removeUTC(calendar.endAt.toString());
      }
      calendar.weeklySchedulings = this.normalizeWeeklySchedulings(calendar.weeklySchedulings);
      calendar.timeslots = this.normalizeTimeslots(calendar.timeslots);
    });
    return calendarScheduling;
  }
  /**
   *
   *
   * @private
   * @param {TimeslotInterface[]} timeslots
   * @returns
   * @memberof SystemDataTransformUp
   */
  protected normalizeTimeslots(timeslots: TimeslotInterface[]) {
    if (!Array.isArray(timeslots)) {
      return timeslots;
    }
    timeslots.map((timeslot: TimeslotInterface) => {
      this.normalizeTimeslot(timeslot);
    });
    return timeslots;
  }
  /**
   *
   *
   * @protected
   * @param {WeeklyScheduling[]} weeklyScheduling
   * @returns {WeeklyScheduling[]}
   * @memberof DataTransformAbstractUp
   */
  protected normalizeWeeklySchedulings(weeklyScheduling: WeeklySchedulingInterface[]): WeeklySchedulingInterface[] {
    if (Array.isArray(weeklyScheduling)) {
      weeklyScheduling.forEach((day: WeeklySchedulingInterface) => {
        day.timeslots = this.normalizeTimeslots(day.timeslots);
      });
    }
    return weeklyScheduling;
  }
  /**
   *
   *
   * @private
   * @param {SystemInterface} system
   * @returns {this}
   * @memberof SystemDataTransformUp
   */
  private normalizeEditorials(system: SystemInterface): this {
    if (!Array.isArray(system.editorials)) {
      return this;
    }
    system.editorials.map((editorial) => {
      if (!Array.isArray(editorial.timeslots)) {
        return;
      }
      editorial.timeslots = this.normalizeTimeslots(editorial.timeslots);
      this.normalizeEditorial(editorial);
    });
    return this;
  }
  /**
   *
   *
   * @private
   * @param {SystemEditorial} editorial
   * @returns
   * @memberof SystemDataTransformUp
   */
  private normalizeEditorial(editorial: EditorialInterface) {
    if (!Array.isArray(editorial.resources)) {
      return this;
    }
    editorial.timeslots = this.normalizeTimeslots(editorial.timeslots);
    editorial.resources.map((resourceInt: PlaylistItemInterface) => {
      // this.normalizeRange(resourceInt.range);
      resourceInt.timeslots = this.normalizeTimeslots(resourceInt.timeslots);
      resourceInt.weeklyScheduling = this.normalizeWeeklySchedulings(resourceInt.weeklyScheduling);
      resourceInt.calendarScheduling = this.calendarSchedulings(resourceInt.calendarScheduling);

      this.normalizeNested(resourceInt.nested);
    });
    return this;
  }

  normalizeNested(nested: PlaylistItemNestedInterface) {
    if (nested) {
      if (!Array.isArray(nested.resources)) {
        return this;
      }
      nested.resources.map((resourceInt: PlaylistItemInterface) => {
        // this.normalizeRange(resourceInt.range);
        // THIS SHOULDN'T EXIST IN NESTED RESOURCES
        this.normalizeTimeslots(resourceInt.timeslots);
      });
    }
  }

  normalizeRange(range: DateRangeInterface[]) {
    if (!Array.isArray(range)) {
      return this;
    }
    range.map((rangeItem: DateRangeInterface) => {
      if (rangeItem.startAt) {
        rangeItem.startAt = this.removeUTC(rangeItem.startAt.toString());
      }
      if (rangeItem.endAt) {
        rangeItem.endAt = this.removeUTC(rangeItem.endAt.toString());
      }
    });
  }

  /**
   *
   *
   * @private
   * @param {SystemInterface} system
   * @returns {this}
   * @memberof SystemDataTransformUp
   */
  private normalizebrightnessScheduling(system: SystemInterface): this {
    if (Array.isArray(system.brightnessScheduling)) {
      system.brightnessScheduling.map((item) => {
        if (item.range) {
          this.normalizeTimeslot(item.range);
        }
      });
    }
    return this;
  }

  /**
   *
   *
   * @private
   * @param {SystemInterface} system
   * @returns {this}
   * @memberof SystemDataTransformUp
   */
  private normalizeWeeklyScheduling(system: SystemInterface): this {
    system.weeklyScheduling = this.normalizeWeeklySchedulings(system.weeklyScheduling);
    return this;
  }

  /**
   *
   *
   * @private
   * @param {SystemInterface} system
   * @returns {this}
   * @memberof SystemDataTransformUp
   */
  private normalizePlaylists(system: SystemInterface): this {
    if (!Array.isArray(system.playlists)) {
      return this;
    }
    system.playlists.map((playlist: PlaylistInterface) => {
      this.normalizeEditorial(playlist);
    });
    return this;
  }
  /**
   *
   *
   * @private
   * @param {SystemInterface} system
   * @returns {this}
   * @memberof SystemDataTransform
   */
  private normalizeMonitor(system: SystemInterface): this {
    if (!system.monitor) {
      return this;
    }
    if (system.monitor.range) {
      this.normalizeMonitorRange(system.monitor.range);
    }
    system.monitor.weeklyScheduling.map((day) => {
      this.normalizeTimeslots(day.timeslots);
    });
    return this;
  }

  /**
   *
   *
   * @protected
   * @param {TimeslotInterface} timeslot
   * @returns {TimeslotInterface}
   * @memberof SystemDataTransformUp
   */
  protected normalizeTimeslot(timeslot: TimeslotInterface): TimeslotInterface {
    if (timeslot.from) {
      timeslot.from = this.removeUTC(timeslot.from.toString());
    }
    if (timeslot.to) {
      timeslot.to = this.removeUTC(timeslot.to.toString());
    }
    return timeslot;
  }

  /**
   *
   *
   * @protected
   * @param {TimeslotInterface} timeslot
   * @returns {TimeslotInterface}
   * @memberof SystemDataTransformUp
   */
  protected normalizeMonitorRange(range: TimeslotInterface): TimeslotInterface {
    if (range.startAt) {
      range.startAt = this.removeUTC(range.startAt.toString());
    }
    if (range.endAt) {
      range.endAt = this.removeUTC(range.endAt.toString());
    }
    return range;
  }
}
