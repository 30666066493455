import { FormControl, FormGroup, FormArray, FormGroupDirective } from '@angular/forms';

export class FormUtils {
  static getInvalidFields(group: any, errorMap = {}, add = ''): { [key: string]: { errors: any, control: FormControl, paths: string[] } } {
    Object.keys(group.controls).forEach((field, index) => {
      // if (typeof add !== 'undefined') {

      // }
      // add = add || '';
      const localAdd = add + (add ? '->' : '') + field;
      // errorMap[add] = {};
      const control = group.get(field);
      if (control instanceof FormControl || FormUtils.isInvalidField(control)) {
        if (control.errors) {
          errorMap[localAdd] = { errors: control.errors, control: control, paths: localAdd.split('->') };
          // errorMap = FormUtils.getInvalidFields(control, errorMap, '');
        } else {
          delete errorMap[localAdd];
        }
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        errorMap = FormUtils.getInvalidFields(control, errorMap, localAdd);
      }
    });

    return errorMap;
  }

  static copyValues(targetGroup: any, valueGroup: any, allowedFields = [], add = '') {
    if (targetGroup && valueGroup) {
      Object.keys(valueGroup.controls).forEach((key, index) => {
        if (!allowedFields.length || allowedFields.indexOf(key) !== -1) {
          const localAdd = add + (add ? '->' : '') + key;
          const targetControl = targetGroup.get(key);
          if (targetControl) {
            console.log('sovrascrivo', localAdd);
            const valueControl = valueGroup.get(key);
            if (targetControl instanceof FormControl) {
              targetControl.setValue(valueControl.value);
              // targetControl.updateValueAndValidity();
            } else if (targetControl instanceof FormGroup) {
              FormUtils.copyValues(targetControl, valueControl, [], localAdd);
            } else if (targetControl instanceof FormArray) {
              FormUtils.clearFormArray(targetControl);
              (<FormArray>valueControl).controls.forEach((control) => {
                console.log('aggiungo array item', control.value);
                (<FormArray>targetControl).push(control);
              });
              // targetControl.updateValueAndValidity();
            }
          }
        } else {
          console.log('------- field is not allowed!', key);
        }
      });
    }
  }

  static clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  static isInvalidField(control: any) {
    return control.invalid && control.errors ? control.errors : null;
  }

  static validateAllFormFields(group: any, opts = { emitEvent: false }) {
    Object.keys(group.controls).forEach((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        FormUtils.validateFormField(control, opts);
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        FormUtils.validateFormField(control, opts);
        FormUtils.validateAllFormFields(control, opts);
      }
    });
    FormUtils.validateFormField(group, opts);
  }
  static validateFormField(control: any, opts = { emitEvent: false }) {
    control.markAsTouched({ onlySelf: true, emitEvent: opts.emitEvent });
    control.markAsDirty({ onlySelf: true, emitEvent: opts.emitEvent });
    const hasAsyncValidator = !!control.asyncValidator;
    if (!hasAsyncValidator) {
      control.updateValueAndValidity({ onlySelf: true, emitEvent: opts.emitEvent });
    } else {
      // SEND stateChanges to root form after async validator complete
      control.updateValueAndValidity();
    }
  }
  static unvalidateAllFormFields(group: any, opts = { emitEvent: false }) {
    Object.keys(group.controls).forEach((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        FormUtils.unvalidateFormField(control, opts);
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        FormUtils.unvalidateFormField(control, opts);
        FormUtils.unvalidateAllFormFields(control, opts);
      }
    });
  }
  static unvalidateFormField(control: any, opts = { emitEvent: false }) {
    control.markAsUntouched({ onlySelf: true });
    control.markAsPristine({ onlySelf: true });
    // control.updateValueAndValidity({ emitEvent: opts.emitEvent });
  }
  // static resetValidation(field: any, onlySelf?: boolean) {
  //   field.markAsPristine({
  //     onlySelf: onlySelf
  //   });
  //   field.markAsUntouched({
  //     onlySelf: onlySelf
  //   });
  // }
}

export class AppForm extends FormGroup {
  private _form: FormGroupDirective;
  get form(): FormGroupDirective {
    return this._form;
  }

  get submitted() {
    return this.form ? this.form.submitted : false;
  }

  constructor(controls, validatorOrOpts?, asyncValidators?) {
    super(controls, validatorOrOpts, asyncValidators);
  }

  setForm(form: FormGroupDirective) {
    this._form = form;
    this.form.onSubmit(undefined);
    FormUtils.validateAllFormFields(this);

    if (this.valid) {
      (this.form as { submitted: boolean }).submitted = false;
    }
  }

  resetValidation() {
    (this.form as { submitted: boolean }).submitted = false;
    // FormUtils.resetValidation(this);
  }

  getRawValue() {
    const obj = super.getRawValue();
    Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
    return obj;
  }
}
