import { Component, Inject, ViewEncapsulation, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FormUtils } from 'app/modules/global/classes/form-utils';
import { I18nService } from 'app/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { DateslotDialogDataInterface } from '../../dateslots/dateslot.interface';

@Component({
  selector: 'app-dateslot-dialog',
  templateUrl: './dateslot-dialog.component.html',
  styleUrls: ['./dateslot-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateslotDialogComponent implements OnInit {
  constructor(
    public matDialogRef: MatDialogRef<DateslotDialogComponent>,
    private _toastrService: ToastrService,
    private _cdr: ChangeDetectorRef,
    private _i18nService: I18nService,
    @Inject(MAT_DIALOG_DATA) public data: DateslotDialogDataInterface,
  ) { }

  ngOnInit(): void {
    console.log('DateslotDialogComponent', this);
  }

  get required() {
    return this.data && this.data.required;
  }

  get min() {
    return this.data && this.data.min;
  }

  get max() {
    return this.data && this.data.max;
  }

  get hasTime() {
    return this.data && this.data.hasTime;
  }

  get group() {
    return this.data && this.data.group;
  }

  save() {
    FormUtils.validateAllFormFields(this.group);

    console.log('save', this.group);

    if (this.group.valid) {
      this.matDialogRef.close(
        this.group && {
          group: this.group,
        },
      );
    } else {
      this._toastrService.error(this._i18nService.translate(_('Compila correttamente tutti i campi')));
    }
  }
}
