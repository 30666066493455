import { Component, Input, OnChanges, ChangeDetectorRef, EventEmitter, Output, AfterViewInit, Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { TimeslotService } from '../../timeslot/timeslot.service';
import { DatetimeService } from '../datetime.service';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DateRangeInterface } from '../dateslots/dateslot.interface';
import { DateslotService } from '../dateslots/dateslot.service';

@Injectable()
export class CustomShowOnTouchedErrorStateMatcher {
  isErrorState(c: FormControl) {
    return c.touched && c.errors !== null;
  }
}

/**
 *
 *
 * @export
 * @class DateslotComponent
 * @extends {DateslotComponent}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-dateslot',
  templateUrl: './dateslot.component.html',
  styleUrls: ['./dateslot.component.scss'],
  providers: [{ provide: ErrorStateMatcher, useClass: CustomShowOnTouchedErrorStateMatcher }],
})
export class DateslotComponent implements AfterViewInit, OnChanges {
  @Input() group: FormGroup;
  @Input() fromField: string = 'startAt';
  @Input() toField: string = 'endAt';
  @Input() fromLabel: string = this._i18nService.translate(_('Data inizio'));
  @Input() toLabel: string = this._i18nService.translate(_('Data fine'));
  @Input() min: string | Date;
  @Input() max: string | Date;
  @Input() range: DateRangeInterface[];
  @Input() hasTime: boolean;
  @Input() allRequired: boolean;

  @Input() set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
  get required() {
    return this._required;
  }
  protected _required = false;

  @Input() set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.group.disable();
    } else {
      this.group.enable();
    }
  }
  get disabled() {
    return this._disabled;
  }
  protected _disabled = false;

  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    protected _cdr: ChangeDetectorRef,
    protected _timeslotService: TimeslotService,
    protected _dateslotService: DateslotService,
    private _datetimeService: DatetimeService,
    private _i18nService: I18nService,
  ) { }

  ngAfterViewInit() {
  }

  ngOnChanges(changes) {
    if (changes.group || changes.min || changes.max) {
    }
  }

  fromChanged(event) {
    this.valueChanged.emit(event);
  }

  toChanged(event) {
    this.valueChanged.emit(event);
  }
}
