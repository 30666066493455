<div fxLayout="column" class="dateslots">
  <div class="mb-12 border dateslots-items">
    <div *ngFor="let group of formArray.controls; let i = index; let first = first; let last = last" [formGroup]="group"
      class="dateslots-item ellipsis">
      <div class="p-12" fxLayoutAlign="flex-start" fxLayoutAlign.gt-xs="flex-start center">
        <!-- DATES -->
        <div fxFlex="100" fxLayout="column" [fxLayout.gt-xs]="hasTime ? 'column' : 'row wrap'"
          [fxLayoutGap.gt-xs]="hasTime ? '' : '8px'">
          <!-- startAt -->
          <div class="mr-12" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center" fxFlex="calc(50% - 8px)">
            <app-datepicker [formControl]="group.get(fromField)" [placeholder]="fromLabel"
              [required]="required || !group.get(toField).value || validation[i]?.fromRequired"
              [disableControl]="disabled" [min]="validation[i]?.fromMin" [max]="validation[i]?.fromMax"
              (dateChange)="fromChanged($event)"></app-datepicker>
            <div *ngIf="hasTime && group.get('startTimeAt')">
              <app-timepicker [nullable]="true" [required]="false" [placeholder]="'Orario inizio' | translate"
                [formControl]="group.get('startTimeAt')" [date]="group.get(fromField).value"
                [max]="group.get('endTimeAt')"></app-timepicker>
            </div>
          </div>
          <!-- endAt -->
          <div class="mr-12" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center" fxFlex="calc(50% - 8px)">
            <app-datepicker [formControl]="group.get(toField)" [placeholder]="toLabel"
              [required]="required || !group.get(fromField).value || validation[i]?.toRequired"
              [disableControl]="disabled" [min]="validation[i]?.toMin" [max]="validation[i]?.toMax"
              (dateChange)="toChanged($event)"></app-datepicker>
            <div *ngIf="hasTime && group.get('endTimeAt')">
              <app-timepicker [nullable]="true" [required]="false" [placeholder]="'Orario fine' | translate"
                [formControl]="group.get('endTimeAt')" [date]="group.get(toField).value"
                [min]="group.get('startTimeAt')"></app-timepicker>
            </div>
          </div>
        </div>
        <!-- DELETE -->
        <button type="button" color="warn" class="remove" mat-icon-button (click)="removeEvent(i)">
          <mat-icon aria-label="Remove dateslot">remove_circle</mat-icon>
        </button>
      </div>
      <mat-divider *ngIf="!last"></mat-divider>
    </div>
    <div *ngIf="!formArray.length" class="text-center p-12 grey-400-fg">
      {{ 'nessun range di date impostato' | translate }}
    </div>
  </div>
  <!-- <pre>
    {{ formArray.value | json }}
  </pre> -->
  <!-- EMPTY -->
  <div class="mb-12" fxLayoutAlign="flex-end">
    <button type="button" class="add" mat-raised-button [disabled]="disabled"
      (click)="addFromDialog ? openDateslotDialog() : addDateslot()">
      {{ 'Aggiungi range di date' | translate }}
    </button>
  </div>
  <!-- ERROR -->
  <mat-error
    *ngIf="formArray.hasError('required') && formArray.touched"><small>{{ 'Obbligatorio' | translate }}</small></mat-error>
</div>