<div *ngIf="!compact && (content || nested)" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="flex-start center"
  [class.wide]="wide">
  <!-- THUMB -->
  <div *ngIf="thumb" class="thumb" [ngClass]="{ 'thumb-nested': nested }" ngClass.xs="thumb-xs"
    [class.pointer]="isClickable">
    <div matRipple [matRippleDisabled]="!isClickable" [openResource]="isClickable && (resource || dcontent)"
      class="resource-preview" [ngClass]="{ 'accent-200-bg': !nested }" backgroundImage backgroundSize="cover"
      [backgroundImageSrc]="src" [style.maxWidth]="maxWidth" [style.maxHeight]="maxHeight">
      <app-resource-nested-content *ngIf="nested" [nested]="nested"></app-resource-nested-content>
      <!-- ICON -->
      <app-resource-icon *ngIf="icon && !nested" class="resource-preview-icon" [resource]="resource"
        [dcontent]="dcontent" [nested]="nested"></app-resource-icon>

      <span matTooltip="FPS"
        *ngIf="resource && resource.type === resourceType.videos && resource.metadata && resource.metadata.fps"
        class="resource-preview-fps orange-500" [ngClass]="{ 'goodrate': resource.metadata.fps >= 60 }"><app-fps
          [value]="resource.metadata.fps"></app-fps></span>

      <!-- ZOOM -->
      <mat-icon *ngIf="zoom" class="resource-preview-action" aria-label="Open resource">zoom_in</mat-icon>
    </div>
  </div>
  <!-- INFO -->
  <div *ngIf="hasMetadata" fxLayout="column" class="ellipsis">
    <!-- TITLE -->
    <div fxLayout="row" fxLayoutAlign="flex-start center">
      <div class="resource-content-index mr-4" *ngIf="hasIndex">{{ index }}</div>
      <!-- ICON -->
      <app-resource-icon *ngIf="hasNameIcon" class="resource-title-icon grey-400-fg mr-4" [resource]="resource"
        [dcontent]="dcontent" [nested]="nested"></app-resource-icon>
      <!-- NAME -->
      <a class="ellipsis" *ngIf="name && isLink && title" href [openResource]="(resource || dcontent)">
        <app-name-ext class="title ellipsis" [value]="title"></app-name-ext>
      </a>
      <app-name-ext *ngIf="name && !isLink && title" class="title ellipsis" [value]="title"></app-name-ext>

      <small class="ml-8" *ngIf="!details && showInlineTimerForVideos && isVideo" fxLayout="row"
        fxLayoutAlign="flex-start center">
        <mat-icon class="grey-400-fg mr-4">timer</mat-icon>
        <span *ngIf="!durationControl" class="details ellipsis"
          [ngClass]="{ 'orange-400-fg': time !== defaultDuration }">
          <app-duration [time]="time" [options]="{ hideEmpty: true }"></app-duration>
        </span>
      </small>

      <small class="ml-8" *ngIf="!details && showBitrateForVideos && isVideo" fxLayout="row"
        fxLayoutAlign="flex-start center">
        <mat-icon class="grey-400-fg mr-4">fitbit</mat-icon>
        <span class="details ellipsis">
          {{ resource.metadata.bit_rate | bitrate }}
        </span>
      </small>

      <a *ngIf="isDContent && dcontentEditBtn" [matTooltip]="'Val al Set di Dati' | translate"
        [routerLink]="'/datasets/' + dcontent.id" (click)="$event.stopPropagation()" target="_blank" fxLayout="column"
        class="ml-8 app-icon-btn-sm">
        <mat-icon aria-label="Modifica durata">link</mat-icon>
      </a>
    </div>
    <!-- DETAILS -->
    <div *ngIf="details" class="font-size-12 resource-details pt-4" fxLayout="column">
      <!-- RESOURCE || DCONTENT -->
      <div *ngIf="isResource || isDContent" fxLayout="row" fxLayoutGap="8px">
        <!-- RESOLUTION -->
        <span *ngIf="width && height" fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <mat-icon class="grey-400-fg mr-4">aspect_ratio</mat-icon>
          <app-resolution class="details ellipsis" [width]="width" [height]="height"></app-resolution>
        </span>
        <!-- DURATION -->
        <span *ngIf="!isNested" fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <div class="mr-4" *ngIf="editDuration">
            <button [color]="accent" mat-icon-button (click)="onEditDurationClick($event)">
              <mat-icon aria-label="Modifica durata">edit</mat-icon>
            </button>
          </div>
          <mat-icon class="grey-400-fg mr-4">timer</mat-icon>
          <span *ngIf="!durationControl" class="details ellipsis"
            [ngClass]="{ 'orange-400-fg': time !== defaultDuration }">
            <app-duration [time]="time" [options]="{ hideEmpty: true }"></app-duration>
          </span>
        </span>
        <!-- BIT RATE -->
        <span *ngIf="!isNested && isVideo" fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <mat-icon class="grey-400-fg mr-4">fitbit</mat-icon>
          <span class="details ellipsis">
            {{ resource.metadata.bit_rate | bitrate }}
          </span>
        </span>
      </div>
      <!-- NESTED -->
      <div *ngIf="isNested" fxLayout="row" fxLayoutGap="8px">
        <!-- RESOURCES -->
        <span fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <mat-icon class="grey-400-fg mr-4">filter</mat-icon>
          <span class="details ellipsis">
            {{ '{count} elementi' | translate: { count: nested.resources.length } }}</span>
        </span>
        <!-- PAGES -->
        <span fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <mat-icon class="grey-400-fg mr-4">remove_red_eye</mat-icon>
          <span class="details ellipsis">{{ '{count} per pagina' | translate: { count: nested.page } }}</span>
        </span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="compact && (content || nested)" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="flex-start center"
  [class.wide]="wide">
  <!-- ICON -->
  <app-resource-icon *ngIf="icon" class="resource-title-icon grey-400-fg mr-4" [resource]="resource"
    [dcontent]="dcontent" [nested]="nested"></app-resource-icon>

  <!-- INFO -->
  <div *ngIf="hasMetadata" fxLayout="column" class="ellipsis">
    <!-- NAME -->
    <div fxLayout="row" fxLayoutAlign="flex-start center">
      <div class="resource-content-index mr-4" *ngIf="hasIndex">{{ index }}</div>
      <!-- NAME -->

      <a *ngIf="isClickable && !nested" class="ellipsis cursor-pointer" [openResource]="(resource || dcontent)">
        <app-name-ext class="title ellipsis" [value]="title"></app-name-ext>
      </a>
      <app-name-ext *ngIf="!isClickable || nested" class="title ellipsis" [value]="title"></app-name-ext>
    </div>
    <!-- RESOLUTION && DURATION -->
    <div *ngIf="details" class="font-size-12 resource-details grey-400-fg" fxLayout="column">
      <!-- RESOURCE || DCONTENT -->
      <div *ngIf="isResource || isDContent" fxLayout="row wrap" fxLayoutGap="8px">
        <!-- RESOLUTION -->
        <span *ngIf="width && height" fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <app-resolution class="details ellipsis" [width]="width" [height]="height"></app-resolution>
        </span>
        <!-- DURATION -->
        <span *ngIf="!isNested" fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <div class="mr-4" *ngIf="editDuration">
            <button [color]="accent" mat-icon-button (click)="onEditDurationClick($event)">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <span *ngIf="!durationControl" class="details ellipsis">
            <app-duration [time]="time" [options]="{ hideEmpty: true }"></app-duration>
          </span>
        </span>
        <!-- BIT RATE -->
        <span *ngIf="!isNested && isVideo" fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <span class="details ellipsis">
            {{ resource.metadata.bit_rate | bitrate }}
          </span>
        </span>
      </div>
      <!-- NESTED -->
      <div *ngIf="isNested" fxLayout="row wrap" fxLayoutGap="8px">
        <!-- RESOURCES -->
        <span fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <span class="details ellipsis">
            {{ '{count} elementi' | translate: { count: nested.resources.length } }}</span>
        </span>
        <!-- PAGES -->
        <span fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <span class="details ellipsis">{{ '{count} per pagina' | translate: { count: nested.page } }}</span>
        </span>
      </div>
    </div>
    <!-- <div *ngIf="isResource" class="font-size-10 resource-details grey-500-fg">
      /{{resource.brand.name}}
    </div> -->
    <div *ngIf="isDContent && !!dcontent.template.brand" class="font-size-10 resource-details grey-500-fg">
      /{{dcontent.template.brand.name}}
    </div>
  </div>

  <!-- EDIT DCONTENT -->
  <ng-container *ngIf="isDContent && dcontentEditBtn">
    <a [matTooltip]="'Val al Set di Dati' | translate" [routerLink]="'/datasets/' + dcontent.id"
      (click)="$event.stopPropagation()" target="_blank">
      <mat-icon>link</mat-icon>
    </a>
  </ng-container>
</div>