import { DataTransform } from 'app/models/data-transform';

/**
 *
 *
 * @export
 * @abstract
 * @class DataTransformAbstractUp
 * @implements {DataTransform}
 */
export abstract class DataTransformAbstractUp implements DataTransform {
  /**
   *
   *
   * @param {*} object
   * @memberof DataTransformAbstractUp
   */
  transform(object: any) { }
  /**
    *
    *
    * @private
    * @param {string} date
    * @returns
    * @memberof SystemDataTransformUp
    */
  protected removeUTC(date: string) {
    if (date) {
      return date.replace(/\+[0-9]{4}$/, '');
    }
  }
  /**
    *
    *
    * @private
    * @param {string} date
    * @returns
    * @memberof SystemDataTransformUp
    */
  protected normalizeMonth(date: string) {
    if (date) {
      return this.removeUTC(date).toString().substr(0, 7);
    }
  }
  /**
    *
    *
    * @private
    * @param {string} date
    * @returns
    * @memberof SystemDataTransformUp
    */
  protected normalizeDate(date: string) {
    if (date) {
      return this.removeUTC(date).toString().substr(0, 10);
    }
  }
}
