import { NgModule } from '@angular/core';
import { SubmitDirective } from './submit.directive';
import { StepperValidationDirective } from './stepper-validation.directive';
@NgModule({
  declarations: [
    SubmitDirective,
    StepperValidationDirective
  ],
  exports: [
    SubmitDirective,
    StepperValidationDirective,
  ]
})
export class SubmitDirectiveModule { }
