import { NgModule } from '@angular/core';
import { ClickConfirmDirective } from './click-confirm.directive';
import { ConfirmDialogComponentModule } from './confirm-dialog/confirm-dialog.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@NgModule({
    declarations: [ClickConfirmDirective],
    imports: [ConfirmDialogComponentModule],
    exports: [ClickConfirmDirective, ConfirmDialogComponentModule]
})
export class ClickConfirmDirectiveModule {}
