import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface SystemStatus {
  label: string,
  icon: string,
  value: string,
}

export enum SystemStatusEnum {
  online = 'online',
  offline = 'offline',
  inactive = 'inactive',
  unmounted = 'unmounted',
  uninstalled = 'uninstalled',
  disabled = 'disabled',
}

export enum SystemStatusColorEnum {
  green,
  red,
  grey,
}

export const SystemStatusesList: SystemStatus[] = [{
  label: _('Online'),
  icon: 'wifi',
  value: SystemStatusEnum.online,
}, {
  label: _('Offline'),
  icon: 'wifi_off',
  value: SystemStatusEnum.offline,
}, {
  label: _('Inattivo'),
  icon: 'history_toggle_off',
  value: SystemStatusEnum.inactive,
}, {
  label: _('Smontato'),
  icon: 'eject',
  value: SystemStatusEnum.unmounted,
}, {
  label: _('Non installato'),
  icon: 'file_download_off',
  value: SystemStatusEnum.uninstalled,
}, {
  label: _('Non monitorabile'),
  icon: 'content_paste_off',
  value: SystemStatusEnum.disabled,
}, ];

