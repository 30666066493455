import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { GlobalModule } from 'app/modules/global/global.module';
import { StringModule } from '../../string/string.module';
import { EntitiesSelectorComponent } from './entities-selector.component';

@NgModule({
  declarations: [
    EntitiesSelectorComponent,
  ],
  imports: [
    GlobalModule,
    StringModule,
    ScrollingModule,
  ],
  exports: [
    EntitiesSelectorComponent,
  ],
})
export class EntitiesSelectorModule {}
