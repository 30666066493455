import { NgModule } from '@angular/core';
import { HintComponentInjectorComponent } from './hint-component-injector/hint-component-injector.component';
import { HintDialogComponent } from '../dialogs/hint-dialog/hint-dialog.component';
import { HintTarasComponent } from './hint-taras/hint-taras.component';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgMaterialModule } from 'app/ng-material/ng-material.module';

@NgModule({
    declarations: [HintComponentInjectorComponent, HintTarasComponent],
    imports: [CommonModule, FuseSharedModule, NgMaterialModule],
    exports: [HintComponentInjectorComponent, HintTarasComponent]
})
export class HintComponentsModule {}
