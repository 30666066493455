<mat-form-field fxFlex="100">
  <!-- <mat-label>{{ placeholder }}</mat-label> -->
  <!-- [(ngModel)]="pickerValue"
  [ngModelOptions]="{ standalone: true }" -->
  <input name="datepicker_input" matInput autocomplete="off" [matDatepicker]="datepicker" [formControl]="formControl"
    [required]="required" [disableControl]="disabled" [placeholder]="placeholder" [min]="min" [max]="max"
    [clearable]="clearable && !disabled" (focus)="datepicker.open()" (cleared)="dateChangeEvent(null)"
    (dateChange)="dateChangeEvent($event.value)" />
  <mat-datepicker-toggle matSuffix [for]="datepicker" [disabled]="disabled"></mat-datepicker-toggle>
  <mat-datepicker #datepicker [disabled]="disabled"></mat-datepicker>
  <mat-error>
    <div *ngIf="formControl?.hasError('required')">{{ 'Obbligatorio' | translate }}</div>
    <div *ngIf="formControl?.hasError('min')">{{ 'Minimo errato' | translate }}</div>
    <div *ngIf="formControl?.hasError('max')">{{ 'Massimo errato' | translate }}</div>
    <div *ngIf="formControl?.hasError('outOfRange')">{{ 'Fuori dal range' | translate }}</div>
  </mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>