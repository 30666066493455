import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DatasetInterface } from 'app/models/dataset.model';
import { Resource, RESOURCE_TYPE } from 'app/models/resource.model';
import { PlaylistItemNestedInterface } from 'app/modules/editorial/editorial.interface';

/**
 *
 *
 * @export
 * @class ResourceIconComponent
 * @description Show resource type icon
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-resource-icon',
  templateUrl: './resource-icon.component.html',
  styleUrls: ['./resource-icon.component.scss'],
})
export class ResourceIconComponent implements OnInit {
  @Input() resource: Resource;
  @Input() dcontent: DatasetInterface;
  @Input() nested: PlaylistItemNestedInterface;

  constructor() {}

  ngOnInit() {}

  get type() {
    return this.resource ? this.resource.type : null;
  }

  get isImage() {
    return this.type === RESOURCE_TYPE.images;
  }

  get isVideo() {
    return this.type === RESOURCE_TYPE.videos;
  }

  get isDContent() {
    return !!this.dcontent;
  }

  get isResource() {
    return !this.isDContent;
  }

  get isNested() {
    return !!this.nested; // && this.nested.page && this.nested.resources.length;
  }
}
