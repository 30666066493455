import { Injectable } from '@angular/core';
import {
  TimeslotInterface,
  TimeslotOptionsInterface,
  TimeslotOptions,
  TimeslotMinutesItemInterface,
  TimeslotMinutesItem,
  BrightnessSchedulingInterface,
  WeeklySchedulingInterface,
  WeeklyScheduling,
  TimeslotAlwaysItemInterface,
  TimeslotAlwaysItem,
  TimeslotsCalendarInterface,
  TimeslotsCalendar,
} from './timeslot.interface';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { DateslotService } from '../datetime/dateslots/dateslot.service';
import { SchedulingEnum } from 'app/models/scheduling.model';
import { MonthIndex, MonthIndexInterface, Weekday, WeekdayInterface } from '../datetime/datetime.interface';

@Injectable({
  providedIn: 'root',
})
export class TimeslotService {
  constructor(private _dateslotService: DateslotService) {}

  buildTimeslot(val: TimeslotInterface = <TimeslotInterface>{}, options?: TimeslotOptionsInterface) {
    options = new TimeslotOptions(options);
    const group = {};
    group[options.fromField] = new FormControl(val[options.fromField]);
    group[options.toField] = new FormControl(val[options.toField]);
    return group;
  }

  buildTimeslots(items: TimeslotInterface[] = [], options?: TimeslotOptionsInterface) {
    return items.map((item, index) => {
      return new FormGroup(this.buildTimeslot(item, options));
    });
  }

  buildBrightnessTimeslots(items: BrightnessSchedulingInterface[] = []) {
    return items.map((item, index) => {
      return new FormGroup(this.buildBrightnessTimeslot(item));
    });
  }

  buildBrightnessTimeslot(val: BrightnessSchedulingInterface = <BrightnessSchedulingInterface>{}) {
    return {
      range: new FormGroup(this.buildTimeslot(val.range)),
      value: new FormControl(val.value, [Validators.required, Validators.min(0), Validators.max(100)]),
    };
  }

  buildWeekTimeslots(items: WeeklySchedulingInterface[] = []) {
    return items.map((item, index) => {
      return new FormGroup(this.buildWeekTimeslot(item));
    });
  }

  buildWeekTimeslot(val?: WeeklySchedulingInterface) {
    val = new WeeklyScheduling(val);
    return {
      weekday: new FormControl(val.weekday),
      alwaysOn: new FormControl(!!val.alwaysOn),
      timeslots: new FormArray(this.buildTimeslots(val.timeslots), [
        (control) => {
          if (control.parent) {
            const cAlwaysOn = control.parent.get('alwaysOn');
            return Validators.requiredTrue(cAlwaysOn) && Validators.required(control);
          }
          return null;
        },
      ]),
    };
  }

  buildAlwaysTimeslots(items: TimeslotAlwaysItemInterface[] = []) {
    return items.map((item, index) => {
      return new FormGroup(this.buildAlwaysTimeslot(item));
    });
  }

  buildAlwaysTimeslot(val?: TimeslotAlwaysItemInterface) {
    val = new TimeslotAlwaysItem(val);
    return {
      from: new FormControl(val.from),
      to: new FormControl(val.to),
      alwaysOn: new FormControl(val.alwaysOn),
    };
  }

  buildMinutesItems(items: TimeslotMinutesItemInterface[] = []) {
    if (items.length === 0) {
      items = [null];
    }
    return items.map((item, index) => {
      return new FormGroup(this.buildMinutesItem(item));
    });
  }

  buildMinutesItem(item?: TimeslotMinutesItemInterface) {
    item = new TimeslotMinutesItem(item);
    return {
      once: new FormControl(item.once, Validators.required),
      timeslots: new FormGroup(this.buildAlwaysTimeslot(new TimeslotAlwaysItem(item.timeslots))),
    };
  }

  buildCalendars(items: TimeslotsCalendarInterface[] = []) {
    return items.map((item, index) => {
      return new FormGroup(this.buildCalendar(item));
    });
  }

  buildCalendar(val?: TimeslotsCalendarInterface) {
    val = new TimeslotsCalendar(val);
    return {
      startAt: new FormControl(val.startAt),
      endAt: new FormControl(val.endAt),
      default: new FormControl(val.default),
      type: new FormControl(val.type),
      timeslots: new FormArray(
        this.buildTimeslots(val.timeslots, {
          // fromField: 'startAt',
          // toField: 'endAt',
        }),
        [
          (control) => {
            if (control && control.parent) {
              if (control.parent.get('type').value === SchedulingEnum.daily) {
                return Validators.required(control);
              }
            }
            return null;
          },
        ],
      ),
      weeklySchedulings: new FormArray(this.buildWeekTimeslots(val.weeklySchedulings), [
        (control) => {
          // console.log('validate calendar weeklySchedulings', control);
          if (control && control.parent) {
            if (control.parent.get('type').value === SchedulingEnum.weekly) {
              return Validators.required(control);
            }
          }
          return null;
        },
      ]),
    };
  }
}
