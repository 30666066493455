import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseurlHttpInterceptorService implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.url.startsWith('http') || request.url.startsWith('assets')) {
      return next.handle(request);
    }
    const apiReq = request.clone({
      url: `${environment.baseurl}${request.url}`
    });
    return next.handle(apiReq);
  }
}
