export interface JsonWebTokenInterface {
  token: string;
  expired: any;
}

export class JsonWebToken implements JsonWebTokenInterface {
  token: string;
  expired: any;

  /**
   * Constructor
   *
   * @param _jsonWebToken
   */
  constructor(private _jsonWebToken?: JsonWebTokenInterface) {
    Object.assign(this, this._jsonWebToken);
    delete this._jsonWebToken;
  }
}
