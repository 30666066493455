export const CUSTOM_ICONS = [
  ['pr-cat', 'assets/icons/material/cat.svg'],
  ['reservations', 'assets/icons/material/bullseye-arrow.svg'],
  ['rules', 'assets/icons/material/format-list-checks.svg'],
  ['validations', 'assets/icons/material/text-box-check-outline.svg'],
  ['editorialsFraction', 'assets/icons/material/timeline-check.svg'],
];

export const ICONS_MAP = {
  // ENTITIES
  hooks: 'link',
  filters: 'filter_list',
  kinds: 'category',
  // ELEMENTS
  image: 'image',
  video: 'videocam',
  dcontent: 'unarchive',
  dsp: 'dataset_linked',
  frame: 'link',
  nested: 'folder',
  bookmark: 'bookmark',
  in_distribution: 'remove_red_eye',
  date_range: 'date_range',
  time: 'access_time',
  time_scheduling: 'timelapse',
  history: 'history',
  help: 'support_agent',
  info: 'info',
  contents: 'layers',
  summary: 'assignment',
  hardware: 'memory',
  screen: 'desktop_windows',
  profile: 'account_circle',
  menu: 'menu',
  logout: 'exit_to_app',
  // ACTIONS
  add: 'add',
  edit: 'edit',
  remove: 'delete_outline',
  remove_multi: 'delete_sweep',
  save: 'save',
  undo: 'undo',
  drag: 'drag_indicator',
  caret_down: 'arrow_drop_down',
  paste: 'assignment',
  duplicate: 'control_point_duplicate',
  overwrite: 'cached',
  expand: 'expand_more',
  reduce: 'expand_less',
  select_multi: 'playlist_add_check',
  preview_image: 'burst_mode',
  search: 'search',
  backwards: 'arrow_back',
  more: 'more_vert',
  is_default: 'star',
  is_favorite: 'favorite',
  is_notification: 'notifications',
  is_resource_online: 'cell_tower',
  // LICENSES
  license_oneshot: 'bookmark_border',
  license_demo: 'lock_open',
  license_subscribed: 'monetization_on',
  license_free: 'accessibility',
  car_rental: 'car_rental',
  //
  unknown: 'priority_high',
};

CUSTOM_ICONS.forEach((svgIcon) => {
  ICONS_MAP[svgIcon[0]] = {
    svg: true,
    name: svgIcon[0],
  };
});
