export interface CoordinatesInterface {
  x?: number;
  y?: number;
}

export class Coordinates implements CoordinatesInterface {
  x: number = 0;
  y: number = 0;
  constructor(private _conf?: CoordinatesInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}
