import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerInterface } from 'app/models/customer.model';
import { CustomerDataTransformDown } from './data-transforms/customer.data.transform.down';
import { CustomerDataTransformUp } from './data-transforms/customer.data.transform.up';


@Injectable({
  providedIn: 'root',
})
export class CustomerHttpInterceptor implements HttpInterceptor {
  private _rexpCreate = new RegExp(/customers$/);
  private _rexpEdit = new RegExp(/customers\/([0-9a-z]{24})$/);

  constructor(
    private _customerDataTransformDown: CustomerDataTransformDown,
    private _customerDataTransformUp: CustomerDataTransformUp,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this._rexpCreate.test(req.url) && !this._rexpEdit.test(req.url)) {
      //TODO: fix this, one Regex is enough
      return next.handle(req);
    }

    if (req.method === 'POST' || req.method === 'PUT') {
      const bodyCopy = JSON.parse(JSON.stringify(req.body));
      req = req.clone({
        body: this._customerDataTransformDown.transform(<CustomerInterface>bodyCopy),
      });
    }
    // console.log('intercept', req);
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status !== 204) {
          return event.clone({
            body: this._customerDataTransformUp.transform(<CustomerInterface>event.body),
          });
        }
        return event;
      }),
    );
  }
}
