import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';


@Injectable({
  providedIn: 'root',
})
export class CachedParamsService {

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
  ) {
  }

  getParams(localStorageKey: string, defaults?: Params) {
    let cachedParams = this._activatedRoute.snapshot.queryParams;
    if (!Object.keys(cachedParams).length && !!localStorageKey) {
      const lsItem = window.localStorage.getItem(localStorageKey);
      if (lsItem) {
        cachedParams = JSON.parse(lsItem);
      }
    }
    return <any>(defaults ? Object.assign(defaults, cachedParams) : cachedParams);
  }

  getCachedParams(localStorageKey: string, defaults?: Params) {
    let cachedParams = {};
    const lsItem = window.localStorage.getItem(localStorageKey);
    if (lsItem) {
      cachedParams = JSON.parse(lsItem);
    }
    return <any>(defaults ? Object.assign(defaults, cachedParams) : cachedParams);
  }

  getMergedParams(localStorageKey: string, defaults: Params = {}) {
    let lsParams = {};
    if (!!localStorageKey) {
      const lsItem = window.localStorage.getItem(localStorageKey);
      if (lsItem) {
        lsParams = JSON.parse(lsItem);
      }
    }
    let queryParams = this._activatedRoute.snapshot.queryParams;
    return Object.assign({}, defaults, lsParams, queryParams);
  }

  cacheParams(localStorageKey: string, params: Params, mergeWithPrevious: boolean = false) {
    let newParams = params;
    if (mergeWithPrevious) {
      const currentParams = this.getCachedParams(localStorageKey);
      newParams = { ...currentParams, ...params };
    }
    window.localStorage.setItem(localStorageKey, JSON.stringify(newParams));
  }

  navigateWithParams(params: Params, merge: boolean = false) {
    return this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: merge ? { ...this._activatedRoute.snapshot.queryParams, ...params } : params,
    });
  }

  cacheAndApplyQueryParams(localStorageKey: string, params: Params, merge: boolean = false) {
    this.cacheParams(localStorageKey, params);
    this.navigateWithParams(params, merge);
  }
}

