import { MatPaginatorIntl } from '@angular/material/paginator';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nService } from 'app/services/i18n.service';

export class PaginatorI18n {
  constructor(private readonly _i18nService: I18nService) {}

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel = this._i18nService.translate(_('Per pagina'));
    paginatorIntl.nextPageLabel = this._i18nService.translate(_('Prossimo'));
    paginatorIntl.previousPageLabel = this._i18nService.translate(_('Precedente'));
    paginatorIntl.firstPageLabel = this._i18nService.translate(_('Prima pagina'));
    paginatorIntl.lastPageLabel = this._i18nService.translate(_('Ultima pagina'));
    paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
    return paginatorIntl;
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return this._i18nService.translate(_('0 di {length}'), { length });
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this._i18nService.translate(_('{ startIndex } - { endIndex } di { length }'), {
      startIndex: startIndex + 1,
      endIndex,
      length,
    });
  }
}
