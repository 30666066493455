import { Component, OnInit, Input, OnChanges, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { Monitor, MonitorInterface } from 'app/models/system.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SystemStatus, SystemStatusColorEnum, SystemStatusEnum, SystemStatusesList } from './monitor-status-icon.model';
import { SchedulingEnum } from 'app/models/scheduling.model';
import { I18nService } from 'app/services/i18n.service';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';

/**
 *
 *
 * @export
 * @class MonitorStatusIconComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-monitor-status-icon',
  templateUrl: './monitor-status-icon.component.html',
  styleUrls: ['./monitor-status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonitorStatusIconComponent implements OnInit, OnChanges {

  @Input() monitor: MonitorInterface;
  @Input() showLabel: boolean = false;
  @Input() showTooltip: boolean = true;

  status: SystemStatus = null;
  color: SystemStatusColorEnum;

  SystemStatusColorEnum = SystemStatusColorEnum;
  SystemStatusEnum = SystemStatusEnum;

  systemStatusesListTranslated;
  statusMap;

  constructor(
    private _i18nService: I18nService,
    private _currentUserService: CurrentUserService,
  ) { }

  ngOnInit() {
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refresh();
    // console.log('ngOnChanges', changes);
  }

  get isVisible() {
    return this.status.value !== SystemStatusEnum.disabled || this._currentUserService.isAdmin();
  }

  getStatus() {
    // console.log('getStatus', this.monitor);

    // NO MONITOR -> OFFLINE GREY
    if (!this._hasMonitor) {
      this.color = SystemStatusColorEnum.grey;
      return this.statusMap[SystemStatusEnum.offline];

    } else {
      // HAS MONITOR
      const monitor = new Monitor(this.monitor);

      // INCIDENT ? RED : GREEN
      if (monitor.incident) {
        this.color = SystemStatusColorEnum.red;
      } else {
        if (this._hasPing) {
          this.color = SystemStatusColorEnum.green;
        } else {
          this.color = SystemStatusColorEnum.grey;
        }
      }

      // MONITOR DISABLED
      if (monitor.schedulingType === SchedulingEnum.disabled) {
        return this.statusMap[SystemStatusEnum.disabled];
      } else {
        // MONITOR ENABLED
        switch (true) {
          // UNMOUNTED
          case monitor.unmounted: {
            return this.statusMap[SystemStatusEnum.unmounted];
          }
          // INACTIVE
          case monitor.inactive: {
            return this.statusMap[SystemStatusEnum.inactive];
          }
          // UNINSALLED
          case monitor.notInstalled: {
            return this.statusMap[SystemStatusEnum.uninstalled];
          }
        }
        // OFFLINE
        if (monitor.incident) {
          return this.statusMap[SystemStatusEnum.offline];
        }
      }
    }

    // OTHERWISE ONLINE GREEN OR GREY
    return this.statusMap[SystemStatusEnum.online];
  }

  refresh() {
    this.systemStatusesListTranslated = this._i18nService.translateArray(SystemStatusesList, ['label']);
    this.statusMap = this.systemStatusesListTranslated.reduce((acc, val) => {
      acc[val.value] = val;
      return acc;
    }, {});
    this.status = this.getStatus();

    // console.log('status updated', this.status);
  }

  get _hasMonitor() {
    return !!this.monitor;
  }

  get _hasPing() {
    return this._hasMonitor && !!this.monitor.playerPing;
  }
}

