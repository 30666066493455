import { Directive, ElementRef, OnInit } from '@angular/core';

// ADD DEFAULT TYPE "button" to buttons
@Directive({
  selector: 'button:not([type]), button[type=""]',
})
export class ButtonTypeDirective implements OnInit {
  constructor(private _el: ElementRef) {
    const el = this._el.nativeElement;
    if (!el.getAttribute('type')) {
      el.setAttribute('type', 'button');
    }
  }
  ngOnInit() {}
}
