import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { TableCellComponentInterface } from '../interfaces/table-cell-component.interface';
import { TableColumn } from './table-column.class';
import { TableCellChangeEvent, TableCellClickEvent } from '../interfaces/table-events.interface';
import { TableRowInterface } from '../interfaces/table-row.interface';

@Directive()
export class TableCellComponent implements TableCellComponentInterface {
  @Input() column: TableColumn;
  @Input() options: any;
  @Input() row: TableRowInterface;
  @Input() index: number;
  @Input() disabled: boolean;
  @Input() value: any;
  @Output() innerClick: EventEmitter<TableCellClickEvent>;
  @Output() innerChange: EventEmitter<TableCellChangeEvent>;
}
