import { NgModule } from '@angular/core';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgMaterialModule } from 'app/ng-material/ng-material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [ConfirmDialogComponent],
    imports: [CommonModule, FuseSharedModule, NgMaterialModule, TranslateModule]
})
export class ConfirmDialogComponentModule {}
