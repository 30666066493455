import { Injectable } from '@angular/core';
import { DataTransformAbstractDown } from 'app/modules/global/classes/data.transform.abstract.down';
import { DatePipe } from '@angular/common';
import { FeedInterface } from 'app/models/feed.model';
import { FieldInterface } from 'app/modules/common/inputs/custom-fields/fields/fields.interface';

@Injectable({
  providedIn: 'root'
})
export class FeedDataTransformDown extends DataTransformAbstractDown {
  /**
   *Creates an instance of FeedDataTransformDown.
   * @param {DatePipe} datePipe
   * @memberof FeedDataTransformDown
   */
  constructor(protected datePipe: DatePipe) {
    super();
  }
  /**
   *
   *
   * @param {FeedInterface} feed
   * @returns
   * @memberof FeedDataTransform
   */
  transform(feed: FeedInterface): FeedInterface {
    // if (feed.created_at) {
    //   feed.created_at = this.normalizeTime(feed.created_at.toString());
    // }
    // if (feed.updated_at) {
    //   feed.updated_at = this.normalizeTime(feed.updated_at.toString());
    // }
    feed.fields = this.normalizeFields(feed.fields);
    return feed;
  }
  /**
   *
   *
   * @protected
   * @param {FieldInterface[]} contents
   * @returns {FieldInterface[]}
   * @memberof FeedDataTransformDown
   */
  protected normalizeFields(contents: FieldInterface[]): FieldInterface[] {
    if (!Array.isArray(contents)) {
      return contents;
    }
    contents.map((feed: FieldInterface) => {
      if (feed.type === 'input' && feed.defaultValue) {
        if (feed.templateOptions.type === 'date') {
          feed.defaultValue = this.normalizeDate(feed.defaultValue.toString());
        }
        if (feed.templateOptions.type === 'month') {
          feed.defaultValue = this.normalizeMonth(feed.defaultValue.toString());
        }
        if (feed.templateOptions.type === 'time') {
          feed.defaultValue = this.normalizeTime(feed.defaultValue.toString());
        }
      }
    });
    return contents;
  }
}

