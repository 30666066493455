import { TemplateRef } from '@angular/core';
import { Resource } from 'app/models/resource.model';

export interface HintServiceInterface {
  data?: HintDataInterface;
  tooltip?: string;
  title?: string;
  footer?: string;
}

export interface HintItemInterface {
  component?: any | [any, any];
  template?: TemplateRef<any> | [TemplateRef<any>, any];
  video?: Resource | string;
  tooltip?: string;
  text?: string;
  title?: string;
  footer?: string;
  iconClass?: string;
}

export interface HintDataItemInterface extends HintItemInterface {
  key?: string;
  text?: string;
  actionText?: string;
}

export interface HintDataInterface {
  [key: string]: HintDataItemInterface;
}

export class HintItem implements HintItemInterface {
  component: any | [any, any];
  template: TemplateRef<any> | [TemplateRef<any>, any];
  video: Resource | string;
  tooltip: string;
  title: string;
  text: string;
  footer: string;

  constructor(private _conf?: HintDataItemInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}
