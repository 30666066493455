import { navigation } from 'app/navigation/navigation';
import { FuseConfigService } from '@fuse/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { I18nService } from 'app/services/i18n.service';
import { HintService } from 'app/modules/common/hint/hint.service';
import { SentryService } from 'app/services/sentry.service';

@Component({
  selector: 'vertical-layout-1',
  templateUrl: './layout-1.component.html',
  styleUrls: ['./layout-1.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _i18nService: I18nService,
    private _hintService: HintService,
    private _sentryService: SentryService,
  ) {
    // Get default navigation
    this.navigation = navigation({
      I18nService: this._i18nService,
      HintService: this._hintService,
      SentryService: this._sentryService,
    });

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.fuseConfig = config;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
