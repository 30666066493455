import { Component, OnInit, Input, OnChanges } from '@angular/core';

/**
 *
 *
 * @export
 * @class FpsComponent
 * @description Display FPS value
 */
@Component({
  selector: 'app-fps',
  templateUrl: './fps.component.html',
  styleUrls: ['./fps.component.scss'],
})
export class FpsComponent implements OnInit, OnChanges {
  @Input() value: number;

  roundedFps: number;

  constructor() {}

  ngOnInit() {
    this.refresh();
  }

  ngOnChanges() {
    this.refresh();
  }

  refresh() {
    this.roundedFps = Math.round(this.value);
  }
}
