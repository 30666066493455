import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../../core/auth/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(public _authService: AuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.match(/auth\/login_check|auth\/password\/reset|init\/permissions/)) {
      return next.handle(req);
    }
    const header = {
      Authorization: `Bearer ${this._authService.getToken()}`
    };
    if(localStorage.getItem('userImpersonated')) {
      header['x-switch-user'] = localStorage.getItem('userImpersonated');
    }
    if(this._authService.getToken()) {
        req = req.clone({setHeaders : header});
    }

    return next.handle(req);
  }
}
