import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DateRangeInterface, DateRange } from './dateslot.interface';
import { DateslotDialogComponent } from '../dialogs/dateslot-dialog/dateslot-dialog.component';
import { DatesRangeDialogComponent } from '../dialogs/dates-range-dialog/dates-range-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DateslotService {
  constructor(protected _matDialog: MatDialog) { }

  buildRange(range: DateRangeInterface[] = [], opts?: { required?: boolean, hasTime?: boolean }) {

    opts = { required: true, hasTime: false, ...opts };

    return range.map((item: DateRangeInterface) => {
      return new FormGroup(this.buildRangeItem(item, opts));
    });
  }

  buildRangeItem(item?: DateRangeInterface, opts?: { required?: boolean, hasTime?: boolean }) {

    opts = { required: true, hasTime: false, ...opts };

    // TODO: set to true when we are ready to activate TIME in DATE RANGES
    opts.hasTime = true;

    item = new DateRange(item);
    const obj = {
      startAt: new FormControl(
        item.startAt,
        opts && opts.required
          ? [
            (control) => {
              if (control && control.parent) {
                if (!control.parent.get('endAt').value || (opts.hasTime && control.parent.get('startTimeAt').value)) {
                  return Validators.required(control);
                }
              }
              return null;
            },
          ]
          : [],
      ),
      endAt: new FormControl(
        item.endAt,
        opts && opts.required
          ? [
            (control) => {
              if (control && control.parent) {
                if (!control.parent.get('startAt').value || (opts.hasTime && control.parent.get('endTimeAt').value)) {
                  return Validators.required(control);
                }
              }
              return null;
            },
          ]
          : [],
      ),
    };

    if (opts.hasTime) {
      obj['startTimeAt'] = new FormControl(
        item.startTimeAt,
        // opts && opts.required
        //   ? [
        //       (control) => {
        //         if (control && control.parent) {
        //           if (!control.parent.get('endTimeAt').value) {
        //             return Validators.required(control);
        //           }
        //         }
        //         return null;
        //       },
        //     ]
        //   : [],
      );
      obj['endTimeAt'] = new FormControl(
        item.endTimeAt,
        // opts && opts.required
        //   ? [
        //       (control) => {
        //         if (control && control.parent) {
        //           if (!control.parent.get('startTimeAt').value) {
        //             return Validators.required(control);
        //           }
        //         }
        //         return null;
        //       },
        //     ]
        //   : [],
      );
    }

    return obj;
  }

  openDateRangeDialog(
    rangeCtrl: FormArray | AbstractControl,
    inMultiSelection: boolean = false,
    min?: Date | string,
    max?: Date | string,
    range?: DateRangeInterface[],
    required?: boolean,
    hasTime?: boolean,
    allRequired?: boolean,
  ) {
    return this._matDialog.open(DatesRangeDialogComponent, {
      panelClass: 'date-rages-dialog',
      data: {
        inMultiSelection,
        rangeCtrl: new FormArray(this.buildRange(rangeCtrl.value, { hasTime: hasTime })),
        min,
        max,
        range,
        required,
        hasTime,
        allRequired,
      },
    });
  }

  openDateslotDialog(
    group: FormGroup,
    min?: Date | string,
    max?: Date | string,
    required?: boolean,
    hasTime?: boolean,
  ) {
    return this._matDialog.open(DateslotDialogComponent, {
      panelClass: 'dateslot-dialog',
      data: {
        group: new FormGroup(this.buildRangeItem(group.value, { hasTime: hasTime })),
        min,
        max,
        required,
        hasTime,
      },
    });
  }
}
