import { Injectable } from '@angular/core';
import { APP_FORMATTER } from './formatter';
import { TimeslotInterface } from '../timeslot/timeslot.interface';
import { DatePipe } from '@angular/common';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nService } from 'app/services/i18n.service';
import { TIME_FORMAT } from 'app/modules/global/constants';

@Injectable({
  providedIn: 'root',
})
export class StringService {
  constructor(
    private _datePipe: DatePipe,
    private _i18nService: I18nService,
  ) { }

  getLetterByIndex(value: number) {
    return (value + 10).toString(36);
  }

  getResolutionString(w: string | number, h: string | number, unit: string = 'px') {
    return `${w}x${h}${unit}`;
  }

  buildNumberRoundOptions(decimals = APP_FORMATTER.decimalOptions.minimumFractionDigits, localeStringOptions) {
    const defaults = APP_FORMATTER.decimalOptions;
    if (typeof decimals !== 'undefined' && decimals !== null && decimals >= 0) {
      defaults.minimumFractionDigits = decimals;
      defaults.maximumFractionDigits = decimals;
    }
    return Object.assign({}, defaults, localeStringOptions);
  }

  format(value, localeStringOptions?, locale?) {
    return parseFloat('' + value).toLocaleString(locale, localeStringOptions);
  }

  getTimeString(value: Date) {
    const timeStr = value ? this._datePipe.transform(
      value,
      this._i18nService.translate(TIME_FORMAT),
    ) : '--:--';
    return timeStr;
  }

  getTimeslotString(timeslot: TimeslotInterface, config: { fromField: string, toField: string } = {
    fromField: 'from',
    toField: 'to',
  }) {
    const from = timeslot[config.fromField];
    const to = timeslot[config.toField];
    const hasNextDayTime = !!from && !!to && from >= to;
    const nextDayStr = hasNextDayTime ? '*' : '';
    return `[ ${this.getTimeString(from)} - ${this.getTimeString(to)}${nextDayStr} ]`;
  }

  getTimeslotsString(timeslots: TimeslotInterface[], config: { fromField: string, toField: string } = {
    fromField: 'from',
    toField: 'to',
  }) {
    return timeslots.map(ts => {
      return this.getTimeslotString(ts, config);
    }).join(' ');
  }
}
