import { NgModule } from '@angular/core';
import { TimeagoModule, TimeagoIntl, TimeagoCustomFormatter, TimeagoFormatter } from 'ngx-timeago';
import { TimeagoComponent } from './timeago.component';

@NgModule({
  declarations: [TimeagoComponent],
  imports: [TimeagoModule.forRoot({ formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter } })],
  providers: [TimeagoIntl],
  exports: [TimeagoComponent],
})
export class TimeagoCustomModule {}
