import {
  WeeklySchedulingInterface,
  TimeslotsCalendarInterface,
  TimeslotInterface,
  WeeklyScheduling,
  TimeslotsCalendar,
  Timeslot,
} from 'app/modules/common/timeslot/timeslot.interface';
import { PlaylistItemInterface } from 'app/modules/editorial/editorial.interface';
import { DateService } from 'app/utils/services/date.service';
import { MonitorInterface, SystemInterface } from './system.model';
import { endOfDay, startOfDay } from 'date-fns';

// TODO: REMEMBER TO sync timestlot.interface.ts
export enum SchedulingEnum {
  none = 'none',
  daily = 'daily',
  weekly = 'weekly',
  calendar = 'calendar',
  '24_7' = '24_7',
  disabled = 'disabled',
  poweredOff = 'poweredOff',
}

export interface SchedulingInterface {
  alwaysOn?: boolean;
  schedulingType?: SchedulingEnum;
  timeslots?: Timeslot[];
  weeklyScheduling?: WeeklySchedulingInterface[];
  calendarScheduling?: TimeslotsCalendarInterface[];
  getTimeslotsByDay?(day: Date | string): TimeslotInterface[];
}

export class Scheduling implements SchedulingInterface {
  alwaysOn: boolean;
  schedulingType: SchedulingEnum = SchedulingEnum.none;
  timeslots: Timeslot[] = [];
  weeklyScheduling: WeeklySchedulingInterface[] = [];
  calendarScheduling: TimeslotsCalendarInterface[] = [];

  constructor(private _conf: SchedulingInterface | SystemInterface, private _resetIncompatibleFields?: boolean) {
    if (this._resetIncompatibleFields) {
      const tempConf = Scheduling.getSystemScheduling(this._conf);
      const realConf = Scheduling.normalizeScheduling(tempConf);
      Object.assign(this, realConf);
    } else {
      Object.assign(this, _conf);
    }
    delete this._conf;
    delete this._resetIncompatibleFields;
  }

  static normalizeScheduling(scheduling: SchedulingInterface) {
    if (scheduling.alwaysOn) {
      scheduling.schedulingType = SchedulingEnum.none;
      scheduling.timeslots = [];
      scheduling.weeklyScheduling = [];
      scheduling.calendarScheduling = [];
    } else {
      switch (scheduling.schedulingType) {
        case SchedulingEnum.none: {
          scheduling.timeslots = [];
          scheduling.weeklyScheduling = [];
          scheduling.calendarScheduling = [];
          break;
        }
        case SchedulingEnum.daily: {
          scheduling.weeklyScheduling = [];
          scheduling.calendarScheduling = [];
          break;
        }
        case SchedulingEnum.weekly: {
          scheduling.timeslots = [];
          scheduling.calendarScheduling = [];
          break;
        }
        case SchedulingEnum.calendar: {
          scheduling.timeslots = [];
          scheduling.weeklyScheduling = [];
          break;
        }
        case SchedulingEnum['24_7']: {
          scheduling.timeslots = [];
          scheduling.weeklyScheduling = [];
          scheduling.calendarScheduling = [];
          break;
        }
      }
    }

    return scheduling;
  }

  static getSystemScheduling(system: SystemInterface) {
    return {
      alwaysOn: system.alwaysOn,
      schedulingType: system.schedulingType,
      timeslots:
        system.on && system.off
          ? [
            new Timeslot({
              startAt: new Date(system.on),
              endAt: new Date(system.off),
            }),
          ]
          : [],
      weeklyScheduling: this._normalizeWeeklyScheduling(system.weeklyScheduling),
      calendarScheduling: this._normalizeCalendarScheduling(system.calendarScheduling),
    };
  }

  static getSystemMonitorScheduling(monitor: MonitorInterface) {
    if (!monitor) return null;
    return {
      schedulingType: monitor.schedulingType,
      timeslots:
        monitor.range && monitor.range.startAt && monitor.range.endAt
          ? [
            new Timeslot({
              startAt: new Date(monitor.range.startAt),
              endAt: new Date(monitor.range.endAt),
            }),
          ]
          : [],
      weeklyScheduling: this._normalizeWeeklyScheduling(monitor.weeklyScheduling),
    };
  }

  static getContentScheduling(content: PlaylistItemInterface) {
    return {
      schedulingType: content.schedulingType || SchedulingEnum.none,
      timeslots: (content.timeslots || []).map((ts) => {
        return new Timeslot({
          startAt: new Date(ts.from),
          endAt: new Date(ts.to),
        });
      }),
      weeklyScheduling: this._normalizeWeeklyScheduling(content.weeklyScheduling),
      calendarScheduling: this._normalizeCalendarScheduling(content.calendarScheduling),
    };
  }

  private static _normalizeTimeslotsScheduling(timeslots: Timeslot[] = []) {
    return timeslots.map((ts) => {
      return new Timeslot({
        startAt: new Date(ts.from),
        endAt: new Date(ts.to),
      });
    });
  }

  private static _normalizeWeeklyScheduling(weeklyScheduling: WeeklySchedulingInterface[] = []) {
    return weeklyScheduling.map((w) => {
      w = new WeeklyScheduling(w);
      w.timeslots = this._normalizeTimeslotsScheduling(w.timeslots);
      return w;
    });
  }

  private static _normalizeCalendarScheduling(calendarScheduling: TimeslotsCalendarInterface[] = []) {
    return calendarScheduling.map((c) => {
      c = new TimeslotsCalendar(c);
      c.timeslots = this._normalizeTimeslotsScheduling(c.timeslots);
      c.weeklySchedulings = this._normalizeWeeklyScheduling(c.weeklySchedulings);
      return c;
    });
  }

  private _indexOfWeeklyScheduling(weekdayIndex: number, weekArray: WeeklySchedulingInterface[] = []) {
    let i = 0;
    for (const weekday of weekArray) {
      if (weekday.weekday === weekdayIndex) {
        return i;
      }
      i++;
    }
    return -1;
  }

  private _getWeeklySchedulingTimeslots(day: Date | string, weeklyScheduling: WeeklySchedulingInterface[] = []) {
    const dayInfo = DateService.dateInfo(day);
    const i = this._indexOfWeeklyScheduling(dayInfo.weekday, weeklyScheduling);
    return i === -1 ? null : weeklyScheduling[i].alwaysOn ? [] : weeklyScheduling[i].timeslots;
  }

  private _getCalendarSchedulingTimeslots(day: Date | string, calendarScheduling: TimeslotsCalendarInterface[] = []) {
    const rules = [].concat(calendarScheduling);

    rules.sort((a: TimeslotsCalendarInterface, b: TimeslotsCalendarInterface) => {
      let A = new Date(a.startAt).getTime();
      let B = new Date(b.startAt).getTime();
      if (a.default) {
        A = -Infinity;
      }
      if (b.default) {
        B = -Infinity;
      }
      return A < B ? 1 : -1;
    });

    for (let i = 0; i < rules.length; i++) {
      const rule: TimeslotsCalendarInterface = rules[i];
      const A = rule.startAt ? startOfDay(DateService.parse(rule.startAt)) : null;
      const B = rule.endAt ? endOfDay(DateService.parse(rule.endAt)) : null;
      if (rule.default || DateService.isRangeInRange(A, B, startOfDay(DateService.parse(day)), endOfDay(DateService.parse(day)))) {
        if (rule.type === SchedulingEnum.weekly) {
          return this._getWeeklySchedulingTimeslots(day, rule.weeklySchedulings);
        } else if (rule.type === SchedulingEnum.daily) {
          // daily
          return rule.timeslots;
        } else if (rule.type === SchedulingEnum.poweredOff) {
          // poweredOff
          return null;
        } else {
          // 24_7
          return [];
        }
      }
    }
    return null;
  }

  isEnabled() {
    return this.schedulingType && this.schedulingType !== SchedulingEnum.none;
  }

  getTimeslotsByDay(day?: Date | string): TimeslotInterface[] {
    if (this.alwaysOn) {
      return [];
    }

    switch (this.schedulingType) {
      case SchedulingEnum.daily: {
        return this.timeslots;
      }
      case SchedulingEnum.weekly: {
        return this._getWeeklySchedulingTimeslots(day, this.weeklyScheduling);
      }
      case SchedulingEnum.calendar: {
        return this._getCalendarSchedulingTimeslots(day, this.calendarScheduling);
      }
    }

    return null;
  }
}
