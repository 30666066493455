import { Component, Inject, ViewEncapsulation, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FormUtils } from 'app/modules/global/classes/form-utils';
import { I18nService } from 'app/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { DatesRangeInterface } from '../../dateslots/dateslot.interface';

@Component({
  selector: 'app-dates-range-dialog',
  templateUrl: './dates-range-dialog.component.html',
  styleUrls: ['./dates-range-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatesRangeDialogComponent implements OnInit {
  constructor(
    public matDialogRef: MatDialogRef<DatesRangeDialogComponent>,
    private _toastrService: ToastrService,
    private _cdr: ChangeDetectorRef,
    private _i18nService: I18nService,
    @Inject(MAT_DIALOG_DATA) public data: DatesRangeInterface,
  ) { }

  ngOnInit(): void {
    console.log('DatesRangeDialogComponent', this);
  }

  onSchedulingTypeChange() {
    this._cdr.detectChanges();
  }

  get required() {
    return this.data && this.data.required;
  }

  get allRequired() {
    return this.data && this.data.allRequired;
  }

  get min() {
    return this.data && this.data.min;
  }

  get max() {
    return this.data && this.data.max;
  }

  get range() {
    return this.data && this.data.range;
  }

  get inMultiSelection() {
    return this.data && this.data.inMultiSelection;
  }

  get hasTime() {
    return this.data && this.data.hasTime;
  }

  get rangeControl() {
    return this.data && this.data.rangeCtrl;
  }

  save() {
    FormUtils.validateAllFormFields(this.rangeControl);

    console.log('save', this.rangeControl);

    if (this.rangeControl.valid) {
      this.matDialogRef.close(
        this.rangeControl && {
          rangeControl: this.rangeControl,
        },
      );
    } else {
      this._toastrService.error(this._i18nService.translate(_('Compila correttamente tutti i campi')));
    }
  }
}
