import { NgModule } from '@angular/core';
import { GlobalModule } from 'app/modules/global/global.module';
import { SortDirectionComponent } from './sort-direction.component';
@NgModule({
    declarations: [
        SortDirectionComponent
    ],
    imports: [
        GlobalModule
    ],
    exports: [
        SortDirectionComponent
    ]
})
export class SortDirectionComponentModule {}
