import { Directive, Input, OnInit, ViewContainerRef, ComponentFactoryResolver, OnChanges, ComponentRef, Output, EventEmitter, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { InputClearComponent } from './input-clear/input-clear.component';

@Directive({
  selector: '[matDatepicker], [clearable]'
})
export class InputClearableDirective implements OnInit, OnChanges {

  @Output() cleared: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @Input() disabled: boolean;

  @Input()
  get clearable(): boolean { return this._clearable; }
  set clearable(value: boolean) {
    this._clearable = coerceBooleanProperty(value);
  }
  private _clearable = true;

  cmpRef: ComponentRef<InputClearComponent>;

  onRChange: any = () => { };
  onRTouched: any = () => { };

  constructor(
    @Self() private ngControl: NgControl,
    private target: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
  }

  ngOnInit(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(InputClearComponent);
    this.cmpRef = this.target.createComponent(factory);
    this.cmpRef.instance.ngControl = this.ngControl;
    this.cmpRef.instance.cleared.subscribe(($event: MouseEvent) => {
      this.cleared.emit($event);
    });

    this.refreshVisibility();
  }

  ngOnChanges(changes): void {
    if ((changes.clearable && !changes.clearable.firstChange) || (changes.disabled && !changes.disabled.firstChange)) {
      this.refreshVisibility();
    }
  }

  refreshVisibility() {
    const enabled = this.clearable && !this.disabled;
    // console.log('InputClearableDirective', this.clearable, 'disabled', this.disabled, 'control', this.ngControl);

    if (!!this.cmpRef && !!this.cmpRef.instance) {
      if (enabled) {
        this.cmpRef.instance.enable();
      } else {
        this.cmpRef.instance.disable();
      }
    }
  }
}
