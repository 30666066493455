import { Injectable } from '@angular/core';
import { ONE_DAY, ONE_HOUR, ONE_MINUTE, ONE_SECOND } from 'app/utils/services/date.service';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface FluidSecondsToDurationOptionsInterface {
  hideEmpty?: boolean;
  hideSeconds?: boolean;
  showDays?: boolean;
  unitsArr?: string[];
}

export class FluidSecondsToDurationOptions implements FluidSecondsToDurationOptionsInterface {
  hideEmpty: boolean = false;
  hideSeconds: boolean = false;
  showDays: boolean = false;
  unitsArr: string[] = [
    _('s@@Carattere abbreviato di secondi'),
    _('m@@Carattere abbreviato di minuti'),
    _('h@@Carattere abbreviato di ore'),
    _('d@@Carattere abbreviato di giorni'),
  ];

  constructor(private _conf?: FluidSecondsToDurationOptionsInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FluidDurationService {
  constructor(private _i18nService: I18nService) { }

  secondsToHuman(value: number, opts?: FluidSecondsToDurationOptionsInterface) {
    opts = new FluidSecondsToDurationOptions(opts);
    let unitArr = opts.unitsArr;

    unitArr = unitArr.map((v) => {
      return this._i18nService.translate(v);
    });

    let v = Math.abs((Math.round(value) || 0) * 1000);
    let timeArr = [];

    if (opts.showDays) {
      const d = Math.floor(v / ONE_DAY);
      timeArr.push(d);
      v -= d * ONE_DAY;
    } else {
      unitArr.pop();
    }

    const h = Math.floor(v / ONE_HOUR);
    timeArr.push(h);
    v -= h * ONE_HOUR;
    const m = Math.floor(v / ONE_MINUTE);
    timeArr.push(m);
    v -= m * ONE_MINUTE;

    // console.log('calc seconds', v);
    if (opts.hideSeconds) {
      unitArr.splice(0, 1);
    } else {
      const s = Math.floor(v / ONE_SECOND);
      timeArr.push(s);
    }

    timeArr.reverse();
    if (opts.hideEmpty) {
      for (let i = timeArr.length - 1; i >= 0; i--) {
        if (!i || timeArr[i] > 0) {
          continue;
        } else {
          timeArr.splice(i, 1);
          unitArr.splice(i, 1);
        }
      }
    }
    timeArr = timeArr.map((num, index) => {
      return num.toString().padStart(2, '0') + unitArr[index];
    });
    timeArr.reverse();
    return timeArr.join(' ');
  }

}
