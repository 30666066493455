import { Directive, ViewContainerRef, Output, NgModule, OnChanges, HostListener, ComponentRef } from '@angular/core';
import { Type } from '@angular/core';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ComponentFactoryResolver,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { TableColumn } from '../../classes/table-column.class';
import { TableCellComponentInterface } from '../../interfaces/table-cell-component.interface';
import { FilterFieldComponent } from 'app/modules/common/table/components/filters/filter-field/filter-field.component';
import { Table } from '../../classes/table.class';
import { TableFilterFieldComponent, TableFilterFieldEventInterface } from './table-component-filter-field';

/**
 * @description
 * Directive that injects a specific component template.
 */
@Directive({
  selector: '[filterComponent]',
})
export class InjectorFilterDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

/**
 * @description
 * Component that injects specific components in Table cells.
 */
@Component({
  selector: 'app-filter-injector',
  template: `<ng-template filterComponent></ng-template>`,
  encapsulation: ViewEncapsulation.None,
})
export class InjectorFilterComponent implements OnInit, OnChanges {
  @Input() component?: Type<TableFilterFieldComponent> | [Type<TableFilterFieldComponent>, any];
  @Input() column: TableColumn;
  @Input() value: any;
  @Input() table: Table;
  @Output() filterChange: EventEmitter<TableFilterFieldEventInterface> = new EventEmitter();

  @ViewChild(InjectorFilterDirective, { static: true }) templateData: InjectorFilterDirective;

  constructor(private _componentFactoryResolver: ComponentFactoryResolver) {}

  cmpRef: ComponentRef<TableFilterFieldComponent>;

  ngOnInit() {
    let component: Type<TableFilterFieldComponent> | [Type<TableFilterFieldComponent>, any],
      options: any = {};

    if (!this.component) {
      component = FilterFieldComponent; // RAW VALUE COMPONENT
    } else {
      if (this.component instanceof Array) {
        // CUSTOM COMPONENT WITH OPTIONS
        component = this.component[0];
        options = this.component[1];
      } else {
        // CUSTOM COMPONENT
        component = this.component;
      }
    }

    const viewContainerRef = this.templateData.viewContainerRef;
    viewContainerRef.clear();

    const cmpFactory = this._componentFactoryResolver.resolveComponentFactory(component);
    this.cmpRef = viewContainerRef.createComponent(cmpFactory);

    // ASSIGN PARAMETERS TO COMPONENT CELL
    Object.assign(this.cmpRef.instance, <TableCellComponentInterface>{
      column: this.column,
      value: this.value,
      table: this.table,
      filterChange: this.filterChange,
    });

    // (<any>this.cmpRef).filterChange.subscribe((value) => {
    //   console.log('::filter change detected', value);
    //   this.value = value;
    // });
  }

  ngOnChanges(changes) {
    if (changes.value && !changes.value.firstChange) {
      // console.log('INJECTOR changes', changes);
      // ASSIGN PARAMETERS TO COMPONENT CELL
      Object.assign(this.cmpRef.instance, <TableCellComponentInterface>{
        value: this.value,
      });
      // TODO: find a way to call ngOnChanges
      this.cmpRef.changeDetectorRef.detectChanges();
    }
  }
}

@NgModule({
    declarations: [InjectorFilterComponent, InjectorFilterDirective],
    imports: [],
    exports: [InjectorFilterComponent]
})
export class TableFilterInjectorModule {}
