import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthRepositoryService } from 'app/repositories/auth-repository.service';
import { JsonWebToken, JsonWebTokenInterface } from 'app/models/json-web-token';


/**
 * The authentication service
 *
 * Provides login/logout functionalities
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  /** The subject that notify login */
  onChange: Subject<any>;
  jwt: JsonWebTokenInterface;

  /** constructor */
  constructor(private _authRepositoryService: AuthRepositoryService) {
    this.jwt = new JsonWebToken();
    this.onChange = new Subject();
    if (this.hasToken()) {
      this.jwt.token = this.getToken();
    }
    if (this.hasExpired()) {
      this.logout();
    }
  }

  /**
   * Login
   *
   * @param username The username
   * @param password The user password
   * @param remember True if you want to save the session on localstorage
   */
  login(username: string, password: string, remember: boolean): Observable<JsonWebTokenInterface> {
    return new Observable(observer => {
      this._authRepositoryService
        .loginCheck(username, password, remember)
        .subscribe(
          (jwt: JsonWebTokenInterface) => {
            this.setToken(jwt.token);
            this.setExpiredAt(jwt.expired);
            observer.next(jwt);
            observer.complete();
          },
          error => {
            this.logout();
            observer.error(error);
          }
        );
    });
  }

  hasToken(): boolean {
    return this.getToken() !== null;
  }

  getToken() {
    return localStorage.getItem('accessToken');
  }

  setToken(token: string) {
    localStorage.setItem('accessToken', token);
    this.jwt.token = token;
  }

  hasExpired() {
    const now = new Date();
    const expired = new Date(this.getExpiredAt());
    return now.getTime() > expired.getTime();
  }

  setExpiredAt(expire_at: string) {
    localStorage.setItem('expiredAt', expire_at);
    this.jwt.expired = expire_at;
  }

  getExpiredAt() {
    return localStorage.getItem('expiredAt');
  }

  /**
   * Logout
   */
  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('expiredAt');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userImpersonated');
    localStorage.removeItem('masterUser');
  }
}
