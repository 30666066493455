import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fluidCurrency' })
export class FluidCurrencyPipe implements PipeTransform {
  static defaultSettings: FluidCurrencySettings = {
    decimalSeparator: ',',
    thousandsSeparator: '.',
    prefix: '',
    suffix: '',
    padding: '000000',
  };

  transform(value: string | number, fractionSize?: number, settings?: FluidCurrencySettings): string {
    settings = Object.assign(FluidCurrencyPipe.defaultSettings, settings);

    let integer,
      fraction = '';

    if (typeof value === 'string') {
      [integer, fraction = ''] = (value || '').toString().split(settings.decimalSeparator);
      if (typeof settings.thousandsSeparator === 'string') {
        integer = integer.split(settings.thousandsSeparator).join('');
      }
    } else {
      [integer, fraction = ''] = (value || '').toString().split('.');
    }

    fraction = fraction.split(settings.thousandsSeparator).join('');

    if (fraction !== '') {
      if (typeof fractionSize !== 'undefined' && fractionSize !== null) {
        fraction =
          fractionSize > 0 ? settings.decimalSeparator + (fraction + settings.padding).substring(0, fractionSize) : '';
      } else {
        fraction = settings.decimalSeparator + fraction;
      }
    }

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, settings.thousandsSeparator);

    // console.log('transform interger, fraction', integer, fraction);
    return settings.prefix + integer + fraction + settings.suffix;
  }

  parse(value: string, fractionSize?: number, settings?: FluidCurrencySettings): string {
    settings = Object.assign(FluidCurrencyPipe.defaultSettings, settings);

    let [integer, fraction = ''] = (value || '')
      .replace(settings.prefix, '')
      .replace(settings.suffix, '')
      .split(settings.decimalSeparator);

    // if (typeof settings.thousandsSeparator === 'string') {
    //   integer = integer.split(settings.thousandsSeparator).join('');
    // }

    if (fraction !== '') {
      if (typeof fractionSize !== 'undefined' && fractionSize !== null) {
        fraction =
          fractionSize > 0 ? settings.decimalSeparator + (fraction + settings.padding).substring(0, fractionSize) : '';
      } else {
        fraction = settings.decimalSeparator + fraction;
      }
    }

    // fraction =
    //   parseInt(fraction, 10) > 0 && fractionSize > 0
    //     ? settings.decimalSeparator + (fraction + settings.padding).substring(0, fractionSize)
    //     : '';

    console.log('parse interger, fraction', integer, fraction);

    return integer + fraction;
  }
}

export interface FluidCurrencySettings {
  decimalSeparator: string;
  thousandsSeparator: string;
  prefix: string;
  suffix: string;
  padding: string;
}
