import { ViewChild, Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { ActivatedRoute, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'app/core/auth/services/authentication.service';
import { CurrentUserService } from '../../services/current-user.service';
import { of, Observable, Subject, throwError } from 'rxjs';
import { ENTER } from '@angular/cdk/keycodes';
import { I18nService } from 'app/services/i18n.service';
import { Language } from 'app/services/i18n.languages';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NavigationService } from 'app/modules/global/services/navigation.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
  @ViewChild('submitButton', { read: ElementRef }) submitButton: ElementRef<HTMLInputElement>;

  loginForm: FormGroup;
  returnUrl: string;
  loginError: Observable<any> = new Observable<any>();

  showPassword: boolean;

  LOGIN_ERROR_STATUS = LOGIN_ERROR_STATUS;

  errorAuth: number = 0;
  retryAfter: number = 0;

  private _stopMonitoringNavigationChange: Subject<any>;

  /**
   * @param _fuseConfigService
   * @param _formBuilder
   * @param route
   * @param router
   * @param _authenticationService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _authenticationService: AuthenticationService,
    private _currentUser: CurrentUserService,
    private _i18nService: I18nService,
    private _toastrService: ToastrService,
  ) {
    this._stopMonitoringNavigationChange = new Subject();

    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  get languages(): Language[] {
    return environment.languages ? I18nService.languages || [] : [];
  }

  get currentLanguage() {
    return this._i18nService.currentLanguage;
  }

  setLanguage(lang: Language): void {
    if (lang.code !== this._i18nService.currentLanguageCode) {
      this._i18nService.setLanguage(lang.code);

      this._toastrService.success(this._i18nService.translate(_('Nuova lingua: {language}'), { language: lang.name }));

      // this._toastrService.success(this._i18nService.translate('toasts.languageChange') + ': ' + lang.name);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required],
      remember: [true],
    });
    // reset login status
    if (this._authenticationService.hasToken() && this._currentUser.hasUser() && this._currentUser.isEnabled()) {
      this.router.navigate(['dashboard']);
    } else {
      this._authenticationService.logout();
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
    console.log('LoginComponent returnUrl', this.returnUrl);
    this.loginForm.valueChanges.subscribe(data => {
      this.errorAuth = 0;
    });

    console.log('ROuter', this.router);
  }

  togglePasswordVisibility(event: MouseEvent) {
    this.showPassword = !this.showPassword;
  }

  keypressEvent(event: KeyboardEvent) {
    if (event.keyCode === ENTER) {
      this.submitButton.nativeElement.click();
    }
  }

  get onSubmit(): Observable<any> {
    return this._authenticationService.login(
      this.loginForm.controls.username.value,
      this.loginForm.controls.password.value,
      this.loginForm.controls.remember.value,
    );
  }

  beforeSubmit() {
    this.errorAuth = 0;
  }

  stopMonitoring() {
    this._stopMonitoringNavigationChange.next();
    this._stopMonitoringNavigationChange.complete();
  }

  afterSubmit(response) {
    // GOT TOKEN
    console.log('loggato!', response);

    // GET USER DETAILS
    this._currentUser.loadUserFromRemote().pipe(first(), catchError(error => {
      // GET USER DETAILS FAILED
      console.log('ERROR IS', error);
      this.errorAuth = LOGIN_ERROR_STATUS.userDisabled;
      return throwError(error);
    })).subscribe(data => {
      // GET USER DETAILS SUCCESS
      console.log('_currentUser.getUserMe() success', data);

      // LISTEN RETURN URL NAVIGATION END, IF FAILS REDIRECT TO DASHBOARD
      this.router.events.pipe(takeUntil(this._stopMonitoringNavigationChange)).subscribe(value => {
        // console.log('router event', value);
        if (value instanceof NavigationEnd) {
          console.log('NavigationEnd', value.url, value.urlAfterRedirects);
          this.stopMonitoring();
          if (value.url !== value.urlAfterRedirects) {
            this.router.navigate(['dashboard']);
          }
        }
        if (value instanceof NavigationCancel) {
          console.log('NavigationEnd', value.url, value.reason);
          this.stopMonitoring();
          this.router.navigate(['dashboard']);
        }
      });

      // TRY TO REDIRECT TO RETURN URL
      if (this.returnUrl) {
        console.log('return url redirect', this.returnUrl);
        this.router.navigateByUrl(this.returnUrl).catch(error => {
          console.log('something went wrong. redirect to dashboard', error);
          this.router.navigate(['dashboard']);
        });
      } else {
        console.log('dashboard redirect');
        this.router.navigate(['dashboard']);
      }
    });

  }

  afterError(error) {
    console.log('after error', error);
    if ([LOGIN_ERROR_STATUS.wrongCredentials, LOGIN_ERROR_STATUS.notFound, LOGIN_ERROR_STATUS.tooManyAttempts].indexOf(error.status) === -1) {
      this.errorAuth = 500;
    } else {
      this.errorAuth = error.status;

      if (error.status === LOGIN_ERROR_STATUS.tooManyAttempts) {
        console.log('keys', error.headers.keys());
        console.log('retry-after', error.headers.get('retry-after'));
        this.retryAfter = +error.headers.get('retry-after');
      }
    }
  }

}

export const LOGIN_ERROR_STATUS = {
  wrongCredentials: 401,
  userDisabled: 403,
  notFound: 404,
  tooManyAttempts: 429,
  serverError: 500,
};
