import { NgModule } from '@angular/core';
import { UploaderComponent } from './uploader.component';
import { UploaderImageComponent } from './uploader-image.component';
import { UploaderQueueComponent } from './components/uploader-queue/uploader-queue.component';
import { UploaderQueueInvalidFileComponent } from './components/uploader-queue-invalid-file/uploader-queue-invalid-file.component';
import { UploaderQueueInvalidFileErrorComponent } from './components/uploader-queue-invalid-file-error/uploader-queue-invalid-file-error.component';
import { UploaderQueueValidFileComponent } from './components/uploader-queue-valid-file/uploader-queue-valid-file.component';
import { GlobalModule } from 'app/modules/global/global.module';
import { StringModule } from '../string/string.module';
import { UploaderErrorComponent } from './components/uploader-error/uploader-error.component';
import {
    UploaderResourceComponent,
    UploaderResourceActionsDirective,
} from './components/uploader-resource/uploader-resource.component';
import { UploaderRequirementsComponent } from './components/uploader-requirements/uploader-requirements.component';
import { UploaderTypeComponent } from './components/uploader-type/uploader-type.component';
import { UploaderVideoComponent } from './uploader-video.component';
import { UploaderDocumentComponent } from './uploader-document.component';
import { DatetimeModule } from '../datetime/datetime.module';
import { ResourcesModule } from 'app/modules/resources/resources.module';
import { UploaderStatusComponent } from './components/uploader-status/uploader-status.component';
import { UploaderInputComponent } from './components/uploader-input/uploader-input.component';
import { UploaderDropareaComponent } from './components/uploader-droparea/uploader-droparea.component';
import { UploaderFilesDirective } from './uploader-files.directive';
import { UploaderGalleryComponent } from './components/uploader-gallery/uploader-gallery.component';
import { UploaderDialogComponent } from './dialogs/uploader-dialog/uploader-dialog.component';
import { UploaderImagesVideosComponent } from './uploader-images-videos.component';

@NgModule({
    declarations: [
        UploaderComponent,
        UploaderTypeComponent,
        UploaderResourceComponent,
        UploaderErrorComponent,
        UploaderQueueComponent,
        UploaderQueueInvalidFileComponent,
        UploaderQueueInvalidFileErrorComponent,
        UploaderQueueValidFileComponent,
        UploaderRequirementsComponent,
        UploaderImageComponent,
        UploaderVideoComponent,
        UploaderDocumentComponent,
        UploaderImagesVideosComponent,
        UploaderResourceActionsDirective,
        UploaderStatusComponent,
        UploaderInputComponent,
        UploaderDropareaComponent,
        UploaderGalleryComponent,
        UploaderFilesDirective,
        UploaderDialogComponent,
    ],
    imports: [GlobalModule, StringModule, DatetimeModule, ResourcesModule],
    exports: [
        UploaderComponent,
        UploaderTypeComponent,
        UploaderResourceComponent,
        UploaderErrorComponent,
        UploaderQueueComponent,
        UploaderQueueInvalidFileComponent,
        UploaderQueueInvalidFileErrorComponent,
        UploaderQueueValidFileComponent,
        UploaderRequirementsComponent,
        UploaderImageComponent,
        UploaderVideoComponent,
        UploaderDocumentComponent,
        UploaderImagesVideosComponent,
        UploaderResourceActionsDirective,
        UploaderStatusComponent,
        UploaderInputComponent,
        UploaderDropareaComponent,
        UploaderGalleryComponent,
        UploaderFilesDirective,
        UploaderDialogComponent,
    ]
})
export class NewUploaderModule { }
