import { Injectable } from '@angular/core';
import { DataTransformAbstractUp } from 'app/modules/global/classes/data.transform.abstract.up';
import { CustomerInterface } from 'app/models/customer.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerDataTransformUp extends DataTransformAbstractUp {

  transform(customer: CustomerInterface): CustomerInterface {
    return customer;
  }

}
