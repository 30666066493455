import { Component, Input, OnInit } from '@angular/core';
import { DatasetInterface } from 'app/models/dataset.model';
import { WidgetBehaviorLabelMap } from 'app/modules/template/components/widget-area/widget-area.interface';
import { I18nService } from 'app/services/i18n.service';

@Component({
  selector: 'app-dcontent-metadata',
  templateUrl: './dcontent-metadata.component.html',
  styleUrls: ['./dcontent-metadata.component.scss']
})
export class DContentMetadataComponent implements OnInit {

  types = this._i18nService.translateMap(WidgetBehaviorLabelMap);

  @Input() dcontent: DatasetInterface;

  constructor(private _i18nService: I18nService) { }

  ngOnInit(): void {
    console.log('types are', this.types);
  }
}
