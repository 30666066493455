import { Component, OnInit, Inject, ViewEncapsulation, TemplateRef, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HintItemInterface } from '../../hint.interface';

/**
 *
 *
 * @export
 * @class HintDialogComponent
 * @description Hint modal
 * @implements {OnInit}
 */
@Component({
  selector: 'app-hint-dialog',
  templateUrl: './hint-dialog.component.html',
  styleUrls: ['./hint-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintDialogComponent implements OnInit {
  template: TemplateRef<any>;
  templateParams: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: HintItemInterface) {}

  ngOnInit(): void {
    console.log('daata', this.data);

    if (this.data && this.data.template) {
      if (Array.isArray(this.data.template)) {
        this.template = this.data.template[0];
        this.templateParams = this.data.template[1];
      } else {
        this.template = this.data.template;
      }
    }
  }
}
