import { Component, Input, ViewEncapsulation, Output, EventEmitter, HostBinding } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-input-clear',
  templateUrl: './input-clear.component.html',
  styleUrls: ['./input-clear.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputClearComponent {

  @Input() ngControl: NgControl;
  @Output() cleared: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  enabled: boolean = true;

  get control() {
    return this.ngControl.control;
  }
  /**
   *
   *
   * @param {MouseEvent} $event
   * @memberof DatepickerClearComponent
   */
  clear($event: MouseEvent) {
    $event.stopPropagation();
    this.control.setValue(null);
    this.control.markAsTouched();
    this.cleared.emit($event);
  }

  enable() {
    this.enabled = true;
    // console.log('enabled', this.enabled);
  }

  disable() {
    this.enabled = false;
    // console.log('enabled', this.enabled);
  }
}
