import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FuseSearchBarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { ToolbarComponent } from './toolbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { UtilsModule } from 'app/modules/common/utils/ultils.module';
import { IconModule } from 'app/modules/global/components/icon/icon.module';
import { NotificationButtonModule } from 'app/modules/notification/components/notification-button/notification-button.module';

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatTooltipModule,
    FuseSharedModule,
    FuseSearchBarModule,
    TranslateModule,
    UtilsModule,
    IconModule,
    NotificationButtonModule,
  ],
  exports: [ToolbarComponent],
})
export class ToolbarModule { }
