<div fxLayoutAlign="flex-start" fxLayoutAlign.gt-xs="flex-start center">
  <!-- DATES -->
  <div fxFlex="100" fxLayout="column" [fxLayout.gt-xs]="hasTime ? 'column' : 'row wrap'"
    [fxLayoutGap.gt-xs]="hasTime ? '' : '8px'">
    <!-- startAt -->
    <div class="mr-12" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center" fxFlex="calc(50% - 8px)">
      <app-datepicker [formControl]="group.get(fromField)" [placeholder]="fromLabel"
        [required]="required || !group.get(toField).value" [disableControl]="disabled" [max]="group.get(toField).value"
        (dateChange)="fromChanged($event)"></app-datepicker>
      <div *ngIf="hasTime && group.get('startTimeAt')">
        <app-timepicker [nullable]="true" [required]="false" [placeholder]="'Orario inizio' | translate"
          [formControl]="group.get('startTimeAt')" [date]="group.get(fromField).value"
          [max]="group.get('endTimeAt')"></app-timepicker>
      </div>
    </div>
    <!-- endAt -->
    <div class="mr-12" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center" fxFlex="calc(50% - 8px)">
      <app-datepicker [formControl]="group.get(toField)" [placeholder]="toLabel"
        [required]="required || !group.get(fromField).value" [disableControl]="disabled"
        [min]="group.get(fromField).value" (dateChange)="toChanged($event)"></app-datepicker>
      <div *ngIf="hasTime && group.get('endTimeAt')">
        <app-timepicker [nullable]="true" [required]="false" [placeholder]="'Orario fine' | translate"
          [formControl]="group.get('endTimeAt')" [date]="group.get(toField).value"
          [min]="group.get('startTimeAt')"></app-timepicker>
      </div>
    </div>
  </div>
</div>