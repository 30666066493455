import { Component, Input, OnChanges } from '@angular/core';

/**
 *
 *
 * @export
 * @class ResourceNestedContentComponent
 * @description Show resource nested content preview
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-resource-nested-content',
  templateUrl: './resource-nested-content.component.html',
  styleUrls: ['./resource-nested-content.component.scss'],
})
export class ResourceNestedContentComponent implements OnChanges {
  @Input() nested: any;

  covers: any[] = [];
  fake: any[] = [];

  constructor() {}

  ngOnChanges() {
    if (!this.nested) {
      return;
    }
    this.covers = this.nested.resources.slice(0, 3);
    if (this.covers.length < 3) {
      this.fake = new Array(3 - this.covers.length);
    }
  }
}
