import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeedElementDataTransformUp } from './data-transforms/feed.element.data.transform.up';
import { FeedElementDataTransformDown } from './data-transforms/feed.element.data.transform.down';
import { FeedElementInterface } from '../pages/feed.item.element/feed.item.element.interface';

@Injectable({
  providedIn: 'root',
})
export class FeedElementHttpInterceptor implements HttpInterceptor {
  private _rexpCreate = new RegExp(/feeditems$/);
  private _rexpEdit = new RegExp(/feeditems\/([0-9a-z]{24})$/);
  /**
   *Creates an instance of FeedElementHttpInterceptor.
   * @param {FeedElementDataTransformUp} _feedElementDataTransformUp
   * @param {FeedElementDataTransformDown} _feedElementDataTransformDown
   * @memberof FeedElementHttpInterceptor
   */
  constructor(
    private _feedElementDataTransformUp: FeedElementDataTransformUp,
    private _feedElementDataTransformDown: FeedElementDataTransformDown,
  ) {}
  /**
   *
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof FeedElementHttpInterceptor
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ((!this._rexpCreate.test(req.url) && !this._rexpEdit.test(req.url)) || req.method === 'DELETE') {
      return next.handle(req);
    }

    if (req.method === 'POST' || req.method === 'PUT') {
      const bodyCopy = JSON.parse(JSON.stringify(req.body));
      req = req.clone({
        body: this._feedElementDataTransformDown.transform(<FeedElementInterface>bodyCopy),
      });
    }
    // console.log('intercept', req);
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        // console.log('response?');
        if (event instanceof HttpResponse && event.status !== 204) {
          return event.clone({
            body: this._feedElementDataTransformUp.transform(<FeedElementInterface>event.body),
          });
        }
        return event;
      }),
    );
  }
}
