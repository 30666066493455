import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DistributionInterface } from 'app/models/distribution.model';
import { DistributionDataTransformDown } from './data-transforms/distribution.data.transform.down';
import { DistributionDataTransformUp } from './data-transforms/distribution.data.transform.up';

// TODO: rework other Interceptors, this is PERFECT!

@Injectable({
  providedIn: 'root',
})
export class DistributionHttpInterceptor implements HttpInterceptor {
  private _rexp = new RegExp(/distributions(\/[0-9a-z]{24})?$/);
  /**
   *Creates an instance of DistributionHttpInterceptor.
   * @param {DistributionDataTransformUp} _distributionDataTransformUp
   * @param {DistributionDataTransformDown} _distributionDataTransformDown
   * @memberof DistributionHttpInterceptor
   */
  constructor(
    private _distributionDataTransformUp: DistributionDataTransformUp,
    private _distributionDataTransformDown: DistributionDataTransformDown,
  ) {}
  /**
   *
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof DistributionHttpInterceptor
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('REQUEST', req.method, req.body);
    if (!this._rexp.test(req.url) || req.method === 'DELETE') {
      //TODO: check DELETE why?
      return next.handle(req);
    }
    if (req.method === 'POST' || req.method === 'PUT') {
      // const bodyCopy = Object.assign({}, req.body);
      const bodyCopy = JSON.parse(JSON.stringify(req.body));
      req = req.clone({
        body: this._distributionDataTransformDown.transform(<DistributionInterface>bodyCopy),
      });
    }
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status !== 204) {
          return event.clone({
            body: this._distributionDataTransformUp.transform(<DistributionInterface>event.body),
          });
        }
        return event;
      }),
    );
  }
}
