import { PipeTransform, Pipe } from '@angular/core';
import { FluidDurationService, FluidSecondsToDurationOptionsInterface } from '../services/fluid-duration.service';

@Pipe({
  name: 'fluidSecondsToDuration',
})
export class FluidSecondsToDurationPipe implements PipeTransform {
  constructor(
    private _fluidDurationService: FluidDurationService,
  ) {}

  // seconds
  transform(value: number, opts?: FluidSecondsToDurationOptionsInterface): string {
    return this._fluidDurationService.secondsToHuman(value, opts);
  }
}
