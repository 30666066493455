import { Injectable } from '@angular/core';
import { ICONS_MAP } from './icons.map';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  icons = ICONS_MAP;

  getType(name: string) {
    if (this.icons) {
      return typeof this.icons[name] === 'object'
        ? ICON_TYPE.svg
        : typeof this.icons[name] === 'string'
        ? ICON_TYPE.icon
        : ICON_TYPE.unset;
    }
    return;
  }

  getIcon(name: string) {
    const type = this.getType(name);
    if (type && type === ICON_TYPE.icon) {
      return this.icons[name];
    }
    return name;
  }

  getSvgIcon(name: string) {
    const type = this.getType(name);
    if (type && type === ICON_TYPE.svg) {
      return this.icons[name].name;
    }
    return '';
  }
}

export const ICON_TYPE = {
  unset: 0,
  svg: 1,
  icon: 2,
};
