import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Resource } from 'app/models/resource.model';
import { I18nService } from 'app/services/i18n.service';
import { HintTarasComponent } from './components/hint-taras/hint-taras.component';
import { HintDataInterface } from './hint.interface';

export const HINT_DATA: (config: { I18nService: I18nService }) => HintDataInterface = (config) => {
  return {
    system: {
      text: config.I18nService.translate(_('Puoi configurare il tuo impianto.')),
    },
    feeds: {
      component: HintTarasComponent,
    },
    pax: {
      text: config.I18nService.translate(_('Messaggio + Componente nel modale')),
      component: [HintTarasComponent, { name: 'Taras' }],
      video: <Resource>{
        thumb: 'https://placeimg.com/640/480/any',
        publicAvailableSource: 'http://techslides.com/demos/sample-videos/small.mp4',
      },
    },
  };
};
