import { Component, Input } from '@angular/core';
import { FluidSecondsToDurationOptionsInterface, FluidSecondsToDurationOptions } from 'app/modules/global/services/fluid-duration.service';

/**
 *
 *
 * @export
 * @class DurationComponent
 * @description Format seconds in hours, minutes and seconds
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss'],
})
export class DurationComponent {
  @Input() time: number; // seconds
  @Input() options: FluidSecondsToDurationOptionsInterface = new FluidSecondsToDurationOptions();
}
