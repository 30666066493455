<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ 'Imposta periodo' | translate }}</span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-24 m-0" mat-dialog-content>
    <form name="dateslot" novalidate>
      <app-dateslot [group]="group" [min]="min" [max]="max" [required]="required" [hasTime]="hasTime"></app-dateslot>
    </form>
  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="matDialogRef.close()">{{ 'Annulla' | translate }}</button>
    <button color="primary" type="button" mat-raised-button (click)="save()">{{ 'Conferma' | translate }}</button>
  </div>
</div>