import { Component, Input, OnInit } from '@angular/core';

/**
 *
 *
 * @export
 * @class HintTarasComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-hint-taras',
  templateUrl: './hint-taras.component.html',
})
export class HintTarasComponent implements OnInit {
  @Input() name: string = 'Taras';

  ngOnInit() {}
}
