import { Component, Input } from '@angular/core';
import { TableCellComponent } from '../../../classes/table-cell-component.class';

@Component({
  selector: 'app-cell-icon',
  templateUrl: './cell-icon.component.html'
})

export class CellIconComponent extends TableCellComponent {
  @Input() row: any;
  @Input() value: any;
  @Input() options: { icon?: string, classes?: string[], tooltip?: string, iconRepeat?: 1, badge?: string};
}
