import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserInterface } from 'app/models/user.model';
import { UserDataTransformDown } from './data-transforms/user.data.transform.down';
import { UserDataTransformUp } from './data-transforms/user.data.transform.up';

@Injectable({
  providedIn: 'root',
})
export class UserHttpInterceptor implements HttpInterceptor {
  private _rexpCreate = new RegExp(/users$/);
  private _rexpEdit = new RegExp(/users\/([0-9a-z]{24})$/);

  constructor(
    private _userDataTransformDown: UserDataTransformDown,
    private _userDataTransformUp: UserDataTransformUp,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this._rexpCreate.test(req.url) && !this._rexpEdit.test(req.url)) {
      //TODO: fix this, one Regex is enough
      return next.handle(req);
    }

    if (req.method === 'POST' || req.method === 'PUT') {
      const bodyCopy = JSON.parse(JSON.stringify(req.body));
      req = req.clone({
        body: this._userDataTransformDown.transform(<UserInterface>bodyCopy),
      });
    }
    // console.log('intercept', req);
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status !== 204) {
          return event.clone({
            body: this._userDataTransformUp.transform(<UserInterface>event.body),
          });
        }
        return event;
      }),
    );
  }
}
