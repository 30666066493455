import { Injectable } from '@angular/core';
import { I18nService } from './i18n.service';
import { environment } from 'environments/environment';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UserInterface } from 'app/models/user.model';
import { LS_FEEDBACK_DIALOG } from 'app/modules/global/localstorage';
import * as Sentry from "@sentry/angular-ivy";



@Injectable({
  providedIn: 'root',
})
export class SentryService {
  constructor(private _i18nService: I18nService) { }

  // showDialog: boolean = !!JSON.parse(window.localStorage.getItem(LS_FEEDBACK_DIALOG));
  showDialog: boolean = environment.sentry_form;

  ignoreErrors: string[] | RegExp[] = ['ExpressionChangedAfterItHasBeenCheckedError'];

  private _dialogShownTimeout;

  init() {
    // SENTRY TRACKING
    Sentry.init({
      environment: environment.production ? 'prod' : 'dev',
      dsn: environment.sentry_dsn,
      release: environment.changelog,
      integrations: [
        new Sentry.BrowserTracing({
          tracingOrigins: [
            'localhost',
            environment.baseurl,
            'https://dev-programmatic.fluidnext.com/',
            'https://new-programmatic.fluidnext.com/',
            'https://newrc-programmatic.fluidnext.com/',
          ],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      maxBreadcrumbs: 5,
      // whitelistUrls: [],
      ignoreErrors: this.ignoreErrors,
      beforeSend: (event, hint) => {
        console.log('Before send', event, hint);

        // avoid multiple dialogs
        if (this.showDialog && event.exception) {
          if (this._dialogShownTimeout) {
            clearTimeout(this._dialogShownTimeout);
          }
          this._dialogShownTimeout = setTimeout(() => {
            this.showSentryDialog(event);
          }, 250);
        }

        return event;
      },
    });
  }

  showSentryDialog(event) {
    const user = <UserInterface>JSON.parse(localStorage.getItem('currentUser'));
    let email, name;

    if (user) {
      email = user.email;
      name = user.fullname || user.username;
    }

    return Sentry.showReportDialog({
      eventId: event.event_id,
      title: this._i18nService.translate(_('Ops! Qualcosa è andato storto.')),
      subtitle: this._i18nService.translate(
        _('Puoi inviarci un feedback descrivendo il comportamento che ha causato il problema.'),
      ),
      subtitle2: '',
      labelName: this._i18nService.translate(_('Nome e cognome')),
      labelEmail: this._i18nService.translate(_('Indirizzo email')),
      user: {
        email: email,
        name: name,
      },
      // lang: 'lang',
      labelComments: this._i18nService.translate(_('Cosa è successo?')),
      labelClose: this._i18nService.translate(_('Chiudi')),
      labelSubmit: this._i18nService.translate(_('Invia')),
      errorGeneric: this._i18nService.translate(_('Si è verificato un errore')),
      errorFormEntry: this._i18nService.translate(_('Compila tutti i campi richiesti.')),
      successMessage: this._i18nService.translate(_('Segnalazione inviata con successo. Grazie!')),
    });
  }

  toggleDialog() {
    this.showDialog = !this.showDialog;
    window.localStorage.setItem(LS_FEEDBACK_DIALOG, this.showDialog ? '1' : '0');
  }
}
