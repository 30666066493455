<mat-list>
  <mat-list-item>
    <span>{{ 'Risoluzione' | translate }}</span> <span> {{ dcontent.template.width }}x{{ dcontent.template.height }}
    </span>
  </mat-list-item>
  <mat-list-item>
    <span>{{ 'Durata' | translate }}</span>
    <span>
      {{ dcontent.duration | fluidSecondsToDuration }}
    </span>
  </mat-list-item>
  <mat-list-item>
    <span>{{ 'Widgets' | translate }}</span>
    <span>
      {{ dcontent.template.widgets.length }}
    </span>
  </mat-list-item>
  <mat-list-item *ngIf="dcontent.template.widgets.length > 0">
    <small
      *ngFor="let widget of dcontent.template.widgets; let last = last;">{{ types[widget?.behavior?.slug] }}{{ last ? '' : ', ' }}</small>
  </mat-list-item>

</mat-list>