<ng-container *ngIf="data">
  <!-- HEADING -->
  <div *ngIf="data.title" mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
      <mat-icon class="mr-8 accent-500-fg">support_agent</mat-icon>
      <div class="ellipsis accent-500-fg">
        {{ data.title }}
      </div>
    </div>
  </div>
  <div mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="12px">
      <!-- TEMPLATE -->
      <div *ngIf="data.template" class="hint-template">
        <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="templateParams"></ng-container>
      </div>
      <!-- COMPONENT -->
      <div *ngIf="data.component" class="hint-component">
        <app-hint-component-injector [component]="data.component"></app-hint-component-injector>
      </div>
      <!-- TEXT -->
      <div *ngIf="!data.component && !data.template && data.text" class="hint-tooltip-text">
        {{ data.text }}
      </div>
      <!-- VIDEO -->
      <div *ngIf="data.video" class="hint-video">
        <video
          [src]="data.video.publicAvailableSource"
          [poster]="data.video.thumb"
          [loop]="false"
          controls
          controlslist
          playsinline
          autoplay
          muted
        ></video>
      </div>
      <!-- FOOTER -->
      <div *ngIf="data.footer">
        {{ data.footer }}
      </div>
    </div>
  </div>
  <!-- ACTIONS -->
  <div mat-dialog-actions>
    <div fxFlex="100" fxLayout="row" fxLayoutAlign.gt-xs="flex-end center">
      <button class="accent-500" mat-button [mat-dialog-close]="true">{{ 'Chiudi' | translate }}</button>
    </div>
  </div>
</ng-container>
