import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Resource, RESOURCE_TYPE } from 'app/models/resource.model';
import { RESOURCE_DURATION_DEFAULT } from 'app/modules/global/constants';
import { DatasetInterface } from 'app/models/dataset.model';

@Component({
  selector: 'app-resource-content',
  templateUrl: './resource-content.component.html',
  styleUrls: ['./resource-content.component.scss'],
})
export class ResourceContentComponent implements OnChanges {
  @Input() content: Resource | DatasetInterface;
  @Input() nested: any;
  @Input() duration: number;
  @Input() index: number;
  @Input() name: boolean;
  @Input() thumb: boolean;
  @Input() icon: boolean;
  @Input() details: boolean;
  @Input() modalPreview: boolean;
  @Input() wide: boolean;
  @Input() compact: boolean;
  @Input() editDuration: boolean;
  @Input() dcontentEditBtn: boolean = true;
  @Input() showInlineTimerForVideos: boolean;
  @Input() showBitrateForVideos: boolean;
  @Output() editDurationClick: EventEmitter<any> = new EventEmitter();

  title: string;
  width: number;
  height: number;
  src: string;
  time: any;
  hasIndex: boolean;

  resourceDurationDefault = RESOURCE_DURATION_DEFAULT;

  resourceType = RESOURCE_TYPE;

  constructor(private _bottomSheet: MatBottomSheet) { }

  onEditDurationClick(event) {
    if (this.editDuration) {
      this.editDurationClick.emit({ event: event, value: this.content });
      event.stopPropagation();
    }
  }

  get defaultDuration() {
    if (this.isResource) {
      const res = this.resource;
      return res.metadata && res.metadata.duration || RESOURCE_DURATION_DEFAULT;
    }
    if (this.isDContent) {
      const dset = this.dcontent;
      return dset.duration || RESOURCE_DURATION_DEFAULT;
    }
    return RESOURCE_DURATION_DEFAULT;
  }

  ngOnChanges() {
    if (!this.content && !this.nested) {
      return;
    }

    this.hasIndex = false;
    if (this.index !== undefined) {
      this.hasIndex = true;
    }

    const someInfo = this.thumb || this.hasMetadata;

    if (!someInfo || this.details || this.hasNameIcon) {
      this.name = true;
    }

    if (this.name) {
      this.title = this.isNested ? this.nested.name : this.content.name;
    }

    if (this.thumb && !this.isNested) {
      this.src = this.content.thumb;
    }

    // if (this.details) { // CALC ALWAYS
    this.time = this.duration;
    if (this.isResource) {
      const metadata = (<Resource>this.content).metadata;
      if (metadata && !Array.isArray(metadata)) {
        this.width = metadata.width;
        this.height = metadata.height;
        if (!this.time) {
          this.time = metadata.duration || this.resourceDurationDefault;
        }
      }
    }
    if (this.isDContent) {
      const template = (<DatasetInterface>this.content).template;
      if (template) {
        this.width = template.width;
        this.height = template.height;
        if (!this.time) {
          this.time = (<DatasetInterface>this.content).duration || this.resourceDurationDefault;
        }
      }
    }
    // }
  }

  get hasMetadata() {
    return this.name || this.details || this.hasNameIcon;
  }

  get hasNameIcon() {
    return this.icon && !this.thumb;
  }

  get isClickable() {
    // TODO: enable this for DContent Dialog Preview
    return (this.isResource || this.isDContent) && this.modalPreview;
    // return (this.isResource) && this.modalPreview;
  }

  get isLink() {
    return !this.thumb && this.isClickable;
  }

  get isResource() {
    return !this.isNested && !!this.content && !!(<Resource>this.content).fileinfo;
  }

  get isDContent() {
    return !this.isNested && !!this.content && !!((<DatasetInterface>this.content).template || (<DatasetInterface>this.content).smartobjects);
  }

  get isNested() {
    return !!this.nested; // && this.content.resources.length;
  }

  get resource(): Resource {
    return this.isResource ? <Resource>this.content : undefined;
  }

  get isVideo() {
    return this.isResource && (<Resource>this.resource).type === RESOURCE_TYPE.videos
  }

  get dcontent(): DatasetInterface {
    return this.isDContent ? <DatasetInterface>this.content : undefined;
  }
}
