import { Injectable } from '@angular/core';
import { DateslotInterface, MonthIndex, MonthIndexInterface, Weekday, WeekdayInterface } from './datetime.interface';
import { FormGroup, FormControl } from '@angular/forms';
import { DateRangeInterface } from './dateslots/dateslot.interface';
import { DateService, MonthInfoInterface } from 'app/utils/services/date.service';
import { endOfDay, parseISO, startOfDay } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class DatetimeService {
  constructor(
    private _dateService: DateService,
  ) { }

  buildDateslots(items: DateslotInterface[] = []) {
    return items.map((item, index) => {
      return new FormGroup(this.buildDateslot(item));
    });
  }

  buildDateslot(val: DateslotInterface = <DateslotInterface>{}) {
    if (val.startAt) {
      val.startAt = new Date(val.startAt);
    }
    if (val.endAt) {
      val.endAt = new Date(val.endAt);
    }
    return {
      startAt: new FormControl(val.startAt),
      endAt: new FormControl(val.endAt),
    };
  }

  buildWeekdays(items: WeekdayInterface[] = []) {
    return items.map((item, index) => {
      return new FormGroup(this.buildWeekday(item));
    });
  }

  buildWeekday(val?: WeekdayInterface) {
    val = new Weekday(val);
    return {
      weekday: new FormControl(val.weekday),
    };
  }

  buildMonthIndexes(items: MonthIndexInterface[] = []) {
    return items.map((item, index) => {
      return new FormGroup(this.buildMonthIndex(item));
    });
  }

  buildMonthIndex(val?: MonthInfoInterface) {
    val = new MonthIndex(val);
    return {
      index: new FormControl(val.index),
    };
  }

  isRunningFunc(item: DateRangeInterface, minDate = null, maxDate = null) {
    // console.log('isRunning')
    item.startAt = item.startAt ? new Date(item.startAt) : null;
    minDate = minDate ? new Date(minDate) : null;
    if (minDate && (!item.startAt || minDate.getTime() > item.startAt.getTime())) {
      item.startAt = minDate;
    }

    item.endAt = item.endAt ? new Date(item.endAt) : null;
    maxDate = maxDate ? endOfDay(new Date(maxDate)) : null;
    if (maxDate && (!item.endAt || maxDate.getTime() < item.endAt.getTime())) {
      item.endAt = maxDate;
    }

    const atLeastOne = !!item.startAt || !!item.endAt;
    const currentNotOrBiggerThenStart = !item.startAt || this.nowTimestamp >= new Date(item.startAt).getTime();
    const currentNotOrLessThenEnd = !item.endAt || this.nowTimestamp <= new Date(item.endAt).getTime();
    return atLeastOne && currentNotOrBiggerThenStart && currentNotOrLessThenEnd;
  }

  get nowTimestamp() {
    return new Date().getTime(); // .setHours(0, 0, 0, 0);
  }

  isUndefinedFunc(item: DateRangeInterface) {
    return !item.startAt && !item.endAt;
  }

  isExpiredFunc(item: DateRangeInterface, minDate = null, maxDate = null) {
    const endAt = item.endAt ? new Date(item.endAt) : null;
    const isPast = !!endAt && new Date(endAt).getTime() < this.nowTimestamp;
    const max = maxDate ? endOfDay(new Date(maxDate)) : null;
    const isPastMax = max ? this.nowTimestamp > max.getTime() : false;
    const startAt = item.startAt ? new Date(item.startAt) : null;
    const isInvalidMax = max && startAt ? max.getTime() < startAt.getTime() : false;

    const min = minDate ? new Date(minDate) : null;
    const isInvalidMin = min && endAt ? min.getTime() > endAt.getTime() : false;

    return isPast || isPastMax || isInvalidMax || isInvalidMin;
  }

  parseItem(item: DateRangeInterface) {
    const parsedItem: DateRangeInterface = {
      startAt: item.startTimeAt && item.startAt ? this._dateService.copyTime(item.startTimeAt, new Date(item.startAt)) : item.startAt ? startOfDay(new Date(item.startAt)) : item.startAt,
      endAt: item.endTimeAt && item.endAt ? this._dateService.copyTime(item.endTimeAt, new Date(item.endAt)) : item.endAt ? endOfDay(new Date(item.endAt)) : item.endAt,
    };
    return parsedItem;
  }

  validateRange(range: DateRangeInterface[] = [], minDate = null, maxDate = null) {
    const rangeValidation = {
      expired: false,
      running: false,
      upcoming: false,
    };
    range.forEach((item, index) => {

      // console.log('validating range item', item);

      const parsedItem = this.parseItem(item);

      // console.log('parsed item', parsedItem, 'min', minDate, 'max', maxDate);

      const isUndefined = this.isUndefinedFunc(parsedItem);
      const isExpired = this.isExpiredFunc(parsedItem, minDate, maxDate);
      const isRunning = this.isRunningFunc(parsedItem, minDate, maxDate);
      const isUpcoming = !isUndefined && !isRunning && !isExpired;

      rangeValidation.running = isRunning || rangeValidation.running;
      rangeValidation.upcoming = !rangeValidation.running && (isUpcoming || rangeValidation.upcoming);
      rangeValidation.expired =
        !rangeValidation.running && !rangeValidation.upcoming && (isExpired || rangeValidation.expired);

      rangeValidation[index] = {
        isUndefined: isUndefined,
        isRunning: isRunning,
        isExpired: isExpired,
        isUpcoming: isUpcoming,
      };

      // console.log('rangeValidation[index]', rangeValidation[index]);
    });
    return rangeValidation;
  }
}
