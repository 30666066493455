import { PipeTransform, Pipe } from '@angular/core';
import { DatePipe } from '@angular/common';
import { I18nService } from 'app/services/i18n.service';

@Pipe({
  name: 'fluidDate',
  pure: false,
})
export class FluidDatePipe implements PipeTransform {
  constructor(private _i18nService: I18nService, private datePipe: DatePipe) {}

  // https://angular.io/api/common/DatePipe
  transform(value: any, pattern: string = 'shortDate'): any {
    const timezone = undefined;
    return this.datePipe.transform(value, pattern, timezone, this._i18nService.currentLanguageCode);
  }
}
