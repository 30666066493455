import { EventEmitter, Injectable } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthenticationService } from 'app/core/auth/services/authentication.service';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { NotificationCountInterface, NotificationInterface } from 'app/models/notification.model';
import { PaginatedResponse } from 'app/models/pagination.model';
import { NotificationRepositoryService } from 'app/repositories/notification-repository.service';
import { SystemRepositoryService } from 'app/repositories/system-repository.service';
import { I18nService } from 'app/services/i18n.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { NOTIFICATIONS_FETCH_FREQUENCY_IN_SECONDS, NOTIFICATIONS_WIDGET_MAX } from '../global/constants';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {

  notificationsResponse: PaginatedResponse<NotificationInterface>;
  unreadCount: number;
  lastFetchDate: Date = new Date();
  lastUnreadCount: NotificationCountInterface;

  private _notificationsCheckInterval = null;
  private _notificationsCheckFrequency = NOTIFICATIONS_FETCH_FREQUENCY_IN_SECONDS; // seconds

  private _triggerChange: EventEmitter<any> = new EventEmitter<any>();
  notificationsChanged: EventEmitter<NotificationInterface[]> = new EventEmitter<NotificationInterface[]>();

  constructor(
    private _toastrService: ToastrService,
    private _i18nService: I18nService,
    private _systemRepositoryService: SystemRepositoryService,
    private _authenticationService: AuthenticationService,
    private _currentUser: CurrentUserService,
    private _notificationRepositoryService: NotificationRepositoryService,
  ) {
    // IF IS VALID USER
    if (environment.notificationsCheck) {
      if (this._authenticationService.hasToken() && this._currentUser.hasUser() && this._currentUser.isEnabled()) {
        clearInterval(this._notificationsCheckInterval);
        this._notificationsCheckInterval = setInterval(() => {
          this.updatetUnreadCount();
        }, this._notificationsCheckFrequency * 1000);
        this.updatetUnreadCount();
      }
    }

    this._triggerChange.subscribe(values => {
      this.updateNotifications();
    });
  }

  private _isSameObject(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  updatetUnreadCount() {
    console.log('COUNT PLS');
    return this._notificationRepositoryService
      .getUnreadCount()
      .pipe(first())
      .subscribe((response: NotificationCountInterface) => {
        this.lastFetchDate = new Date();
        this.unreadCount = response.tot;
        // const lastUpdate = new Date(response.last.created_at);
        const newUnreadCount = response;
        if (!this._isSameObject(newUnreadCount, this.lastUnreadCount)) {
          this._triggerChange.emit(this.notifications);
          this.lastUnreadCount = newUnreadCount;
        }
      });
  }

  get notifications(): NotificationInterface[] {
    return this.notificationsResponse ? this.notificationsResponse.items : [];
  }

  updateNotifications() {
    return this._notificationRepositoryService
      .findAll({
        count: NOTIFICATIONS_WIDGET_MAX,
      })
      .pipe(first())
      .subscribe((response: PaginatedResponse<NotificationInterface>) => {
        this.notificationsResponse = response;
        this.notificationsChanged.emit(this.notifications);
      });
  }

  readNotification(notification: NotificationInterface) {
    const obs = this._notificationRepositoryService.setRead([notification.id]).pipe(first());
    obs.subscribe(v => {
      // notification.read = true;
      // this.notificationsChanged.emit([notification]);
      this.updatetUnreadCount();
    });
    return obs;
  }

  readAll() {
    const obs = this._notificationRepositoryService.setReadAll().pipe(first());
    obs.subscribe(v => {
      // notifications.forEach(notification => {
      //   notification.read = true;
      // });
      // this.notificationsChanged.emit(notifications);
      this.updatetUnreadCount();
    });
    return obs;
  }

  readNotifications(notifications: NotificationInterface[]) {
    const obs = this._notificationRepositoryService.setRead(notifications.map(v => v.id)).pipe(first());
    obs.subscribe(v => {
      // notifications.forEach(notification => {
      //   notification.read = true;
      // });
      // this.notificationsChanged.emit(notifications);
      this.updatetUnreadCount();
    });
    return obs;
  }

  unreadNotifications(notifications: NotificationInterface[]) {
    const obs = this._notificationRepositoryService.setUnread(notifications.map(v => v.id)).pipe(first());
    obs.subscribe(v => {
      notifications.forEach(notification => {
        notification.read = false;
      });
      // this.notificationsChanged.emit(notifications);
      this.updatetUnreadCount();
    });
    return obs;
  }

}
