import { NgModule } from '@angular/core';
import { NgMaterialModule } from 'app/ng-material/ng-material.module';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { HintContentComponent } from './hint-content.component';

@NgModule({
    declarations: [HintContentComponent],
    imports: [NgMaterialModule, FuseSharedModule, CommonModule]
})
export class HintContentComponentModule {}
