import { Subject, Observable } from 'rxjs';
import { Injectable, isDevMode, Inject, InjectionToken } from '@angular/core';
import Pusher from 'pusher-js';

export const PUSHER_CONFIG = new InjectionToken<PusherConfig>('PUSHER_CONFIG');

export interface PusherConfig {
  id: string;
  channel: string;
  forceTLS: boolean;
  cluster: string;
}

/**
 * Add Pusher subscription
 */
@Injectable({
  providedIn: 'root',
})
export class PusherBridgeService {
  pusher: Pusher;
  subject: Subject<any> = new Subject();
  constructor(@Inject(PUSHER_CONFIG) private config: PusherConfig) {
    this.pusher = new Pusher(config.id, {
      cluster: config.cluster,
      forceTLS: config.forceTLS,
      enabledTransports: ['ws'],
    });
    // console.log('instance pusher: ', this.pusher);
    this.pusher.subscribe(config.channel).bind('message', (data) => {
      this.subject.next(data);
      // console.log('pushed emit', data);
    });
  }

  getObservable(): Observable<any> {
    return this.subject.asObservable();
  }
}
