import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { Language, FLUID_LANGUAGES } from './i18n.languages';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  static languages: Language[] = FLUID_LANGUAGES;

  reloadPageOnChange: boolean = true;
  fallbackToDefaultLanguage: boolean = false;

  languageChange$: Subject<string> = new Subject<string>();
  currentLanguage: Language;

  static getNativeLanguage(): string {
    const nativeLanguage = I18nService.languages.find((lang) => lang.native);
    return nativeLanguage ? nativeLanguage.code : !!I18nService.languages.length && I18nService.languages[0].code;
  }

  static getMainLanguage(): string {
    const defaultLanguage = I18nService.languages.find((lang) => lang.default);
    return defaultLanguage ? defaultLanguage.code : !!I18nService.languages.length && I18nService.languages[0].code;
  }

  static setLocaleLanguage(code: string) {
    window.localStorage.setItem('locale', code);
  }

  static getLocaleLanguage(): string {
    const lsLang = window.localStorage.getItem('locale');
    return lsLang && lsLang.substr(0, 2);
  }

  static getBrowserLanguage(): string {
    const browserLang = window.navigator.language;
    return browserLang && browserLang.substr(0, 2);
  }

  static getDefaultLanguage(): string {
    const lsLang = this.getLocaleLanguage();
    const browserLang = this.getBrowserLanguage();
    const mainLang = this.getMainLanguage();
    return (
      (this.isSet(lsLang) && lsLang) || (this.isSet(browserLang) && browserLang) || (this.isSet(mainLang) && mainLang)
    );
  }

  static isSet(code) {
    return code && Array.isArray(I18nService.languages) && !!I18nService.languages.find((lang) => lang.code === code);
  }

  constructor(private _translateService: TranslateService) { }

  init() {
    // console.log('this._translateService', this._translateService);

    if (Array.isArray(I18nService.languages) && I18nService.languages.length) {
      // LOAD DEFAULT LANGUAGE FOR FALLBACKS
      if (environment.languages && this.fallbackToDefaultLanguage) {
        this._translateService.setDefaultLang(I18nService.getMainLanguage());
      }

      // SET CURRENT LANG
      if (environment.languages) {
        this._translateService.use(I18nService.getDefaultLanguage());
      } else {
        this._translateService.use(I18nService.getNativeLanguage());
      }

      // CURRENT LANG OBJECT
      this.currentLanguage = this.getCurrentLanguage();

      // ADD ALL LANGUAGES (will download all .json files at beginning)
      //   this._translateService.addLangs(I18nService.languages.map((lang) => lang.code));

      // LOAD ONLY LOCALE USED LANGUAGE
      this._translateService.addLangs([this.currentLanguageCode]);

      // TRIGGER TRANSLATIONS EVERYWHERE
      // setTimeout(() => {
      //   // this._translateService.setDefaultLang('en');
      //   // this._translateService.setDefaultLang('it');
      //   this._translateService.onDefaultLangChange.next();
      // });
    }
  }

  getTranslate(text: string, params?: any): Observable<string> {
    return this._translateService.get(text, params);
  }

  translate(text: string, params?: any): string {
    return this._translateService.instant(text, params);
  }

  get currentLanguageCode() {
    return this._translateService.currentLang;
  }

  getCurrentLanguage() {
    return (
      Array.isArray(I18nService.languages) &&
      I18nService.languages.find((lang) => lang.code === this.currentLanguageCode)
    );
  }

  getTranslatedComponent(name) {
    const currLang = this.getCurrentLanguage();
    return currLang.components && currLang.components[name];
  }

  setLanguage(code: string) {
    // IF LANG EXISTS
    if (I18nService.isSet(code)) {
      // SET NEW LANG TO LOCALSTORAGE
      I18nService.setLocaleLanguage(code);

      // WORKS ONLY FOR TEMPLATE TRANSLATIONS
      if (!this.reloadPageOnChange) {
        this._translateService.currentLang = '';
        this._translateService.use(code);
        this.languageChange$.next(code);
        this.currentLanguage = this.getCurrentLanguage();
      } else {
        window.location.reload();
      }
    }
  }

  translateMap(obj: any) {
    const transObj = JSON.parse(JSON.stringify(obj));
    for (const k in transObj) {
      if (transObj.hasOwnProperty(k) && typeof transObj[k] === 'string') {
        transObj[k] = this.translate(transObj[k]);
      }
    }
    return transObj;
  }

  translateNestedObject(obj: any, keys: string[] = ['name']) {
    const transObj = JSON.parse(JSON.stringify(obj));
    for (const k in transObj) {
      if (transObj.hasOwnProperty(k)) {
        if (typeof transObj[k] === 'string' && keys.indexOf(k) !== -1) {
          transObj[k] = this.translate(transObj[k]);
        }

        if (typeof transObj[k] === 'object') {
          transObj[k] = this.translateNestedObject(transObj[k], keys);
        }
      }
    }
    return transObj;
  }

  translateArray(arr: any[], keys: string[] = ['name']): any[] {
    const transArr = JSON.parse(JSON.stringify(arr));
    return transArr.map((v) => {
      for (const k in v) {
        if (v.hasOwnProperty(k)) {
          if (typeof v[k] === 'string' && keys.indexOf(k) !== -1) {
            // console.log('processo stringa', v[k]);
            v[k] = this.translate(v[k]);
          }

          if (Array.isArray(v[k])) {
            // console.log('processo array', v[k]);
            v[k] = this.translateNestedObject(v[k], keys);
          }
        }
      }
      return v;
    });
  }
}

// export class CustomTranslatePipe extends TranslatePipe implements PipeTransform {
//   transform(key: any, args: any[]): string {
//     const result = super.transform(key, args);
//     return result !== '' && result !== key ? result : '[?] ' + key;
//   }
// }

/**
 * ------------------------------------------------------------------
 * ngxTranslate Fix Start
 * ------------------------------------------------------------------
 * If you are using a language other than the default one, i.e. English in this case,
 * you may encounter an issue where some of the components are not actually being
 * translated when your app first initialized.
 *
 * This is related to ngxTranslate module and below there is a temporary fix while we
 * are moving the multi language implementation over to the Angular's core language
 * service.
 **/

// Set the default language to 'en' and then back to 'tr'.
// '.use' cannot be used here as ngxTranslate won't switch to a language that's already
// been selected and there is no way to force it, so we overcome the issue by switching
// the default language back and forth.
/**
 setTimeout(() => {
    this._translateService.setDefaultLang('en');
    this._translateService.setDefaultLang('tr');
    });
    */

/**
 * ------------------------------------------------------------------
 * ngxTranslate Fix End
 * ------------------------------------------------------------------
 */

//  // // Set the navigation translations
// // this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationItalian);
// // Add languages
// this._translateService.addLangs(['it', 'en']);
// // Set the default language
// this._translateService.setDefaultLang('it');
// // Use a language, grab it from localStorage
// this._translateService.use(localStorage.getItem('locale') || this._translateService.getDefaultLang());

// // ngxTranslate fix
// // setTimeout(() => {
// //   this._translateService.setDefaultLang('en');
// //   this._translateService.setDefaultLang('it');
// // });

// // this._translateService.onLangChange.pipe(takeUntil(this._unsubscribeAll)).subscribe((event: LangChangeEvent) => {
// //   localStorage.setItem('locale', event.lang);
// //   console.log('CHANGE LANG', event.lang);
// // });

// console.log('Current Locale:', this._translateService.currentLang);
// // console.log('trans service', this._translateService);
// // this._translateService.use(this._translateService.currentLang);
