import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanLoad,
  Route,
  UrlSegment,
} from '@angular/router';
import { CurrentUserService } from './current-user.service';
import { AuthenticationService } from './authentication.service';
import { NavigationService } from 'app/modules/global/services/navigation.service';

/**
 * The authentication guard redirect the user to the auth page if not logged
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  /** Constructor */
  constructor(
    private _router: Router,
    private _currentUserService: CurrentUserService,
    private _authService: AuthenticationService,
    private _navigationService: NavigationService,
  ) { }

  /**
   * @param route The route
   * @param state The route snapshot
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isValidUser()) {
      if (this.isCorrectRole(route)) {
        return true;
      }
      // dashboard redirect
      this._router.navigate(['dashboard']);
      return false;
    }
    this._router.navigate(['login'], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }

  isCorrectRole(route: ActivatedRouteSnapshot) {
    // console.log('=== isCorrectRole: route.data.roles', route.data.roles);
    if (!route.data.roles || this._currentUserService.hasRoles(route.data.roles)) {
      return true;
    }
    return false;
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    if (this.isValidUser()) {
      return true;
    }
    // BUILD REAL RETURN URL
    const returnUrl = this._navigationService.buildUrlFromSegments(segments)
    this._router.navigate(['login'], {
      queryParams: { returnUrl },
    });
    return false;
  }

  /**
   * Return true if the user is logged
   */
  isValidUser(): boolean {
    return this._authService.hasToken() && this._currentUserService.hasUser() && this._currentUserService.isEnabled();
  }
}
