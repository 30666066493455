import { NgModule } from '@angular/core';
import { DatetimeModule } from 'app/modules/common/datetime/datetime.module';
import { GlobalModule } from 'app/modules/global/global.module';
import { FeedFiltersPreviewComponent } from './feed-filters-preview.component';
@NgModule({
    declarations: [FeedFiltersPreviewComponent],
    imports: [GlobalModule, DatetimeModule],
    exports: [FeedFiltersPreviewComponent]
})
export class FeedFiltersPreviewComponentModule {}
