import { Directive, ViewContainerRef, Output, NgModule, OnChanges, HostListener, AfterViewInit } from '@angular/core';
import { Type } from '@angular/core';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ComponentFactoryResolver,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { TableColumn } from '../../classes/table-column.class';
import { TableCellComponentsModule } from '../cells/table-cell-components.module';
import { TableCellChangeEvent, TableCellClickEvent } from '../../interfaces/table-events.interface';
import { TableCellComponentInterface } from '../../interfaces/table-cell-component.interface';
import { TableCellComponent } from '../../classes/table-cell-component.class';
import { TableHeadCellComponent } from '../../classes/table-head-cell-component.class';
import { TableHeadCellComponentInterface } from '../../interfaces/table-head-cell-component.interface';
import { TableFilterInjectorModule } from './table-component-filter-injector';
import { Table } from '../../classes/table.class';
import { TableFiltersPreviewInjectorModule } from './table-component-filters-preview-injector';
import { UtilsService } from 'app/modules/common/utils/utils.service';

/**
 * @description
 * Directive that injects a specific component template.
 */
@Directive({
  selector: '[component]',
})
export class InjectorComponentDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

/**
 * @description
 * Component that views an basic raw value.
 */
@Component({
  selector: 'app-cell-raw-value',
  template: `{{ value }}`,
})
export class CellRawValueComponent extends TableCellComponent {
  value: any;
}
@NgModule({
  declarations: [CellRawValueComponent]
})
export class TableCellRawValueModule { }

export type TableCellComponentComponentType = Type<TableCellComponent | TableHeadCellComponent>;
export type TableComponentWithOptionsType = [TableCellComponentComponentType, any];
export type TableComponentWithOrWithoutOptionsType = TableCellComponentComponentType | TableComponentWithOptionsType;
export type TableComponentCallbackType = (row: any, index: number) => TableComponentWithOrWithoutOptionsType;
export type TableComponentGenericType = TableComponentCallbackType | TableComponentWithOrWithoutOptionsType;

/**
 * @description
 * Component that injects specific components in Table cells.
 */
@Component({
  selector: 'app-injector',
  template: `<ng-template component></ng-template>`,
  encapsulation: ViewEncapsulation.None,
})
export class InjectorComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() component: TableComponentGenericType;
  @Input() table: Table;
  @Input() column: TableColumn;
  @Input() row: any;
  @Input() disabled: boolean;
  @Input() refresh: number;
  @Input() index: number;
  @Input() value: any;
  @Input() type: string; // for now reads only 'header'
  @Input() forceShowLabel: boolean;

  @Output() innerClick: EventEmitter<TableCellClickEvent> = new EventEmitter();
  @Output() innerChange: EventEmitter<TableCellChangeEvent> = new EventEmitter();

  @ViewChild(InjectorComponentDirective, { static: true }) templateData: InjectorComponentDirective;

  @HostListener('click', ['$event'])
  onClick(event) {
    this.innerClick.emit({
      event: event,
      column: this.column,
      row: this.row,
      index: this.index,
      value: this.value,
      data: {
        type: '__empty__',
      }
    });
  }

  constructor(private _componentFactoryResolver: ComponentFactoryResolver, private _utilsService: UtilsService) { }

  ngOnInit() {
    this.redraw();
  }

  ngOnChanges(changes) {
    // console.log('X...X CHANGE HAPPENED!', changes);

    if ((changes.refresh && !changes.refresh.firstChange) || (changes.disabled && !changes.disabled.firstChange)) {
      // console.log('component redraw for REFRESH or DISABLED');
      this.redraw();
    }

    if (changes.value && !changes.value.firstChange && changes.value.previousValue !== changes.value.currentValue) {
      // console.log(this.column.name, 'value changed', 'old', changes.value.previousValue, 'new', changes.value.currentValue);
    }
  }

  ngAfterViewInit() {
    // this.redraw();
  }

  isFunction(funcOrClass) {
    const propertyNames = Object.getOwnPropertyNames(funcOrClass);
    return (!propertyNames.includes('prototype') || propertyNames.includes('arguments'));
  }

  redraw() {
    // if (!this.table) return;

    // console.log('should reload component again', this.column);

    let component: TableComponentWithOrWithoutOptionsType = CellRawValueComponent; // RAW VALUE COMPONENT
    let options: any = {};

    let tempComponent = this.component;

    if (typeof this.component === 'function') {
      // if (this.component.length === 2) {
      if (this.isFunction(this.component)) {
        tempComponent = (<TableComponentCallbackType>this.component)(this.row, this.index); // CALLBACK OF CONDITIONAL CUSTOM COMPONENTS
      }
    }

    if (typeof tempComponent === 'function') {
      // CUSTOM COMPONENT
      component = <TableCellComponentComponentType>tempComponent;
    }

    if (typeof tempComponent === 'object' && Array.isArray(tempComponent)) {
      // CUSTOM COMPONENT WITH OPTIONS
      component = <TableCellComponentComponentType>tempComponent[0];
      options = <any>tempComponent[1];
    }

    const viewContainerRef = this.templateData.viewContainerRef;
    viewContainerRef.clear();

    const cmpFactory = this._componentFactoryResolver.resolveComponentFactory(component);
    const cmpRef = viewContainerRef.createComponent(cmpFactory);

    // ASSIGN COMMON PARAMETERS TO COMPONENT HEAD / CELL
    Object.assign(cmpRef.instance, <TableHeadCellComponentInterface>{
      // options: this.setCellOptions(options),
      // options: options,
      column: this.column,
      innerClick: this.innerClick,
    });

    // MERGE OPTIONS WITH EXISTENT ONES
    if (!cmpRef.instance.options) {
      cmpRef.instance.options = {};
    }
    for (const key in options) {
      if (options.hasOwnProperty(key) && typeof options[key] !== 'undefined') {
        cmpRef.instance.options[key] = options[key];
      }
    }

    // GENERATE VALUE
    const cmp = cmpRef.instance;

    if (this.type === 'header') {
      cmp.value = this.decorateHeaderCellValue(<TableHeadCellComponent>cmp);
      // console.log('cmp.value recalc', cmp.value);
    } else {
      if (this.isHeadComponent) {
        // setTimeout(() => {
        cmp.value = this.decorateHeadCellValue(<TableHeadCellComponent>cmp);
        // });
      } else {
        // console.log('this.table', this.table);

        // CREATE TABLE RECORD FOR THIS CELL
        if (!!this.table && !this.table.rows[this.index]) {
          this.table.rows[this.index] = {};
        }
        if (!!this.table && !this.table.rows[this.index][this.column.name]) {
          this.table.rows[this.index][this.column.name] = {
            value: null,
            component: cmpRef,
          };
        }

        // ASSIGN PARAMETERS TO COMPONENT CELL
        Object.assign(cmpRef.instance, <TableCellComponentInterface>{
          table: this.table,
          row: this.row,
          disabled: this.disabled,
          index: this.index,
          innerChange: this.innerChange,
        });
        cmp.value = this.decorateCellValue(<TableCellComponent>cmp);
        cmp.disabled = this.disabled || this.decorateCellDisabled(<TableCellComponent>cmp);
        if (typeof cmp.value === 'undefined') {
          cmp.value = null;
        }

        setTimeout(() => {
          if (this.table) {
            this.table.rows[this.index][this.column.name].value = cmp.value;
          }
        });
      }
    }

  }

  get isHeadComponent() {
    return !this.row;
  }

  decorateHeaderCellValue(cmp: TableHeadCellComponent) {
    if (typeof cmp.column.header.$value === 'function') {
      return cmp.column.header.$value(cmp.column, this.table);
    } else {
      return '';
    }
  }

  decorateHeadCellValue(cmp: TableHeadCellComponent) {
    if (!cmp.column.hideLabel || this.forceShowLabel) {
      if (typeof cmp.column.head.$value === 'function') {
        return cmp.column.head.$value(cmp.column);
      } else {
        return cmp.column.label;
      }
    }
    return '';
  }

  decorateCellValue(cmp: TableCellComponent): any {
    if (typeof cmp.column.cell.$value === 'function') {
      return cmp.column.cell.$value(
        cmp.row,
        cmp.index,
        !!this.table && !!this.table.rows && this.table.rows[cmp.index],
        !!this.table && this.table.rows,
      );
    } else {
      const pathValue = this._utilsService.getObjectPath(cmp.row, cmp.column.cell.path);
      if (typeof pathValue === 'undefined') {
        // return this.value; // TODO: 23/05/2023 - why cached value?! it shows previous cells of wrong rows when you filter table 
        return null; // TODO: 23/05/2023 - check if this didnt break some table behavior. Should be correct.
      }
      return pathValue;
    }
  }

  decorateCellDisabled(cmp: TableCellComponent): boolean {
    if (typeof cmp.column.cell.$disabled === 'function') {
      return cmp.column.cell.$disabled(
        cmp.row,
        cmp.index,
        !!this.table.rows && this.table.rows[cmp.index],
        this.table.rows,
      );
    }
    return false;
  }

  // setCellOptions(options: any) {
  //   if (options !== undefined && typeof options === 'function') {
  //     return options(this.row, this.index);
  //   } else {
  //     return options;
  //   }
  // }
}

@NgModule({
  declarations: [InjectorComponent, InjectorComponentDirective],
  imports: [TableCellRawValueModule, TableFilterInjectorModule, TableFiltersPreviewInjectorModule],
  exports: [InjectorComponent, TableCellComponentsModule, TableFilterInjectorModule, TableFiltersPreviewInjectorModule]
})
export class TableInjectorModule { }
