import { Component, Input } from '@angular/core';
import { TimeagoIntl } from 'ngx-timeago';
import { I18nService } from 'app/services/i18n.service';

@Component({
  selector: 'app-timeago',
  template: '<span timeago [date]="date" [live]="live"></span>',
})
export class TimeagoComponent {
  @Input() date: Date | string;
  @Input() live: boolean;

  constructor(private intl: TimeagoIntl, private _i18nService: I18nService) {
    const strings = this._i18nService.getTranslatedComponent('timeago');
    // set current language translations
    if (strings) {
      this.intl.strings = strings;
      this.intl.changes.next();
    }
  }
}
