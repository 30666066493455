import { ErrorHttpInterceptorService } from './services/http/error-http-interceptor.service';
import { BaseurlHttpInterceptorService } from './services/http/baseurl-http-interceptor.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER, Injectable, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { NgMaterialModule } from './ng-material/ng-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, registerLocaleData, DatePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseModule } from '@fuse/fuse.module';
import { TableModule } from './modules/common/table/table.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule } from '@fuse/components';
import { DragulaModule } from 'ng2-dragula';
import { ToastrModule } from 'ngx-toastr';
import { fuseConfig } from 'app/fuse-config';
import {
  TranslateCompiler,
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { environment } from 'environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ResourcesModule } from './modules/resources/resources.module';
import { PusherConfig } from './modules/global/services/pusher.subscribe';
import { LayoutModule } from './modules/layout/layout.module';
import { MODEL_HTTP_INTERCEPTORS } from './modules/global/http.interceptors';
import { TokenInterceptorService } from './services/http/token-interceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './core/auth/auth.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { InitPermissionService } from './core/auth/services/init-permission.service';
import { I18nService } from './services/i18n.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from './utils/paginator.i18n';
// SENTRY IMPORTS
import { Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { GoogleMapsModule } from '@angular/google-maps';
import { map } from 'rxjs/operators';
import * as Sentry from "@sentry/angular-ivy";

// END SENTRY IMPORTS

// New locales must be registered before bootstrap
// registerLocaleData(localeIt);
// registerLocaleData(localeEn);
// registerLocaleData(localeEs);
// registerLocaleData(localeFr);

export function InitPermissionsFactory(permissions: InitPermissionService) {
  return () => permissions.load();
}

const pusherConfig: PusherConfig = <PusherConfig>environment.pusher_connections.resource;

@Injectable()
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    const placeholder = false; //'??????';
    let untranslatedText = params.key;
    if (typeof untranslatedText === 'string') {
      untranslatedText = untranslatedText.split('@@')[0];
    }
    // return '[!] ' + untranslatedText;
    return placeholder ? placeholder : untranslatedText;
  }
}

@Injectable()
export class MyTranslateLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private prefix: string = 'assets/i18n/',
    private suffix: string = '.json') {
  }

  public getTranslation(lang: string): any {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`)
      .pipe(map(result => this.process(result)));
  }

  private process(object: object) {
    // object[key]
    const placeholder = false; // '######';
    return Object.keys(object)
      .filter(key => object.hasOwnProperty(key) && object[key] !== '')
      .reduce((result, key) => (result[key] = typeof object[key] === 'object' ? this.process(object[key]) : (placeholder ? placeholder : object[key]), result), {});
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new MyTranslateLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    CommonModule,
    FlexLayoutModule,
    AuthModule,
    NgxPermissionsModule.forRoot(),
    // TRANSLATE MODULE
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
      useDefaultLang: false,
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,

    // App modules
    LayoutModule,
    TableModule,
    HttpClientModule,

    ResourcesModule.forRoot(pusherConfig),
    //third part
    DragulaModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-center',
      autoDismiss: true,
      closeButton: true,
      progressBar: true,
    }),

    GoogleMapsModule,
    HttpClientJsonpModule,
  ],
  providers: [
    I18nService,
    {
      provide: MatPaginatorIntl,
      deps: [I18nService],
      useFactory: (_i18nService: I18nService) => new PaginatorI18n(_i18nService).getPaginatorIntl(),
    },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseurlHttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHttpInterceptorService,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: I18nService.getDefaultLanguage(),
    },
    ...MODEL_HTTP_INTERCEPTORS,
    InitPermissionService,
    {
      provide: APP_INITIALIZER,
      useFactory: InitPermissionsFactory,
      deps: [InitPermissionService, Sentry.TraceService], // ADD SENTRY AS DEP
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: environment.production ? false : true,
      }),
    },
    // END SENTRY PROVIDERS
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
