import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthRepositoryService } from 'app/repositories/auth-repository.service';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, pairwise, tap } from 'rxjs/operators';
import { SUPPORT_EMAIL } from 'app/modules/global/constants';
import { Language } from 'app/services/i18n.languages';
import { I18nService } from 'app/services/i18n.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  @ViewChild('submitButton', { read: ElementRef }) submitButton: ElementRef<HTMLInputElement>;

  subscriptions: Subscription = new Subscription();
  forgotPasswordForm: FormGroup;
  recoveryMode: boolean = false;
  token: string;

  SUPPORT_EMAIL = SUPPORT_EMAIL;

  errorAuth: boolean;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private _activateRoute: ActivatedRoute,
    private _route: Router,
    private _authRepositoryService: AuthRepositoryService,
    private _i18nService: I18nService,
    private _toastrService: ToastrService,
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  get languages(): Language[] {
    return environment.languages ? I18nService.languages || [] : [];
  }

  get currentLanguage() {
    return this._i18nService.currentLanguage;
  }

  setLanguage(lang: Language): void {
    if (lang.code !== this._i18nService.currentLanguageCode) {
      this._i18nService.setLanguage(lang.code);

      this._toastrService.success(this._i18nService.translate(_('Nuova lingua: {language}'), { language: lang.name }));

      // this._toastrService.success(this._i18nService.translate('toasts.languageChange') + ': ' + lang.name);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    const tokenChecker = this._activateRoute.queryParams
      .pipe(
        mergeMap((params) => {
          if (params.token) {
            return this._authRepositoryService.confirmationResetPassword(params.token).pipe(
              map((response) => {
                return {
                  data: response,
                };
              }),
            );
          } else {
            return of([]).pipe(
              map((response) => {
                return { data: response };
              }),
            );
          }
        }),
      )
      .subscribe((response: any) => {
        if (response.data.username && !response.data.error_send_mail) {
          this._route.navigate(['login']);
        }
      });
    this.subscriptions.add(tokenChecker);
    this.forgotPasswordForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    const formChange = this.forgotPasswordForm.valueChanges.pipe(pairwise()).subscribe(([prev, next]: [any, any]) => {
      if (prev || next) {
        this.errorAuth = false;
      }
    });
    this.subscriptions.add(formChange);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  keypressEvent(event: KeyboardEvent) {
    if (event.keyCode === ENTER) {
      this.submitButton.nativeElement.click();
    }
  }

  get onSubmit(): Observable<any> {
    return this._authRepositoryService.recoverPassword(this.forgotPasswordForm.get('email').value);
  }

  beforeSubmit() {
    this.errorAuth = false;
  }

  afterSubmit(response) {
    console.log('after submit', response);
    this.recoveryMode = true;
    this.forgotPasswordForm.reset();
    this.forgotPasswordForm.markAsPristine();
    this.forgotPasswordForm.markAsUntouched();
  }

  afterError(error) {
    this.errorAuth = true;
  }
}
