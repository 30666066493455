import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NotificationInterface } from 'app/models/notification.model';
import { first } from 'rxjs/operators';
import { NotificationsService } from '../../notifications.service';

/**
 *
 *
 * @export
 * @class NotificationButtonComponent
 * @description Show resource type icon
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss'],
})
export class NotificationButtonComponent implements OnInit {

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  constructor(
    public notificationsService: NotificationsService,
  ) { }

  ngOnInit() {

  }

  onNotificationClick(event: MouseEvent, notification: NotificationInterface) {
    event.stopPropagation();
    if (!notification.read) {
      this.notificationsService.readNotification(notification);
    }
  }

  readAll(event: MouseEvent) {
    console.log('readAll', event);

    event.stopPropagation();
    this.notificationsService.readAll();
  }

}
