import { FieldInterface } from "app/modules/common/inputs/custom-fields/fields/fields.interface";
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface WidgetInterface {
  guid: string;
  idx: number;
  depth: number;
  label: string;
  behavior: WidgetBehaviorInterface;
  width: number;
  height: number;
  left: number;
  top: number;
  color: string;
  transition: WidgetTransitionInterface;
  options: WidgetOptionsInterface & any;
  style?: WidgetStyleInterface;
  smartobject?: { fields: FieldInterface[] };
}

export class Widget implements WidgetInterface {
  guid: string;
  idx: number;
  depth: number;
  label: string;
  behavior: WidgetBehaviorInterface;
  width: number;
  height: number;
  left: number;
  top: number;
  color: string;
  transition: WidgetTransitionInterface;
  options: WidgetOptionsInterface & any;
  style?: WidgetStyleInterface;
  smartobject?: { fields: any[] };

  constructor(private _conf: WidgetInterface) {
    Object.assign(this, this._conf);
    delete this._conf;

    if (!this.options || Array.isArray(this.options)) {
      this.options = {};
    }

    const slug = this.behavior && this.behavior.slug || WidgetBehaviorSlugEnum.smartobject;
    if (slug !== WidgetBehaviorSlugEnum.smartobject && typeof this.options.default === 'undefined') {
      this.options.default = null;
    }
  }
}

export interface WidgetBehaviorInterface {
  id?: string;
  name?: string;
  icon?: string;
  slug?: string;
}

export interface WidgetStyleInterface {
  color?: string;
  'font-size'?: number;
  'text-align'?: string;
  'text-decoration'?: string;
  'word-break'?: string;
  'align-self'?: string;
  'font-weight'?: string;
  'font-style'?: string;
  'background-size'?: string;
  opacity?: number;
}

export class WidgetImageStyle implements WidgetStyleInterface {
  'opacity' = 100;
  'background-size' = 'cover';

  constructor(private _conf: WidgetStyleInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export class WidgetTextStyle implements WidgetStyleInterface {
  'color' = '#000000';
  'font-size' = 60;
  'text-align' = 'center';
  'align-self' = 'center';
  'word-break' = 'break-word';
  'text-decoration' = 'none';
  'font-weight' = 'normal';
  'font-style' = 'normal';

  constructor(private _conf: WidgetStyleInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export interface WidgetTransitionInterface {
  'animation-class'?: string;
  'animation-delay'?: number;
  'animation-duration'?: number;
}

export class WidgetTransition implements WidgetTransitionInterface {
  'animation-class' = 'none';

  constructor(private _conf: WidgetTransitionInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export interface WidgetOptionsInterface {
  alias?: string;
  default?: string;
  locked?: boolean;
  loop?: boolean;
  mute?: boolean;
  controls?: boolean;
}

export class WidgetVideoOptions implements WidgetOptionsInterface {
  loop: boolean = true;

  constructor(private _conf: WidgetOptionsInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export enum WidgetBehaviorSlugEnum {
  text = 'text',
  image = 'image',
  video = 'video',
  smartobject = 'smartobject',
}

export const WidgetBehaviorIconMap = {
  image: 'image',
  video: 'videocam',
  text: 'font_download',
  smartobject: 'bug_report',
}

export const WidgetBehaviorLabelMap = {}
WidgetBehaviorLabelMap[WidgetBehaviorSlugEnum.text] = _('Testo');
WidgetBehaviorLabelMap[WidgetBehaviorSlugEnum.image] = _('Immagine');
WidgetBehaviorLabelMap[WidgetBehaviorSlugEnum.video] = _('Video');
WidgetBehaviorLabelMap[WidgetBehaviorSlugEnum.smartobject] = _('SmartObject');
