import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Resource } from 'app/models/resource.model';

@Component({
  selector: 'resource-mini-preview',
  templateUrl: './resource-mini-preview.component.html',
  styleUrls: ['./resource-mini-preview.component.scss']
})
export class ResourceMiniPreviewComponent implements OnChanges {
  @Input() resource: Resource;
  @Input() autoplay: boolean = true;
  @ViewChild('videoPlayer', {static: true}) videoplayer: ElementRef;

  ngOnChanges(changes) {
    if (this.videoplayer) {
      this.videoplayer.nativeElement.load();
      if (this.autoplay) {
        this.videoplayer.nativeElement.play();
      }
    }
  }
}
