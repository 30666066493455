import { NgModule } from '@angular/core';
import { AppTableRowExpandTmplDirective, TableComponent } from './table.component';
import { GlobalModule } from '../../global/global.module';
import { FilterFieldComponentModule } from './components/filters/filter-field/filter-field.module';
import { TableListViewModule } from './components/table-list-view/table-list-view.module';
import { SortDirectionComponentModule } from './components/filters/sort-direction/sort-direction.module';
import { TableInjectorModule } from './components/injectors/table-component-injector';
import { FeedFilterFieldComponentModule } from 'app/modules/feed/components/feed-filter-field/feed-filter-field.module';
import { DragulaModule } from 'ng2-dragula';
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module
import { TableCellComponentsModule } from './components/cells/table-cell-components.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TableParamsHttpInterceptor } from 'app/modules/common/table/table-params.http.interceptor';
import { TableSettingsComponent } from './components/table-settings/table-settings.component';
import { FilterModule } from './components/filters/filter.module';
import { FeedFiltersPreviewComponentModule } from 'app/modules/feed/components/feed-filters-preview/feed-filters-preview.module';
import { FakeTableComponent } from './components/test/fake-table/fake-table.component';
import { CdkDetailRowDirective } from './components/test/fake-table/cdk-detail-row.directive';

@NgModule({
  declarations: [
    TableComponent,
    FakeTableComponent,
    AppTableRowExpandTmplDirective,
    CdkDetailRowDirective,
    TableSettingsComponent,
  ],
  imports: [
    GlobalModule,
    FilterModule,
    FeedFilterFieldComponentModule,
    FeedFiltersPreviewComponentModule,
    SortDirectionComponentModule,
    TableInjectorModule,
    TableListViewModule,
    DragulaModule,
    NgxPaginationModule,
  ],
  exports: [
    TableComponent,
    FakeTableComponent,
    CdkDetailRowDirective,
    TableSettingsComponent,
    TableCellComponentsModule,
    AppTableRowExpandTmplDirective,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TableParamsHttpInterceptor,
      multi: true,
    },
  ],
})
export class TableModule {}
