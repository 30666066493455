<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
  <div id="login-intro" fxFlex fxHide fxShow.gt-xs>
    <div class="logo" [@animate]="{ value: '*', params: { scale: '0.2' } }">
      <img src="assets/images/logos/fluid-logo.png" />
    </div>

    <div class="title" [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }">
      {{ 'Benvenuto su FLUID!' | translate }}
    </div>

    <!-- <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat,
      vel convallis elit fermentum pellentesque. Sed mollis velit facilisis facilisis viverra.
    </div> -->
  </div>

  <div id="login-form-wrapper" fusePerfectScrollbar [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
    fxLayout="column">
    <div fxFlex id="login-form">
      <div class="logo" fxHide.gt-xs>
        <img src="assets/images/logos/fluid-logo-alt.png" />
      </div>

      <div class="title h1">{{ 'Login' | translate }}</div>

      <form name="loginForm" [formGroup]="loginForm" novalidate>
        <mat-form-field>
          <input name="username" matInput [placeholder]="'Username' | translate" formControlName="username"
            (keypress)="keypressEvent($event)" />
          <mat-error *ngIf="loginForm.get('username').hasError('required')">
            {{ 'Obbligatorio' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input matInput name="password" [type]="showPassword ? 'text' : 'password'"
            [placeholder]="'Password' | translate" formControlName="password" (keypress)="keypressEvent($event)" />
          <button class="toggle-password-visibility" type="button" matSuffix mat-icon-button
            (click)="togglePasswordVisibility($event)">
            <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error> {{ 'Obbligatorio' | translate }} </mat-error>
        </mat-form-field>

        <mat-error *ngIf="errorAuth">
          <ng-container [ngSwitch]="errorAuth">
            <ng-container
              *ngSwitchCase="LOGIN_ERROR_STATUS?.userDisabled">{{ 'Utente non abilitato' | translate }}</ng-container>
            <ng-container
              *ngSwitchCase="LOGIN_ERROR_STATUS?.wrongCredentials">{{ 'Password errata'| translate }}</ng-container>
            <ng-container
              *ngSwitchCase="LOGIN_ERROR_STATUS?.notFound">{{ 'Utente non trovato'| translate }}</ng-container>
            <ng-container
              *ngSwitchCase="LOGIN_ERROR_STATUS?.tooManyAttempts">{{ 'Troppi tentativi falliti. Riprovare tra' | translate }}
              <app-duration [time]="retryAfter" [options]="{ hideEmpty: true }"></app-duration></ng-container>
            <ng-container
              *ngSwitchCase="LOGIN_ERROR_STATUS?.serverError">{{ 'Si è verificato un errore. Riprovare più tardi.' | translate }}</ng-container>
          </ng-container>

          <!-- {{ 'Username o password errati' | translate }} -->
        </mat-error>

        <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
          <mat-checkbox class="remember-me" aria-label="Ricordami" formControlName="remember">
            {{ 'Ricordami' | translate }}
          </mat-checkbox>
          <a class="forgot-password" [routerLink]="'/forgot-password'"> {{ 'Password dimenticata?' | translate }} </a>
        </div>

        <button #submitButton type="button" mat-raised-button color="accent" class="submit-button" aria-label="LOGIN"
          [form]="loginForm" [onSubmit]="onSubmit" (beforeSubmit)="beforeSubmit()" (afterSubmit)="afterSubmit($event)"
          (afterError)="afterError($event)" successMessage="">
          {{ 'LOGIN' | translate }}
        </button>
      </form>

      <!--  <div class="separator">
        <span class="text">OR</span>
      </div>

      <div fxLayout="column" fxLayoutAlign="start center">

        <button mat-raised-button class="google">
          Log in with Google
        </button>

        <button mat-raised-button class="facebook">
          Log in with Facebook
        </button>

      </div>

      <div class="register" fxLayout="column" fxLayoutAlign="center center">
        <span class="text">Don't have an account?</span>
        <a class="link" [routerLink]="'/pages/auth/register-2'">Create an account</a>
      </div> -->
    </div>

    <div class="text-right mt-24 mr-24">
      <img src="assets/images/positivessl_trust_seal_lg_120.png" width="120" />
    </div>

    <ng-container *ngIf="languages?.length && currentLanguage">
      <div class="pt-24 mb-24 pr-12 text-right">
        <!-- LANGUAGES -->
        <button mat-button class="language-button" [matMenuTriggerFor]="languageMenu">
          <div fxLayout="row" fxLayoutAlign="center center">
            <img class="flag mr-8" [src]="currentLanguage.imagePath" />
            <span class="iso text-uppercase">{{ currentLanguage.code }}</span>
          </div>
        </button>
        <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
          <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
            <span fxLayout="row" fxLayoutAlign="start center">
              <img class="flag mr-16" [src]="lang.imagePath" />
              <span class="iso">{{ lang.name }}</span>
            </span>
          </button>
        </mat-menu>
      </div>
    </ng-container>
  </div>
</div>