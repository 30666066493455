import { Injectable } from '@angular/core';
import { DataTransformAbstractUp } from 'app/modules/global/classes/data.transform.abstract.up';
import { TriggerInterface } from 'app/models/trigger.model';
import {
  TriggerContentsItemInterface,
  TriggerTimeInterface,
  TriggerPeriodicalInterface,
  TriggerTimeScheduleInterface,
} from '../../trigger.interface';
import { TimeslotMinutesItemInterface, TimeslotInterface } from 'app/modules/common/timeslot/timeslot.interface';

@Injectable({
  providedIn: 'root',
})
export class TriggerDataTransformUp extends DataTransformAbstractUp {
  /**
   *
   *
   * @param {TriggerInterface} system
   * @returns
   * @memberof TriggerDataTransform
   */
  transform(trigger: TriggerInterface): TriggerInterface {
    if (trigger.startAt) {
      trigger.startAt = this.removeUTC(trigger.startAt.toString());
    }
    if (trigger.endAt) {
      trigger.endAt = this.removeUTC(trigger.endAt.toString());
    }
    trigger.contents = this.normalizeContents(trigger.contents);
    return trigger;
  }

  /**
   *
   *
   * @protected
   * @param {TriggerContentsItemInterface[]} contents
   * @returns {TriggerContentsItemInterface[]}
   * @memberof TriggerDataTransformUp
   */
  protected normalizeContents(contents: TriggerContentsItemInterface[]): TriggerContentsItemInterface[] {
    if (!Array.isArray(contents)) {
      return contents;
    }
    contents.map((item: TriggerContentsItemInterface) => {
      this.normalizeTimeslots(item.timeslots);
      this.normalizePeriodical(item.periodical);
      this.normalizeBehaviorTime(item.time);
    });
    return contents;
  }

  /**
   *
   *
   * @protected
   * @param {TriggerTimeInterface} time
   * @returns {TriggerTimeInterface}
   * @memberof TriggerDataTransformUp
   */
  protected normalizeBehaviorTime(time: TriggerTimeInterface): TriggerTimeInterface {
    if (time) {
      this.normalizeBehaviorTimeSchedules(time.schedules);
    }
    return time;
  }

  /**
   *
   *
   * @protected
   * @param {TriggerTimeScheduleInterface[]} schedules
   * @returns {TriggerTimeScheduleInterface[]}
   * @memberof TriggerDataTransformUp
   */
  protected normalizeBehaviorTimeSchedules(schedules: TriggerTimeScheduleInterface[]): TriggerTimeScheduleInterface[] {
    if (!Array.isArray(schedules)) {
      return schedules;
    }
    schedules.map((item: TriggerTimeScheduleInterface) => {
      item.time = this.removeUTC(item.time.toString());
    });
    return schedules;
  }

  /**
   *
   *
   * @protected
   * @param {TriggerPeriodical} periodical
   * @returns {TriggerPeriodical}
   * @memberof TriggerDataTransformUp
   */
  protected normalizePeriodical(periodical: TriggerPeriodicalInterface): TriggerPeriodicalInterface {
    if (periodical) {
      this.normalizePeriodicalTimes(periodical.times);
    }
    return periodical;
  }

  /**
   *
   *
   * @protected
   * @param {TimeslotMinutesItemInterface[]} times
   * @returns {TimeslotMinutesItemInterface[]}
   * @memberof TriggerDataTransformUp
   */
  protected normalizePeriodicalTimes(times: TimeslotMinutesItemInterface[]): TimeslotMinutesItemInterface[] {
    if (!Array.isArray(times)) {
      return times;
    }
    times.map((item: TimeslotMinutesItemInterface) => {
      if (!item.timeslots) {
        item.timeslots = {
          from: null,
          to: null,
          alwaysOn: true,
        };
      }
      this.normalizeTimeslot(item.timeslots);
    });
    return times;
  }

  /**
   *
   *
   * @private
   * @param {TimeslotInterface[]} timeslots
   * @returns
   * @memberof TriggerDataTransformUp
   */
  protected normalizeTimeslots(timeslots: TimeslotInterface[]): TimeslotInterface[] {
    if (!Array.isArray(timeslots)) {
      return timeslots;
    }
    timeslots.map((timeslot: TimeslotInterface) => {
      this.normalizeTimeslot(timeslot);
    });
    return timeslots;
  }

  /**
   *
   *
   * @protected
   * @param {TimeslotInterface} timeslot
   * @returns {TimeslotInterface}
   * @memberof TriggerDataTransformUp
   */
  protected normalizeTimeslot(timeslot: TimeslotInterface): TimeslotInterface {
    if (timeslot.from) {
      timeslot.from = this.removeUTC(timeslot.from.toString());
    }
    if (timeslot.to) {
      timeslot.to = this.removeUTC(timeslot.to.toString());
    }
    return timeslot;
  }
}
