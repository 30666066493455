import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { NgMaterialModule } from 'app/ng-material/ng-material.module';
import { HintContentComponentModule } from './hint-content/hint-content.module';
import { HintDirective } from './hint.directive';

@NgModule({
  declarations: [HintDirective],
  imports: [HintContentComponentModule, CommonModule, NgMaterialModule, MatMenuModule],
  exports: [HintDirective],
})
export class HintDirectiveModule {}
