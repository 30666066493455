import { Directive, Input, HostListener, HostBinding, Output, EventEmitter, OnDestroy, OnChanges, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { first, startWith, filter, catchError } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { FormUtils } from '../classes/form-utils';
import { ToastrService } from 'ngx-toastr';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Directive({
  selector: '[onSubmit]',
})
export class SubmitDirective implements OnInit, OnDestroy {
  @Input() form: AbstractControl;
  @Input() validate: (form: AbstractControl) => boolean;
  @Input() onSubmit: Observable<any>;
  @Input() disabled: boolean;
  @Input() successMessage: string = this._i18nService.translate(_('Dati salvati con successo'));
  @Input() errorMessage: string = this._i18nService.translate(_('Si è verificato un errore'));
  @Output() beforeSubmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() afterSubmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() afterError: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class.onsubmit-loading') loading: boolean;
  @HostBinding('disabled') get btnDisabled(): boolean {
    return this.loading || this.disabled;
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    // console.log('submitDirective');

    // BEFORE SUBMIT BEHAVIOR
    this.beforeSubmit.next();

    if (typeof this.validate !== 'undefined') {
      // CUSTOM VALIDATION
      if (!this.validate(this.form)) {
        event.preventDefault();
        event.stopPropagation();
        return;
      } else {
        // CUSTOM VALIDATION SUBMIT
        this.submit();
      }
    } else {
      // console.log('form based');

      // FORM BASED
      if (this.form && this.form instanceof AbstractControl) {
        // SET LOADING FOR ASYNC VALIDATORS
        this.loading = true;
        // console.log('before validate status', this.form.status);
        // TRIGGER FIELDS VALIDATION
        FormUtils.validateAllFormFields(this.form);
        // console.log('after send validate status', this.form.status);
        // WAIT VALIDATORS TO COMPLETE

        // console.log('form validated', this.form.status, this.form.valid);

        this.form.statusChanges
          .pipe(
            startWith(this.form.status),
            // startWith(''),
            filter((status) => status !== 'PENDING'),
            first(),
          )
          .subscribe((status) => {
            // console.log('form status change', status);

            // console.log('status change value', this.form.status);
            if (this.form.valid) {
              // SUBMIT
              // console.log('lets submit');

              this.submit();
            } else {
              // WARN AND REMOVE LOADER
              this._toastrService.error(this._i18nService.translate(_(`Controllare i campi obbligatori`)));
              this.loading = false;
              // PRINT ERRORS
              console.warn('form errors', FormUtils.getInvalidFields(this.form));
            }
          });
      } else {
        // console.log('direct submit');
        // PLAIN SUBMIT
        this.submit();
      }
    }
  }

  constructor(private _toastrService: ToastrService, private _i18nService: I18nService) { }

  ngOnInit(): void {
    // console.log('submit directive afterError', this.afterError);
  }

  ngOnDestroy() {
    this.beforeSubmit.complete();
    this.afterSubmit.complete();
    this.afterError.complete();
  }

  submit() {
    if (this.onSubmit && this.onSubmit instanceof Observable) {
      this.loading = true;
      setTimeout(() => {
        // console.log('subscribing to submit');
        const submitSub = this.onSubmit.pipe(
          first(),
          // catchError(error => {
          //   if ()
          //   return throwError(error);
          // });
        ).subscribe(
          (response) => {
            // console.log('submit done', response);
            // AFTER SUBMIT BEHAVIOR
            this.loading = false;
            if (this.successMessage) {
              this._toastrService.success(this.successMessage);
            }
            this.afterSubmit.next(response);
          },
          (error: any) => {
            // SERVER ERROR
            this.loading = false;
            if (error && this.errorMessage) {
              console.log('submit error', error);
              this._toastrService.error(this.errorMessage);
            }
            this.afterError.next(error);
          },
        );
      });
    }
  }
}
