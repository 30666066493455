<div fxFlex="100">
    <!-- SELECT - boolean, has options -->
    <ng-container *ngIf="isSelectInput">
        <mat-form-field fxFlex="100">
            <mat-select [(ngModel)]="value" (selectionChange)="onFilterChange($event.value)"
                [placeholder]="column?.filter?.placeholder || 'Attivo' | translate">
                <mat-option>-</mat-option>
                <mat-option *ngFor="let opt of column?.filter?.options" [value]="opt.value">{{ opt.label }}</mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
    <!-- TEXT - equals or other... -->
    <ng-container *ngIf="isFieldInput">
        <mat-form-field fxFlex="100">
            <input attr.name="{{column?.filter?.inputName}}" matInput [type]="isNumber ? 'number' : 'text'"
                [autocomplete]="column?.filter?.inputName ? 'autocomplete' : 'off'" [(ngModel)]="value"
                (keyup)="onFilterChange($event.target.value)" [placeholder]="column?.filter?.placeholder">
        </mat-form-field>
    </ng-container>
</div>