import { Component, Input } from '@angular/core';
import { TableCellComponent } from '../../../classes/table-cell-component.class';

@Component({
  selector: 'app-cell-enabled',
  templateUrl: './cell-enabled.component.html',
})
export class CellEnabledComponent extends TableCellComponent {
  @Input() value: boolean;
}
