import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeedDataTransformUp } from './data-transforms/feed.data.transform.up';
import { FeedDataTransformDown } from './data-transforms/feed.data.transform.down';
import { FeedInterface } from 'app/models/feed.model';

@Injectable({
  providedIn: 'root',
})
export class FeedHttpInterceptor implements HttpInterceptor {
  private _rexpCreate = new RegExp(/feeds$/);
  private _rexpEdit = new RegExp(/feeds\/([0-9a-z]{24})$/);
  /**
   *Creates an instance of FeedHttpInterceptor.
   * @param {FeedDataTransformUp} _feedDataTransformUp
   * @param {FeedDataTransformDown} _feedDataTransformDown
   * @memberof FeedHttpInterceptor
   */
  constructor(
    private _feedDataTransformUp: FeedDataTransformUp,
    private _feedDataTransformDown: FeedDataTransformDown,
  ) {}
  /**
   *
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof FeedHttpInterceptor
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ((!this._rexpCreate.test(req.url) && !this._rexpEdit.test(req.url)) || req.method === 'DELETE') {
      return next.handle(req);
    }

    if (req.method === 'POST' || req.method === 'PUT') {
      const bodyCopy = JSON.parse(JSON.stringify(req.body));
      req = req.clone({
        body: this._feedDataTransformDown.transform(<FeedInterface>bodyCopy),
      });
    }
    // console.log('intercept', req);
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status !== 204) {
          return event.clone({
            body: this._feedDataTransformUp.transform(<FeedInterface>event.body),
          });
        }
        return event;
      }),
    );
  }
}
