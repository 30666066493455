import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
  static format(value: number | string, decimals: number = 0): string {
    if (isNaN(+value)) {
      return;
    }
    return (+value).toLocaleString('it-IT', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  }

  transform(value: number | string, decimals: number = 0): string {
    return FormatNumberPipe.format(value, decimals);
  }
}
