import { NgModule } from '@angular/core';
import { CellDateComponent } from './cell-date/cell-date.component';
import { CellThumbComponent } from './cell-thumb/cell-thumb.component';
import { CellEnabledComponent } from './cell-enabled/cell-enabled.component';
import { CellDurationComponent } from './cell-duration/cell-duration.component';
import { CellActionsComponent } from './cell-actions/cell-actions.component';
import { CellToggleComponent } from './cell-toggle/cell-toggle.component';
import { CellColorComponent } from './cell-color/cell-color.component';
import { CellResolutionComponent } from './cell-resolution/cell-resolution.component';
import { CellCoordinatesComponent } from './cell-coordinates/cell-coordinates.component';
import { GlobalModule } from 'app/modules/global/global.module';
import { RouterModule } from '@angular/router';
import { CellNameColorComponent } from './cell-name-color/cell-name-color.component';
import { DatetimeModule } from 'app/modules/common/datetime/datetime.module';
import { CellResourcePreviewComponent } from './cell-resource-preview/cell-resource-preview.component';
import { StringModule } from 'app/modules/common/string/string.module';
import { ResourcesModule } from 'app/modules/resources/resources.module';
import { ImageCommonModule } from 'app/modules/common/images/image.common.module';
import { CellTimeComponent } from './cell-time/cell-time.component';
import { CellIconComponent } from './cell-icon/cell-icon.component';
import { CellNameExtComponent } from './cell-name-ext/cell-name-ext.component';
import { CellPriceComponent } from './cell-price/cell-price.component';
import { CellFormatComponent } from './cell-format/cell-format.component';
import { UtilsModule } from 'app/modules/common/utils/ultils.module';
import { CellResourceUplaoderComponent } from './cell-resource-uploader/cell-resource-uploader.component';
import { NewUploaderModule } from 'app/modules/common/uploader/uploader.module';
import { CellStatusComponent } from './cell-status/cell-status.component';
import { CellFirmwareStatusComponent } from './cell-firmware-status/cell-firmware-status.component';
import { CellDatesStatusComponent } from './cell-dates-status/cell-dates-status.component';
import { CellDatesComponent } from './cell-dates/cell-dates.component';
import { CellProgressbarComponent } from './cell-progressbar/cell-progressbar.component';
import { CellUpdateByComponent } from './cell-update-by/cell-update-by.component';
import { CellOSLogoComponent } from './cell-os-logo/cell-os-logo.component';

@NgModule({
    declarations: [
        CellDateComponent,
        CellThumbComponent,
        CellEnabledComponent,
        CellDurationComponent,
        CellActionsComponent,
        CellToggleComponent,
        CellNameColorComponent,
        CellColorComponent,
        CellResolutionComponent,
        CellCoordinatesComponent,
        CellResourcePreviewComponent,
        CellTimeComponent,
        CellIconComponent,
        CellNameExtComponent,
        CellPriceComponent,
        CellFormatComponent,
        CellResourceUplaoderComponent,
        CellStatusComponent,
        CellFirmwareStatusComponent,
        CellDatesStatusComponent,
        CellDatesComponent,
        CellProgressbarComponent,
        CellUpdateByComponent,
        CellOSLogoComponent,
    ],
    imports: [
        GlobalModule,
        RouterModule,
        DatetimeModule,
        StringModule,
        ResourcesModule,
        ImageCommonModule,
        UtilsModule,
        NewUploaderModule,
    ],
    exports: [
        CellDateComponent,
        CellThumbComponent,
        CellEnabledComponent,
        CellDurationComponent,
        CellActionsComponent,
        CellToggleComponent,
        CellNameColorComponent,
        CellColorComponent,
        CellResolutionComponent,
        CellCoordinatesComponent,
        CellResourcePreviewComponent,
        CellTimeComponent,
        CellIconComponent,
        CellNameExtComponent,
        CellPriceComponent,
        CellFormatComponent,
        CellResourceUplaoderComponent,
        CellStatusComponent,
        CellFirmwareStatusComponent,
        CellDatesStatusComponent,
        CellDatesComponent,
        CellProgressbarComponent,
        CellUpdateByComponent,
        CellOSLogoComponent,
    ]
})
export class TableCellComponentsModule { }
