import { Resource } from 'app/models/resource.model';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Injectable } from '@angular/core';
import { ResourcePreviewContentComponent } from '../components/resource-preview/resource-preview-content.component';
import { DatasetInterface } from 'app/models/dataset.model';

@Injectable({
    providedIn: 'root'
})
export class ResourcePreviewService {

    constructor(private bottomSheet: MatBottomSheet) { }

    isResource(content) {
        // return !!content &&
        //     !!content.fileinfo;
        return !!content && content.metadata &&
            !!content.publicAvailableSource;
    }

    isDContent(content) {
        return !!content && (content.template || content.smartobjects);
    }

    open(content: Resource | DatasetInterface, options = { panelClass: 'bottomsheet-resource-preview' }) {
        if (this.isResource(content) || this.isDContent(content)) {
            const bottomSheetRef = this.bottomSheet.open(
                ResourcePreviewContentComponent, options
            );
            bottomSheetRef.instance.bottomSheetRef = bottomSheetRef;
            if (this.isResource(content)) {
                bottomSheetRef.instance.resource = <Resource>content;
            }
            if (this.isDContent(content)) {
                bottomSheetRef.instance.dcontent = <DatasetInterface>content;
            }
        }
    }
}
