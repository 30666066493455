import { Injectable } from '@angular/core';
import { ValidatorFn, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class TiemepickerInputService {
  constructor() { }

  logEnabled = false;

  normalizeDate(date, relativeTo = new Date()) {
    date = new Date(date);
    const now = new Date(relativeTo);
    now.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), 0);
    return now;
  };

  min(minDate: Date, relativeDate: Date | null): ValidatorFn {


    return (c: FormControl) => {
      // this.log('VALIDATE MIN', c.value);
      if (c.value && minDate) {
        minDate = this.normalizeDate(minDate, minDate);
        const valueDate = this.normalizeDate(c.value, relativeDate || minDate);
        if (valueDate < minDate) {
          // this.log('error min', valueDate, '<=', minDate);
          return {
            min: `Can't be minor of ${minDate.toString()}`,
          };
        }
      }
      return null;
    };
  }

  max(maxDate: Date, relativeDate: Date | null): ValidatorFn {
    // console.log('validate max');

    return (c: FormControl) => {
      this.log('VALIDATE MAX', 'current date', c.value);
      this.log('VALIDATE MAX', 'max date', maxDate);
      if (c.value && maxDate) {
        maxDate = this.normalizeDate(maxDate, maxDate);
        const valueDate = this.normalizeDate(c.value, relativeDate || maxDate);
        this.log('VALIDATE MAX', 'current date EDIT', valueDate);
        this.log('VALIDATE MAX', 'max date EDIT', maxDate);
        if (valueDate > maxDate) {
          this.log('error max', valueDate, '>=', maxDate);
          return {
            max: `Can't be major of ${maxDate.toString()}`,
          };
        }
      }
      return null;
    };
  }

  log(...args) {
    if (this.logEnabled) {
      console.log(...args);
    }
  }
}
