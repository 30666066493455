<app-cell-icon
  style="display: flex"
  *ngIf="value"
  [options]="{ icon: 'check_circle', classes: 'green-400-fg', tooltip: 'Abilitato' | translate }"
></app-cell-icon>
<app-cell-icon
  style="display: flex"
  *ngIf="!value"
  [options]="{ icon: 'check_circle', classes: 'grey-400-fg', tooltip: 'Disabilitato' | translate }"
></app-cell-icon>
