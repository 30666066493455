import { UserRole } from 'app/models/user-role';

import { Injectable } from '@angular/core';
import { includes } from 'lodash';
import { UserInterface } from 'app/models/user.model';
import { Subject } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { GrantInterface } from '../models/grant.model';

/**
 * The grant service
 */
@Injectable({
  providedIn: 'root',
})
export class GrantService {
  permissions: Subject<PermissionChangeInterface>;

  constructor() {
    this.permissions = new Subject<PermissionChangeInterface>();
  }

  /**
   * Returns true if the user has the specified role
   */
  isGranted(user: UserInterface, role: UserRole): boolean {
    if (!user) {
      return false;
    }

    return includes(user.roles, role.name);
  }
}

export interface PermissionChangeInterface {
  grants: GrantInterface[];
  type: string;
}
