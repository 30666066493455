import { Component, Input } from '@angular/core';
import { RESOURCE_TYPE, Resource } from 'app/models/resource.model';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DatasetInterface } from 'app/models/dataset.model';


@Component({
  selector: 'resource-preview-content',
  templateUrl: './resource-preview-content.component.html',
  styleUrls: ['./resource-preview-content.component.scss']
})
export class ResourcePreviewContentComponent {
  _resource: Resource;
  _dcontent: DatasetInterface;
  path: string;
  @Input()
  set resource(resource: Resource) {
    this._resource = resource;
    this.path =
      this._resource.publicWebSource || this._resource.publicAvailableSource;
  }
  get resource() {
    return this._resource;
  }
  set dcontent(dcontent: DatasetInterface) {
    this._dcontent = dcontent;
    this.path = '';
  }
  get dcontent() {
    return this._dcontent;
  }
  resourceType = RESOURCE_TYPE;
  constructor(public bottomSheetRef: MatBottomSheetRef<ResourcePreviewContentComponent>) { }

  get hasMetadata() {
    return !!this.resource && Object.keys(this.resource.metadata).length > 0;
  }
}
