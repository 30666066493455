import { Injectable } from '@angular/core';
import { DataTransformAbstractUp } from 'app/modules/global/classes/data.transform.abstract.up';
import { UserInterface } from 'app/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserDataTransformUp extends DataTransformAbstractUp {

  transform(user: UserInterface): UserInterface {
    return user;
  }

}
