import { NgModule } from '@angular/core';
import { NgMaterialModule } from 'app/ng-material/ng-material.module';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { InputClearComponent } from './input-clear.component';

@NgModule({
    declarations: [
        InputClearComponent
    ],
    imports: [
        NgMaterialModule,
        FuseSharedModule,
        CommonModule
    ]
})

export class InputClearComponentModule {}
