import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { LS_TABLE_COLUMNS, LS_TABLE_PARAMS, LS_TABLE_PARAMS_OLD } from 'app/modules/global/localstorage';

// TODO: rework other Interceptors, this is PERFECT!

@Injectable({
  providedIn: 'root',
})
export class TableParamsHttpInterceptor implements HttpInterceptor {
  constructor() { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('interceptor inside table');
    if (req.method === 'GET') {
      const cachedRequest = this.getCachedRequest(req);
      if (cachedRequest) {
        req = cachedRequest;
      }
      // // HARDCODED
      // console.log('GET REQ', req.params, req);
      const filtersStr = req.params.get('filters');
      // console.log('filterStr', filtersStr);
      if (!!filtersStr && typeof filtersStr === 'string') {
        let filters;
        try {
          filters = JSON.parse(filtersStr);
        } catch (err) { }

        if (!!filters) {
          // FEED CONTENTS
          if (!Array.isArray(filters) && typeof filters === 'object') {
            Object.keys(filters).forEach((k) => {
              const v = filters[k];
              if (v.type === 'text' && typeof v.value === 'string') {
                // console.log('FOUND REPLACEMENT TEXT!!', v.value);
                v.value = v.value.replace(/[[\]()\\]/g, '\\$&');
                // console.log('FOUND REPLACEMENT TEXT NOW IS', v.value);
              }
            });
            // console.log('filters are finally', filters);
            const httpParams = req.params.keys().reduce((acc, val) => {
              acc[val] = req.params.get(val);
              return acc;
            }, {});
            httpParams['filters'] = JSON.stringify(filters);
            req = req.clone({
              setParams: httpParams,
            });
            // console.log('NEW TEXT REQ is', req);
          }

          // ALL TABLES
          if (!!filters && Array.isArray(filters)) {
            filters = filters.map((v) => {
              if (v.type === 'search' && typeof v.value === 'string') {
                // console.log('FOUND REPLACEMENT!!', v.value);
                v.value = v.value.replace(/[[\?\*\]()\\]/g, '\\$&');
                // console.log('FOUND REPLACEMENT NOW IS', v.value);
              }
              return v;
            });
            const httpParams = req.params.keys().reduce((acc, val) => {
              acc[val] = req.params.get(val);
              return acc;
            }, {});
            httpParams['filters'] = JSON.stringify(filters);
            req = req.clone({
              setParams: httpParams,
            });
            // console.log('NEW REQ is', req);
          }
        }
      }
    }
    return next.handle(req);
  }

  getCachedRequest(req) {
    // SPLIT BASEURL IF EXISTS
    const reqUrls = req.url.split(environment.baseurl);
    // console.log('split by', environment.baseurl);
    const reqUrl = reqUrls.length === 2 ? reqUrls[1] : reqUrls[0];
    if (reqUrl) {
      // PROCESS ONLY URLS WITHOUT QUERY PARAMS
      const urlWithoutParams = !req.params.keys().length;
      if (urlWithoutParams) {
        const table_params = this.getCachedTableParamsObject();
        const cachedParams = table_params[reqUrl];

        // PROCESS IF CURRENT URL HAS CACHED PARAMS
        if (cachedParams && Object.keys(cachedParams).length) {
          // console.log('cached params detected', cachedParams);
          let cachedFilters = cachedParams.filters && JSON.parse(cachedParams.filters);
          if (cachedFilters && Array.isArray(cachedFilters)) {
            // console.log('cached filters detected', cachedFilters);
            const table_columns = this.getCachedTableColumnsObject();
            const cachedColumns = table_columns[reqUrl];

            // PROCESS IF CURRENT URL HAS CACHED COLUMNS
            if (cachedColumns && Object.keys(cachedColumns).length) {
              // console.log('cached columns detected', cachedColumns);
              // FILTER QUERY PARAM FILTERS, REMOVE HIDDEN COLUMN FILTERS
              cachedFilters = cachedFilters.filter((filter, index) => {
                return cachedColumns[filter.field] !== 0;
              });
              // console.log('params filters must have been cleared', cachedFilters);
              // SAVE FILTERED QUERY PARAM FILTERS
              cachedParams.filters = JSON.stringify(cachedFilters);
              this.setCachedTableParamsObject(table_params);
            }
          }
          // console.log('overwrite request with', cachedParams);
          // OVERWRITE REQUEST QUERY PARAMS
          return req.clone({
            setParams: cachedParams,
          });
        }
      }
    }
    return null;
  }

  getCachedTableParamsObject() {
    return JSON.parse(localStorage.getItem(LS_TABLE_PARAMS)) || {};
  }

  setCachedTableParamsObject(obj) {
    window.localStorage.setItem(LS_TABLE_PARAMS, JSON.stringify(obj));
  }

  getCachedTableColumnsObject() {
    return JSON.parse(localStorage.getItem(LS_TABLE_COLUMNS)) || {};
  }
}

// TODO: remove this after few weeks
// COPY OLD FILTERS TO NEW KEY AND REMOVE OLD ONE
const applyCachedParamsNameMigration = () => {
  const oldData = localStorage.getItem(LS_TABLE_PARAMS_OLD);
  if (oldData) {
    window.localStorage.setItem(LS_TABLE_PARAMS, oldData);
    window.localStorage.removeItem(LS_TABLE_PARAMS_OLD);
  }
};
