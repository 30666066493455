import { NgModule } from '@angular/core';
import { DatetimeModule } from 'app/modules/common/datetime/datetime.module';
import { GlobalModule } from 'app/modules/global/global.module';
import { FeedFilterFieldComponent } from './feed-filter-field.component';
@NgModule({
    declarations: [FeedFilterFieldComponent],
    imports: [GlobalModule, DatetimeModule],
    exports: [FeedFilterFieldComponent]
})
export class FeedFilterFieldComponentModule {}
