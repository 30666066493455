<ng-container [ngSwitch]="resource?.type">
  <div
    class="content"
    *ngSwitchCase="'images'"
    backgroundImage
    backgroundSize="contain"
    [backgroundImageSrc]="resource.publicAvailableSource"
  >
    <!-- <img [src]="resource.publicAvailableSource" style="opacity: 0" /> -->
  </div>
  <video #videoPlayer class="content" *ngSwitchCase="'videos'" [autoplay]="autoplay" controls loop>
    <source [src]="resource.publicWebSource" type="video/mp4" />
  </video>
</ng-container>
