/**
 * Result of paginated endpoints
 */
export interface PaginatedResponseInterface<T> {
  items: T[];
  pagination: PaginatedResponsePaginationInterface;
}

export interface PaginatedResponsePaginationInterface {
  current?: number;
  currentItemCount?: number;
  endPage?: number;
  first?: number;
  firstItemNumber?: number;
  firstPageInRange?: number;
  last?: number;
  lastItemNumber?: number;
  lastPageInRange?: number;
  next?: number;
  numItemsPerPage?: number;
  pageCount?: number;
  pageRange?: number;
  pagesInRange?: number[];
  startPage?: number;
  totalCount?: number;
}

export class PaginatedResponse<T> implements PaginatedResponseInterface<T> {
  items: T[] = [];
  pagination: PaginatedResponsePaginationInterface;

  constructor(private _config?: PaginatedResponseInterface<T>) {
    Object.assign(this, this._config);
    this.pagination = new PaginatedResponsePagination(this.pagination);
    delete this._config;
  }
}

export class PaginatedResponsePagination implements PaginatedResponsePaginationInterface {
  current: number = 0;
  currentItemCount: number = 0;
  endPage: number = 0;
  first: number = 1;
  firstItemNumber: number = 0;
  firstPageInRange: number = 0;
  last: number = 0;
  lastItemNumber: number = 0;
  lastPageInRange: number = 1;
  next: number = 0;
  numItemsPerPage: number = 20;
  pageCount: number = 0;
  pageRange: number = 0;
  pagesInRange: number[] = [1, 0];
  startPage: number = 1;
  totalCount: number = 0;

  constructor(private _config?: PaginatedResponsePaginationInterface) {
    Object.assign(this, this._config);
    delete this._config;
  }
}
