import { Component, Input, OnChanges, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { merge } from 'rxjs';
import { Table } from '../../../classes/table.class';

@Component({
  selector: 'app-filters-preview',
  templateUrl: './filters-preview.component.html',
  styleUrls: ['./filters-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FiltersPreviewComponent implements OnInit, OnChanges {
  @Input() table: Table;
  @Input() options: any;

  chips = [];

  ngOnInit() {
    merge(
      this.table.onFilterFieldChange$,
      this.table.filterBuilder.filtersChange$,
      this.table.sort.sortChange,
    ).subscribe((event) => {
      console.log('filter field change detected', event);
      this.processFilters();
    });

    this.processFilters();
  }

  ngOnChanges(changes) {
    // if (changes.filters) {
    // this.processFilters();
    // }
  }

  processFilters() {
    console.log('prrocess filters in preview in table', this.table);
    this.chips = [];

    this.table.filterColumns.forEach((column) => {
      console.log('processing filter column', column.name);
      const filter = column.filter;
      const value = filter.toString();
      if (this.table.filterBuilder.isset(column.name) && value !== null) {
        const chipObj: TableFilterPreviewChip = {
          name: column.name,
          label: column.label || column.name,
          compare: '',
          viewType: null,
          value: undefined,
        };
        switch (filter.type) {
          case 'boolean': {
            chipObj.viewType = 'boolean';
            chipObj.value = JSON.parse(value || false);
            break;
          }
          case 'search': {
            chipObj.compare = '=';
            chipObj.value = '"%' + value + '%"';
            break;
          }
          default: {
            // 'equals', 'select'
            chipObj.compare = '=';
            chipObj.value = '"' + value + '"';
            break;
          }
        }
        this.chips.push(chipObj);
      }
    });

    console.log('chips found', this.chips);

    this.setSortChip();
  }

  protected setSortChip() {
    if (this.table.sort.active && this.table.sort.direction) {
      let sortColumn = this.chips.find((chip) => {
        return this._camelToSnake(chip.name) === this._camelToSnake(this.table.sort.active);
      });
      if (sortColumn) {
        sortColumn.sort = this.table.sort.direction;
      } else {
        let columnName = this.table.sort.active;
        if (!this.table.hasColumn(columnName)) {
          columnName = this._snakeToCamel(this.table.sort.active);
        }
        sortColumn = this.table.getColumn(columnName);
        if (sortColumn) {
          const chipObj: TableFilterPreviewChip = {
            name: sortColumn.name,
            label: sortColumn.label || sortColumn.name,
            sort: this.table.sort.direction,
          };
          this.chips.push(chipObj);
        }
      }
    }
  }

  findColumnByName(name) {
    return this.table.columns.find((column) => {
      return column.name === name;
    });
  }

  protected _camelToSnake(str) {
    return String(str)
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
  }

  private _snakeToCamel(s) {
    return String(s).replace(/(\_\w)/g, function (m) {
      return m[1].toUpperCase();
    });
  }
}

export interface TableFilterPreviewChip {
  name: string;
  label: string;
  compare?: string;
  viewType?: string;
  value?: any;
  sort?: string;
}
