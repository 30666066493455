<div fxLayout="row" [formGroup]="parts" class="app-timepicker-input-component ellipsis"
  [ngClass]="{ 'app-timepicker-input-clearable': clearable }">
  <input class="app-timepicker-input-field app-timepicker-input-hour" appearance="outline" formControlName="hour"
    (click)="onHourFocus($event)" (keydown)="onKeydown($event)" (input)="onValueChange($event)"
    (blur)="padHour($event.target.value)" (focus)="onHourFocus($event.target)" [attr.disabled]="disabled ? true : null"
    type="number" autocomplete="off" placeholder="H" />
  <span class="app-timepicker-input-spacer">:</span>
  <input class="app-timepicker-input-field app-timepicker-input-minute" appearance="outline" formControlName="minute"
    (click)="onMinuteFocus($event)" (keydown)="onKeydown($event)" (input)="onValueChange($event)"
    (blur)="padMinute($event.target.value)" (focus)="onMinuteFocus($event.target)"
    [attr.disabled]="disabled ? true : null" type="number" autocomplete="off" placeholder="M" />
  <ng-container *ngIf="hasSeconds">
    <span class="app-timepicker-input-spacer">:</span>
    <input class="app-timepicker-input-field app-timepicker-input-second" appearance="outline" formControlName="second"
      (click)="onSecondFocus($event)" (keydown)="onKeydown($event)" (input)="onValueChange($event)"
      (blur)="padSecond($event.target.value)" (focus)="onSecondFocus($event.target)"
      [attr.disabled]="disabled ? true : null" type="number" autocomplete="off" placeholder="S" />
  </ng-container>
  <ng-container *ngIf="!isH24">
    <mat-select formControlName="period" (selectionChange)="update()" [disabled]="disabled">
      <mat-option *ngFor="let period of TimepickerPeriodList" [value]="period.value">{{ period.label }}</mat-option>
    </mat-select>
  </ng-container>

</div>

<!-- <pre>R: {{ required }} {{ hasRequiredValidator }}</pre> -->

<!-- <pre>
V: {{ value?.toLocaleString() }}
D: {{ date?.toLocaleString() }}
</pre> -->