import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GlobalModule } from 'app/modules/global/global.module';
import { NotificationButtonComponent } from './notification-button.component';

@NgModule({
    declarations: [
        NotificationButtonComponent,
    ],
    imports: [
        GlobalModule,
        RouterModule,
    ],
    exports: [
        NotificationButtonComponent,
    ]
})
export class NotificationButtonModule {}
