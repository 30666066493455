import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './pages/login/login.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { FuseSharedModule } from '@fuse/shared.module';
import { SubmitDirectiveModule } from '../../modules/global/directives/submit.directive.module';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { AuthRoutingModule } from './auth-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { DatetimeModule } from 'app/modules/common/datetime/datetime.module';
import { GlobalModule } from 'app/modules/global/global.module';

/** The auth module */
@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent],
  imports: [
    GlobalModule,
    AuthRoutingModule,
    SubmitDirectiveModule,
    DatetimeModule,
  ],
})
export class AuthModule {}
