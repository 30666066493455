import { Directive, ViewContainerRef, NgModule, ComponentRef } from '@angular/core';
import { Type } from '@angular/core';
import { Component, Input, OnInit, ViewChild, ComponentFactoryResolver, ViewEncapsulation } from '@angular/core';
import { Table } from '../../classes/table.class';
import { FiltersPreviewComponent } from '../filters/filters-preview/filters-preview.component';

/**
 * @description
 * Directive that injects a specific component template.
 */
@Directive({
  selector: '[filtersPreviewComponent]',
})
export class InjectorFiltersPreviewDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

/**
 * @description
 * Component that injects specific components in Table cells.
 */
@Component({
  selector: 'app-filters-preview-injector',
  template: `<ng-template filtersPreviewComponent></ng-template>`,
  encapsulation: ViewEncapsulation.None,
})
export class InjectorFiltersPreviewComponent implements OnInit {
  @Input() component?: Type<FiltersPreviewComponent> | [Type<FiltersPreviewComponent>, any];
  @Input() table: Table;

  @ViewChild(InjectorFiltersPreviewDirective, { static: true }) templateData: InjectorFiltersPreviewDirective;

  constructor(private _componentFactoryResolver: ComponentFactoryResolver) {}

  cmpRef: ComponentRef<FiltersPreviewComponent>;

  ngOnInit() {
    let component: Type<FiltersPreviewComponent> | [Type<FiltersPreviewComponent>, any],
      options: any = {};

    if (!this.component) {
      component = FiltersPreviewComponent; // RAW VALUE COMPONENT
    } else {
      if (this.component instanceof Array) {
        // CUSTOM COMPONENT WITH OPTIONS
        component = this.component[0];
        options = this.component[1];
      } else {
        // CUSTOM COMPONENT
        component = this.component;
      }
    }

    const viewContainerRef = this.templateData.viewContainerRef;
    viewContainerRef.clear();

    const cmpFactory = this._componentFactoryResolver.resolveComponentFactory(component);
    this.cmpRef = viewContainerRef.createComponent(cmpFactory);

    // ASSIGN PARAMETERS TO COMPONENT CELL
    Object.assign(this.cmpRef.instance, <any>{
      table: this.table,
    });

    // MERGE OPTIONS WITH EXISTENT ONES
    if (!this.cmpRef.instance.options) {
      this.cmpRef.instance.options = {};
    }
    for (const key in options) {
      if (options.hasOwnProperty(key) && typeof options[key] !== 'undefined') {
        this.cmpRef.instance.options[key] = options[key];
      }
    }
  }

  // ngOnChanges(changes) {
  //   if (changes.value && !changes.value.firstChange) {
  //     // console.log('INJECTOR changes', changes);
  //     // ASSIGN PARAMETERS TO COMPONENT CELL
  //     Object.assign(this.cmpRef.instance, <TableCellComponentInterface>{
  //       value: this.value,
  //     });
  //     // TODO: find a way to call ngOnChanges
  //     this.cmpRef.changeDetectorRef.detectChanges();
  //   }
  // }
}

@NgModule({
    declarations: [InjectorFiltersPreviewComponent, InjectorFiltersPreviewDirective],
    imports: [],
    exports: [InjectorFiltersPreviewComponent]
})
export class TableFiltersPreviewInjectorModule {}
