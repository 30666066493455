import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CUSTOM_ICONS } from './icons.map';

@Injectable({
  providedIn: 'root',
})
export class IconsRegistryService {
  // ADD NEW SVG ICONS HERE
  constructor(private _domSanitizer: DomSanitizer, private _matIconRegistry: MatIconRegistry) {
    CUSTOM_ICONS.forEach((svgIcon) => {
      this.add(svgIcon[0], svgIcon[1]);
    });
  }

  get origin() {
    return window.location.href.split('/').slice(0, 3).join('/');
  }

  add(name, path) {
    if (path.indexOf('://') === -1) {
      // NO ORIGIN SPECIFIED
      if (path.startsWith('/')) {
        path = path.slice(1);
      }
      path = `${this.origin}/${path}`;
    }
    this._matIconRegistry.addSvgIcon(name, this._domSanitizer.bypassSecurityTrustResourceUrl(`${path}`));
  }
}
