import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { StringService } from './../string.service';

/**
 *
 *
 * @export
 * @class ResolutionComponent
 * @description Format resolution view (width x height)
 * @implements {OnInit}
 */
@Component({
  selector: 'app-resolution',
  templateUrl: './resolution.component.html',
  styleUrls: ['./resolution.component.scss'],
})
export class ResolutionComponent implements OnInit, OnChanges {
  @Input() width: any;
  @Input() height: any;
  @Input() errorLabel: string;
  @Input() unit: string;
  @Input() showArea: boolean;

  resolution: string;
  area: number;

  constructor(private _stringService: StringService) {}

  ngOnInit() {
    this.reload();
  }

  ngOnChanges() {
    this.reload();
  }

  reload() {
    if (this.isValid) {
      this.resolution = this.getResolutionString();
      if (this.showArea) {
        this.area = this.getArea();
      }
    }
  }

  getResolutionString() {
    return this._stringService.getResolutionString(this.width, this.height);
  }

  getArea() {
    return this.width * this.height;
  }

  // isSet() {
  //   return this.width !== null && this.height !== null;
  // }

  get isValid() {
    // return this.width > 0 && this.height > 0;
    return typeof this.width !== 'undefined' && typeof this.height !== 'undefined';
  }
}
