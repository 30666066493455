import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthenticationService } from 'app/core/auth/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorHttpInterceptorService implements HttpInterceptor {
  /**
   *
   * @param authenticationService
   * @param _toastr
   */
  constructor(
    private authenticationService: AuthenticationService,
    private _toastr: ToastrService, //private route: Router
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.match(/login_check/) || request.url.match(/user\/me/)) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((err) => {
        console.log('catchError', err);
        if (err.status === 401) {
          // console.log('getRemoteUserMe error INTERCEPTED');
          this.authenticationService.logout();
          location.reload();
        }
        // check statuses backoffice
        if (err.status >= 403 && err.status < 500) {
          if (environment.authBounceForceLogout) {
            console.warn('status error http: ', err.status);
            this.authenticationService.logout();
            location.reload();
          } else {
            if (err.status === 406) {
              return throwError(err.error);
            } else {
              this._toastr.error('Si è verificato un errore del server');
            }
          }
        } else {
          this._toastr.error('Si è verificato un errore del server');
        }
        const error = (err.error && err.error.message) || err.statusText;
        return throwError(error);
      }),
    );
  }
}
