import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { TableColumnInterface } from 'app/modules/common/table/interfaces/table-column.interface';
import { Table } from '../../../classes/table.class';
import {
  TableFilterFieldInterface,
  TableFilterFieldEventInterface,
} from '../../injectors/table-component-filter-field';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nService } from 'app/services/i18n.service';

/**
 *
 *
 * @export
 * @class FilterFieldComponent
 * @description Input or Select dynamic filter input
 */
@Component({
  selector: 'app-filter-field',
  templateUrl: './filter-field.component.html',
  styleUrls: ['./filter-field.component.scss'],
})
export class FilterFieldComponent implements TableFilterFieldInterface, OnInit, OnChanges {
  @Input() column: TableColumnInterface;
  @Input() value: any;
  @Input() table: Table;
  @Output() filterChange: EventEmitter<TableFilterFieldEventInterface> = new EventEmitter();

  booleanOptions = this._i18nService.translateArray(TableFilterBooleanOptions, ['label']);

  constructor(
    private _i18nService: I18nService,
  ) { }

  onFilterChange(event) {
    // this.value = value;
    // console.log('onFilterChange', this.value);

    let value = this.value;

    if (this.isBoolean && typeof this.value !== 'undefined') {
      value = JSON.parse(this.value);
    }

    if (this.isText) {
      value = value ? value : undefined;
    }

    this.filterChange.emit({
      column: this.column,
      value: value,
      // value: this.isBoolean && typeof this.value !== 'undefined' ? JSON.parse(this.value) : this.value,
    });
  }

  get isBoolean() {
    return this.column.filter.type === 'boolean';
  }

  get isText() {
    return ['equals', 'search'].indexOf(<string>this.column.filter.type) !== -1;
  }

  get isNumber() {
    return ['number'].indexOf(<string>this.column.filter.type) !== -1;
  }

  ngOnInit() {
    if (this.isBoolean) {
      this.column.filter.options = this.booleanOptions;
    }
  }

  ngOnChanges(changes) { }

  get isSelectInput() {
    return this.isBoolean || (this.column.filter.options && this.column.filter.options.length > 0);
  }

  get isFieldInput() {
    return !this.isSelectInput;
  }
}

export const TableFilterBooleanOptions = [
  {
    label: _('Sì'),
    value: true,
  },
  {
    label: _('No'),
    value: false,
  },
]

export const TableFilterBooleanOnlyTrueOptions = [
  {
    label: _('Sì'),
    value: true,
  },
]
