<!-- <mat-chip-list *ngIf="chips.length > 0" aria-label="Filters">
  <mat-chip class="green-600" style="font-weight: normal; pointer-events: none" *ngFor="let chip of chips">{{
    chip.name + ' ' + chip.compare + ' ' + chip.value
  }}</mat-chip>
</mat-chip-list> -->
<div fxLayout="column" class="filters-preview p-12" *ngIf="chips.length > 0" aria-label="Filters">
  <small fxLayout="column">
    <span class="mb-4 grey-400-fg">Filtri:</span>
    <span fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="flex-start center">
      <span fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="flex-start center" class="filter-preview mb-4"
        *ngFor="let chip of chips">
        <span *ngIf="chip.sort"
          class="filter-preview-sort"><mat-icon>{{ chip.sort === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon></span>
        <span>{{ chip.label }}</span>
        <span *ngIf="chip.compare">{{ chip.compare }}</span>
        <ng-container [ngSwitch]="chip.viewType">
          <mat-icon class="filter-preview-value" *ngSwitchCase="'boolean'" [color]="chip.value ? 'accent' : 'warn'">{{
            chip.value ? 'check_circle' : 'not_interested'
          }}</mat-icon>
          <ng-container *ngSwitchDefault>
            <span class="filter-preview-value" *ngIf="chip.value">{{ chip.value }}</span>
          </ng-container>
        </ng-container>
      </span>
    </span>
  </small>
</div>