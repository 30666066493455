import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { BACKSPACE, COMMA, DASH, EQUALS, LEFT_ARROW, PERIOD } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  HostBinding,
  HostListener,
  ChangeDetectorRef,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, ControlValueAccessor, NgControl, FormControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nService } from 'app/services/i18n.service';
import { DateService } from 'app/utils/services/date.service';
import { Subject } from 'rxjs';
import { TimepickerFormatEnum, TimepickerPeriodEnum, TimepickerPeriodList } from '../datetime.interface';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';


/**
 *
 *
 * @export
 * @class TimepickerInputComponent
 * @description Custom timepicker-input field
 * @implements {ControlValueAccessor}
 * @implements {MatFormFieldControl<Date>}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-timepicker-input',
  templateUrl: 'timepicker-input.component.html',
  styleUrls: ['timepicker-input.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TimepickerInputComponent,
    },
  ],
})
export class TimepickerInputComponent implements ControlValueAccessor, MatFormFieldControl<Date>, OnChanges, OnDestroy {
  static nextId = 0;
  logEnabled = false;

  parts: FormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'app-timepicker-input';

  TimepickerPeriodList = this._i18nService.translateArray(TimepickerPeriodList, ['label']);

  @HostBinding('class.app-timepicker-input-floating') shouldLabelFloatValue: boolean;
  @HostBinding('id') id: string = `app-timepicker-input-${TimepickerInputComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy: string = '';

  get empty() {
    const {
      value: { hour, minute, second },
    } = this.parts;
    return !hour && !minute && !second;
  }

  get shouldLabelFloat() {
    this.shouldLabelFloatValue = true; // this.focused || !this.empty;
    return this.shouldLabelFloatValue;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get errorState() {
    return this.ngControl.touched && this.ngControl.errors !== null
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);

    if (this.focused) {
      this.focused = false;
    }

    if (this.ngControl && this.ngControl.control) {
      if (value) {
        this.ngControl.control.disable();
      } else {
        this.ngControl.control.enable();
      }
    }

    console.log('this.ngControl.control', this.ngControl?.control);

    this.stateChanges.next();
    // console.log('set disabled');
  }
  private _disabled = false;

  @Input()
  get date(): Date {
    return this._date;
  }
  set date(value: Date) {
    if (typeof value !== 'undefined' && value instanceof Date) {
      this._date = DateService.parse(value);
      if (!this.hasSeconds) {
        this._date.setSeconds(0);
      }
      this._date.setMilliseconds(0);
      // console.log('DATE CHANGED!!!', this._date);
      this.stateChanges.next();
    }
  }
  private _date: Date = new Date(new Date().setHours(new Date().getHours(), new Date().getMinutes(), 0, 0));

  @Input() clearable: boolean;

  @Input()
  get hasSeconds(): boolean {
    return this._hasSeconds;
  }
  set hasSeconds(value: boolean) {
    this._hasSeconds = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _hasSeconds = false;

  @Input() format: TimepickerFormatEnum = this._currentUserService.timeFormat;

  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  // CONTROL VALUE ACCESSOR
  onChange: any = () => { };
  onTouched: any = () => { };

  value: Date | null;

  getFormattedHours() {
    if (this.isH24) {
      return this.value?.getHours();
    }
    return this.value.getHours() % 12 || 12;
  }

  getRealHours() {
    const period = this.parts.get('period').value;
    let hours = +this.parts.get('hour').value;
    if (this.isH24) {
      return hours;
    }
    // Convert hours to 24-hour format
    if (period === TimepickerPeriodEnum.pm && hours < 12) {
      hours += 12;
    }
    if (period === TimepickerPeriodEnum.am && hours === 12) {
      hours = 0;
    }
    return hours;
  }

  isValidDate(date) {
    return (date instanceof Date && date.toString() !== 'Invalid Date') || (typeof date === 'string' && new Date(date).toString() !== 'Invalid Date');
  }

  syncView(from = '', value: Date = this.value) {
    // console.log('sync', from, value);
    if (this.isValidDate(value)) {
      const newTime = new Date(value);
      const tempDate = new Date(this.date);
      tempDate.setHours(
        newTime.getHours(),
        newTime.getMinutes(),
        newTime.getSeconds(),
        // this.hasSeconds ? newTime.getSeconds() : 0,
        0
      );
      this.value = new Date(tempDate);

      this.parts.setValue({
        hour: this.pad(this.getFormattedHours()),
        minute: this.pad(this.value.getMinutes()),
        second: this.pad(this.value.getSeconds()),
        period: this.value.getHours() < 12 ? TimepickerPeriodEnum.am : TimepickerPeriodEnum.pm,
      });
    } else {
      this.value = null;
      this.initForm();
    }
  }

  writeValue(value: Date | null) {
    // console.log('WRITE VALUE TO TIME PICKER', value, this);
    this.syncView('writeValue', value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener('focusout')
  onBlur() {

    setTimeout(() => {
      const inputs = this.getInputs();
      // console.log('inputs', inputs);
      // console.log('document.activeElement', document.activeElement);
      if (!inputs.includes(<HTMLInputElement>document.activeElement)) {
        // console.log('blurred out from all inputs')
        this.setDefaultValues();
      }
    })

    this.focused = false;
    this.onTouched();
    this.stateChanges.next();
  }

  constructor(
    public ngControl: NgControl,
    private _fb: FormBuilder,
    private _fm: FocusMonitor,
    private _elRef: ElementRef<HTMLElement>,
    private _i18nService: I18nService,
    private _currentUserService: CurrentUserService,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;

      // setInterval(() => {
      //   console.log('this.ngControl', this.ngControl);
      // }, 5000);
    }

    this.initForm();

    this._fm.monitor(<any>this._elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !this.disabled && !!origin;
      this.stateChanges.next();
    });

    // setTimeout(() => {
    //   const yyy = this.ngControl?.control?.hasValidator(Validators.required);
    //   console.log('HAS VALIDATOR', yyy);
    // }, 2000);
  }

  // get hasRequiredValidator() {
  //   return this.ngControl?.control?.hasValidator(Validators.required);
  // }

  initForm() {
    this.parts = this._fb.group({
      hour: null,
      minute: null,
      second: null,
      period: TimepickerPeriodEnum.am,
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('ngOnChanges', changes);

    if (changes['date'] && !changes['date'].firstChange) {
      // console.log('relative date changed, update main value');
      setTimeout(() => {
        this.onTouched();
        this.update('ngOnChanges relative date', false);
        this.syncView();
      });
    }

    if (
      changes['date'] ||
      changes['format']
    ) {
      this.syncView('ngOnChanges');
    }
  }

  pad(value) {
    this.log('pad', value);
    let tempValue = String(value);
    if (tempValue.length === 1) {
      tempValue = '0' + value;
    }
    // tempValue = tempValue.slice(0, 2);
    return tempValue;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._fm.stopMonitoring(<any>this._elRef.nativeElement);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this._elRef.nativeElement.querySelector('input').focus();
    }
  }

  getInputs() {
    return Array.from(this._elRef.nativeElement.querySelectorAll('input'));
  }

  onKeydown(event) {
    this.log('onHourKeydown', event);
    const blacklist = [EQUALS, DASH, PERIOD, COMMA]; // + - . ,
    if (blacklist.includes(event.keyCode)) {
      event.preventDefault();
    }

    // console.log('event.keyCode', event, event.keyCode);

    switch (event.keyCode) {
      case BACKSPACE: {
        setTimeout(() => {
          if (!event.target.value.trim()) {
            const inputs = this.getInputs();
            const currentIndex = inputs.findIndex((e) => e === event.target);
            // console.log('inputs', inputs, currentIndex);
            if (currentIndex > 0) {
              const prevInput = inputs[currentIndex - 1];
              prevInput.focus();
              this.selectText(prevInput);
              event.preventDefault();
            }
          }
        });
        break;
      }
    }

  }

  onValueChange(event) {
    if (!isNaN(event.target.valueAsNumber)) {
      let value = event.target.value;

      // if pasted
      if (event.inputType === 'insertFromPaste') {
        // set value without prefix "+" or "-"
        event.target.value = '';
        // remove decimals
        value = (+value).toFixed(0);
      }

      // last two digits
      value = String(value).slice(-2);
      event.target.value = value;

      // allow clear
      if (value !== '' && event.inputType !== 'deleteContentForward' && event.inputType !== 'deleteContentBackward') {
        const type = event.target.attributes.formcontrolname.value;
        this.log('type is ', type);
        // keyboard numbers
        if (type === 'hour') {
          value = this.onHourChange(event);
        }
        if (type === 'minute') {
          value = this.onMinuteChange(event);
        }
        if (type === 'second') {
          value = this.onSecondChange(event);
        }
        this.parts.get(type).setValue(value);
      }
    } else {
      event.target.value = '';
    }

    this.update('onValueChange');
  }

  get isH24() {
    return this.format === TimepickerFormatEnum.h24;
  }

  // CUSTOM
  onHourChange(event) {

    const hourMin = this.isH24 ? 0 : 1;
    const hourMax = this.isH24 ? 23 : 12;

    // const hourMin = 0;
    // const hourMax = 23;

    this.log('onHourChange', event);
    let value = event.target.value;
    // keyboard numbers
    if (event.inputType === 'insertText' || event.inputType === 'insertFromPaste') {
      if (value < hourMin) {
        value = hourMin;
      }
      if (value > hourMax) {
        value = hourMax;
      }
      value = this.formatHour(value);
    } else {
      // arrows
      if (value < hourMin) {
        value = hourMax;
      }
      if (value > hourMax) {
        value = hourMin;
      }
      value = this.pad(value);
    }
    return value;
  }

  onMinuteChange(event) {
    this.log('onMinuteChange', event);
    let value = event.target.value;
    // keyboard numbers
    if (event.inputType === 'insertText' || event.inputType === 'insertFromPaste') {
      if (value < 0) {
        value = 0;
      }
      if (value > 59) {
        value = 59;
      }
      value = this.formatMinute(value);
    } else {
      // arrows
      if (value < 0) {
        value = 59;
      }
      if (value > 59) {
        value = 0;
      }
      value = this.pad(value);
    }
    return value;
  }

  onSecondChange(event) {
    this.log('onSecondChange', event);
    let value = event.target.value;
    // keyboard numbers
    if (event.inputType === 'insertText' || event.inputType === 'insertFromPaste') {
      if (value < 0) {
        value = 0;
      }
      if (value > 59) {
        value = 59;
      }
      value = this.formatSecond(value);
    } else {
      // arrows
      if (value < 0) {
        value = 59;
      }
      if (value > 59) {
        value = 0;
      }
      value = this.pad(value);
    }
    return value;
  }

  formatHour(value) {
    this.log('formatHour');
    value = String(value);
    const l = value.length;
    const single = l === 1;
    const double = l === 2;

    const untilDigit = this.isH24 ? 2 : 1;

    if (value > untilDigit && single) {
      this.focusMinuteInput();
      return this.pad(value);
    }
    if (double) {
      this.focusMinuteInput();
    }
    return value;
  }

  formatMinute(value) {
    this.log('formatMinute');
    value = String(value);
    const l = value.length;
    const single = l === 1;
    const double = l === 2;
    if (value > 5 && single) {
      if (this.hasSeconds) {
        this.focusSecondInput();
      }
      return this.pad(value);
    }
    if (this.hasSeconds && double) {
      this.focusSecondInput();
    }
    return value;
  }

  formatSecond(value) {
    this.log('formatSecond');
    value = String(value);
    const l = value.length;
    const single = l === 1;
    if (value > 5 && single) {
      return this.pad(value);
    }
    return value;
  }

  selectText(target) {
    this.log('target', target);
    if (target && typeof target.select === 'function') {
      target.select();
    }
  }

  focusMinuteInput() {
    this.log('focusMinuteInput');
    const domMinute = this._elRef.nativeElement.querySelectorAll('input')[1];
    if (domMinute) {
      domMinute.focus();
      this.selectText(domMinute);
      this.onMinuteFocus(domMinute);
    }
  }

  focusSecondInput() {
    this.log('focusSecondInput');
    const domSecond = this._elRef.nativeElement.querySelectorAll('input')[2];
    if (domSecond) {
      domSecond.focus();
      this.selectText(domSecond);
      this.onMinuteFocus(domSecond);
    }
  }

  padHour(value) {
    // console.log('padHour', this.parts.get('hour').value, value);
    if (String(value).length === 1) {
      this.parts.get('hour').setValue(this.pad(value));
    }
  }

  padMinute(value) {
    // console.log('padMinute', value);
    if (String(value).length === 1) {
      this.parts.get('minute').setValue(this.pad(value));
    }
  }

  padSecond(value) {
    // console.log('padSecond', value);
    if (String(value).length === 1) {
      this.parts.get('second').setValue(this.pad(value));
    }
  }

  onHourFocus(event) {
    this.selectText(event.target ?? event);
  }

  onMinuteFocus(event) {
    this.selectText(event.target ?? event);
  }

  onSecondFocus(event) {
    this.selectText(event.target ?? event);
  }

  setDefaultValues() {
    setTimeout(() => {

      const {
        value: { hour, minute, second },
      } = this.parts;

      // if (this.required || hour !== null || minute !== null || second !== null) {
      //   if (hour === null) {
      //     this.padHour(0);
      //   }
      //   if (minute === null) {
      //     this.padMinute(0);
      //   }
      //   if (second === null) {
      //     this.padSecond(0);
      //   }
      // }

      if (hour !== null) {
        if (minute === null) {
          this.padMinute(0);
        }
        if (second === null) {
          this.padSecond(0);
        }
      }

      this.update('setDefaultValues');
    });
  }

  update(from = '', emitEvent: boolean = true) {
    console.log('update from', from);
    this.value = this.getModel();
    this.stateChanges.next();
    this.onChange(this.value);
    if (emitEvent) {
      this.valueChanged.emit(this.value);
    }
  }

  getModel() {
    const {
      value: { hour, minute, second },
    } = this.parts;

    // if (!this.required && !hour && !minute && !second) {
    //   return null;
    // }

    if (!hour) {
      return null;
    }

    const realHour = this.getRealHours();
    // console.log('getModel', this.parts, hour, minute, second, 'realHour', realHour);

    const tempDate = new Date(this.date);
    tempDate.setHours(
      realHour || 0,
      minute || 0,
      this.hasSeconds ? (second || 0) : 0,
      0
    );

    return tempDate;
  }

  log(...args) {
    if (this.logEnabled) {
      console.log(...args);
    }
  }
}
