import { style } from '@angular/animations';
import { Directive, ElementRef, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[backgroundImage]'
})
export class BackgroundImageDirective implements OnChanges {

  @Input()
  backgroundImageSrc: string;
  @Input()
  backgroundSize: string = 'cover';

  ngOnChanges(changes: SimpleChanges): void {

    this.el.nativeElement.style.backgroundRepeat = 'no-repeat';
    this.el.nativeElement.style.backgroundPosition = 'center center';
    this.el.nativeElement.style.backgroundSize = this.backgroundSize;
    this.el.nativeElement.style.backgroundImage = `url("${
      this.backgroundImageSrc
      }")`;

  }

  constructor(private el: ElementRef) { }
}
