import { Injectable } from '@angular/core';
import { DataTransformAbstractDown } from 'app/modules/global/classes/data.transform.abstract.down';
import { DatePipe } from '@angular/common';
import { CustomerInterface } from 'app/models/customer.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerDataTransformDown extends DataTransformAbstractDown {

  constructor(protected datePipe: DatePipe) {
    super();
  }

  transform(customer: CustomerInterface): CustomerInterface {
    if (Array.isArray(customer.systems)) {
      customer.systems = <any>customer.systems.map(v => v.id);
    }
    return customer;
  }
}
