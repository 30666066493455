import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Notification, NotificationCountInterface, NotificationInterface } from 'app/models/notification.model';
import { License } from 'app/models/license.model';
import DateTimeFormat = Intl.DateTimeFormat;
import { PaginatedResponseInterface } from 'app/models/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationRepositoryService {
  /**
   *Creates an instance of NotificationRepositoryService.
   * @param {HttpClient} _http
   * @memberof NotificationRepositoryService
   */
  constructor(private _http: HttpClient) {}
  /**
   *
   *
   * @param {*} [_params={}]
   * @returns {Observable<Notification[]>}
   * @memberof NotificationRepositoryService
   */
  findAll(_params: any = {}): Observable<PaginatedResponseInterface<NotificationInterface>> {
    // const fake = new Notification({
    //   id: '123123123',
    //   sender: 'FluidBot',
    //   subject: 'You created a stuff',
    //   message: 'You successfully done something great',
    //   created_at: new Date().toISOString(),
    // });

    // const fakeItems: NotificationInterface[] = new Array(50).fill(fake).map((v) => {
    //   return { ...fake };
    // });

    // return of({
    //   items: fakeItems,
    //   pagination: {
    //     current: 1,
    //     pageCount: 5,
    //     totalCount: fakeItems.length,
    //   },
    // });

    return this._http.get<PaginatedResponseInterface<NotificationInterface>>('notifications', {
      params: Object.assign({}, _params, {
        sorting: 'createdAt', // HARDCODED
        directtion: 'desc',
      }),
    });
  }

  /**
   *
   *
   * @returns {Observable<any>}
   * @memberof NotificationRepositoryService
   */
  setReadAll(): Observable<any> {
    return this._http.put(`notifications/readall`, {});
  }

  /**
   *
   *
   * @param {string[]} ids
   * @returns {Observable<any>}
   * @memberof NotificationRepositoryService
   */
  setRead(ids: string[]): Observable<any> {
    return this._http.put(`notifications/read`, ids);
  }

  /**
   *
   *
   * @param {string[]} ids
   * @returns {Observable<any>}
   * @memberof NotificationRepositoryService
   */
  setUnread(ids: string[]): Observable<any> {
    return this._http.put(`notifications/unread`, ids);
  }

  /**
   *
   *
   * @returns {Observable<NotificationCountInterface>}
   * @memberof NotificationRepositoryService
   */
   getUnreadCount(): Observable<NotificationCountInterface> {
    return this._http.get<NotificationCountInterface>(`notifications/count`);
  }

  /**
   *
   *
   * @returns {Observable<Notification[]>}
   * @memberof NotificationRepositoryService
   */
  getAll(): Observable<Notification[]> {
    return this._http.get<Notification[]>(`notifications/all`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<Notification>}
   * @memberof NotificationRepositoryService
   */
  update(id: string, data: NotificationInterface): Observable<any> {
    return this._http.put(`notifications/${id}`, data);
  }

  /**
   *
   *
   * @param {Object} { ids: string [] }
   * @returns
   * @memberof ResourcesRepositoryService
   */
  readAll(ids: string[]) {
    return this._http.request('post', 'notifications/multi/read', {
      body: ids,
    });
  }

  /**
   *
   *
   * @param {Object} { ids: string [] }
   * @returns
   * @memberof ResourcesRepositoryService
   */
  unreadAll(ids: string[]) {
    return this._http.request('post', 'notifications/multi/unread', {
      body: ids,
    });
  }
}
