import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TriggerDataTransformUp } from './data-transforms/trigger.data.transform.up';
import { TriggerDataTransformDown } from './data-transforms/trigger.data.transform.down';
import { TriggerInterface } from 'app/models/trigger.model';

@Injectable({
  providedIn: 'root',
})
export class TriggerHttpInterceptor implements HttpInterceptor {
  private _rexpCreate = new RegExp(/triggers$/);
  private _rexpEdit = new RegExp(/triggers\/([0-9a-z]{24})$/);
  /**
   *Creates an instance of TriggerHttpInterceptor.
   * @param {TriggerDataTransformUp} _triggerDataTransformUp
   * @param {TriggerDataTransformDown} _triggerDataTransformDown
   * @memberof TriggerHttpInterceptor
   */
  constructor(
    private _triggerDataTransformUp: TriggerDataTransformUp,
    private _triggerDataTransformDown: TriggerDataTransformDown,
  ) {}
  /**
   *
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof TriggerHttpInterceptor
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this._rexpCreate.test(req.url) && !this._rexpEdit.test(req.url)) {
      //TODO: fix this, one Regex is enough
      return next.handle(req);
    }

    if (req.method === 'POST' || req.method === 'PUT') {
      const bodyCopy = JSON.parse(JSON.stringify(req.body));
      req = req.clone({
        body: this._triggerDataTransformDown.transform(<TriggerInterface>bodyCopy),
      });
    }
    // console.log('intercept', req);
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status !== 204) {
          return event.clone({
            body: this._triggerDataTransformUp.transform(<TriggerInterface>event.body),
          });
        }
        return event;
      }),
    );
  }
}
