import { Component, Input, OnInit } from '@angular/core';
import { TableCellComponent } from '../../../classes/table-cell-component.class';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

interface DataFirmwareComponentInterface {
  icon?: string;
  tooltip?: string;
  color?: string;
}

const FIRMWARE_STATUSES: any = {
  available: {
    tooltip: _('Disponibile al download'),
    icon: 'save',
    color: 'red-400-fg',
  },
  download: {
    tooltip: _('Download in corso'),
    icon: 'cloud_download',
    color: 'blue-400-fg',
  },
  install: {
    tooltip: _('Installazione in corso'),
    icon: 'change_circle',
    color: 'orange-400-fg',
  },
  updated: {
    tooltip: _("Aggiornato all'ultima versione disponibile"),
    icon: 'cloud_done',
    color: 'green-400-fg',
  },
};

@Component({
  selector: 'app-cell-firmware-status',
  templateUrl: './cell-firmware-status.component.html',
})
export class CellFirmwareStatusComponent extends TableCellComponent {
  @Input() value: string;

  statuses = this._i18nService.translateNestedObject(FIRMWARE_STATUSES, ['tooltip']);

  constructor(private _i18nService: I18nService) {
    super();
  }

  get data(): DataFirmwareComponentInterface {
    return this.statuses[this.value];
  }
}
