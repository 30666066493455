import { NgModule } from '@angular/core';
import { GlobalModule } from 'app/modules/global/global.module';
import { DateComponent } from './date/date.component';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { DatesListComponent } from './dates-list/dates-list.component';
import { DayLabelComponent } from './day-label/day-label.component';
import { TimeViewComponent } from './time-view/time-view.component';
import { TimepickerInputComponent } from './timepicker-input/timepicker-input.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { WeekdaysComponent } from './weekdays/weekdays.component';
import { DurationComponent } from './duration/duration.component';
import { DatesStatusComponent } from './dates-status/dates-status.component';
// import {
//     TimepickerInputValidatorMinDirective,
//     TimepickerInputValidatorMaxDirective,
// } from './timepicker-input/timepicker-input-validators.directive';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DateslotsComponent } from './dateslots/dateslots.component';
import { MonthpickerComponent } from './monthpicker/monthpicker.component';
import { DatetimeRangeSelectorComponent } from './datetime-range-selector/datetime-range-selector.component';
import { DatetimeRangePopoverSelectorComponent } from './datetime-range-popover-selector/datetime-range-popover-selector.component';
import { UpdateByComponent } from './update-by/update-by.component';
import { MonthIndexesComponent } from './monthindexes/monthindexes.component';
import { InputOptionsModule } from 'app/modules/global/directives/input-options/input-options.module';
import { MonthLabelComponent } from './month-label/month-label.component';
import { MyDatepickerComponent } from './datepicker/mydatepicker.component';
import { DateslotComponent } from './dateslot/dateslot.component';
import { DatesRangeDialogComponent } from './dialogs/dates-range-dialog/dates-range-dialog.component';
import { DateslotDialogComponent } from './dialogs/dateslot-dialog/dateslot-dialog.component';

@NgModule({
    declarations: [
        DateComponent,
        DatesListComponent,
        DayLabelComponent,
        MonthLabelComponent,
        DurationComponent,
        TimeViewComponent,
        TimepickerComponent,
        TimepickerInputComponent,
        // TimepickerInputValidatorMinDirective,
        // TimepickerInputValidatorMaxDirective,
        DatesStatusComponent,
        WeekdaysComponent,
        MonthIndexesComponent,
        MonthpickerComponent,
        DatepickerComponent,
        MyDatepickerComponent,
        DatesRangeDialogComponent,
        DateslotDialogComponent,
        DateslotsComponent,
        DateslotComponent,
        DatetimeRangeSelectorComponent,
        DatetimeRangePopoverSelectorComponent,
        UpdateByComponent,
    ],
    imports: [GlobalModule, FusePipesModule, InputOptionsModule],
    exports: [
        DateComponent,
        DatesListComponent,
        DayLabelComponent,
        MonthLabelComponent,
        DurationComponent,
        TimeViewComponent,
        TimepickerComponent,
        TimepickerInputComponent,
        // TimepickerInputValidatorMinDirective,
        // TimepickerInputValidatorMaxDirective,
        DatesStatusComponent,
        WeekdaysComponent,
        MonthIndexesComponent,
        MonthpickerComponent,
        DatepickerComponent,
        MyDatepickerComponent,
        DatesRangeDialogComponent,
        DateslotDialogComponent,
        DateslotsComponent,
        DateslotComponent,
        DatetimeRangeSelectorComponent,
        DatetimeRangePopoverSelectorComponent,
        UpdateByComponent,
    ]
})
export class DatetimeModule { }
