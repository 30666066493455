import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgMaterialModule } from 'app/ng-material/ng-material.module';
import { IconComponent } from './icon.component';

@NgModule({
  declarations: [IconComponent],
  imports: [CommonModule, FuseSharedModule, NgMaterialModule],
  exports: [IconComponent],
})
export class IconModule {}
