/**
 * The user role base interface
 */
export interface UserRole {
  name: string;
}


/**
 * List of possibile user roles
 */
export const Roles = {
  ADMIN: <UserRole>{
    name: 'ROLE_SUPER_ADMIN'
  },
  STAFF: <UserRole>{
    name: 'ROLE_STAFF'
  },
  USER: <UserRole>{
    name: 'ROLE_USER'
  }
};
