import { Directive, HostListener, Input } from '@angular/core';
import { DatasetInterface } from 'app/models/dataset.model';
import { Resource } from 'app/models/resource.model';
import { ResourcePreviewService } from 'app/modules/resources/services/resource.preview.service';

@Directive({
  selector: '[openResource]'
})
export class OpenResourceDirective {
  @Input() openResource: Resource | DatasetInterface;
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    this.open(event);
  }

  constructor(
    private _resourcePreviewService: ResourcePreviewService
  ) { }

  open(event) {
    console.log('OpenResourceDirective', event);
    // if (this._resourcePreviewService.isResource(this.openResource) || this._resourcePreviewService.isDContent(this.openResource)) {
    if (this._resourcePreviewService.isResource(this.openResource)) {
      this._resourcePreviewService.open(this.openResource);
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
