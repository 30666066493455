export const MapNavigationPermissions = {
  // REMOTE CONTROL
  accessToRemoteCtrlModule: 'remote-control-section',

  // CAMPAIGNS (ADS)
  accessToCommercialModule: 'ads-section',
  canReadCampaignsData: 'campaigns', // THERE IS NO ID IN NAVIGATION, SPECIAL APPROACH?

  // CONTENTS
  accessToContentModule: 'brands-section',
  canReadUploadContentData: 'brands',
  canReadSystemsData: 'systems',
  canReadRulesMasterData: 'overrides',
  canReadBindData: 'binds',
  canReadTriggersData: 'triggers',
  canReadDistributionsData: 'distributions',
  canReadCircuitsData: 'circuits',

  // DYNAMIC CONTENTS
  accessToDynamicContentModule: 'templates-section',
  canReadDataSetsData: 'datasets',
  canReadTemplateData: 'templates',
  canReadDContentData: 'smartobjects',
  canReadFeedsData: 'feeds',

  // CUSTOMERS
  accessToCustomersModule: 'customers-section',

  // CREDENTIALS
  accessToAuthModule: 'credentials-section',
  canAccessGroupsData: 'groups',
  canReadAuthData: 'users',

  // FIRMWARES
  accessToFirmwareModule: 'firmwares-section',
  canReadFirmwareData: 'firmwares',
  canReadFirmwareRuleData: 'firmwarerules',

  // MONITOR
  accessToMonitorModule: 'monitor-section',
  canReadLogData: 'logs',
  canReadStatusSystemsData: 'status',
  canReadSystemOverviewData: 'system-overview',

  // COUNTER
  accessToCounterModule: 'counter-section',
  canReadAudienceData: 'audience',
  canReadAnalyticsData: 'analytics',
  canReadOtsData: 'ots-stats',

  // SUPPORT
  accessToSupportModule: 'support-section',
  canOpenTicketData: 'openticket',
  canViewChangelogData: 'changelog-section',
  canViewHelperCenterData: 'help-hints',
}