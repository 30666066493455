import { Component, Input, OnChanges, ChangeDetectorRef, EventEmitter, Output, AfterViewInit, Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { TimeslotService } from '../../timeslot/timeslot.service';
import { DatetimeService } from '../datetime.service';
import { DateRangeInterface } from './dateslot.interface';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DateslotService } from './dateslot.service';

@Injectable()
export class CustomShowOnTouchedErrorStateMatcher {
  isErrorState(c: FormControl) {
    return c.touched && c.errors !== null;
  }
}

/**
 *
 *
 * @export
 * @class DateslotsComponent
 * @description Timeslots with assignable brightness value per each component
 * @extends {TimeslotsComponent}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-dateslots',
  templateUrl: './dateslots.component.html',
  styleUrls: ['./dateslots.component.scss'],
  providers: [{ provide: ErrorStateMatcher, useClass: CustomShowOnTouchedErrorStateMatcher }],
})
export class DateslotsComponent implements AfterViewInit, OnChanges {
  @Input() formArray: FormArray;
  @Input() fromField: string = 'startAt';
  @Input() toField: string = 'endAt';
  @Input() fromLabel: string = this._i18nService.translate(_('Data inizio'));
  @Input() toLabel: string = this._i18nService.translate(_('Data fine'));
  @Input() min: string | Date;
  @Input() max: string | Date;
  @Input() range: DateRangeInterface[];
  @Input() hasTime: boolean;
  @Input() allRequired: boolean;
  @Input() addFromDialog: boolean = false;

  @Input() set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
  get required() {
    return this._required;
  }
  protected _required = false;

  @Input() set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.formArray.disable();
    } else {
      this.formArray.enable();
    }
  }
  get disabled() {
    return this._disabled;
  }
  protected _disabled = false;

  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  validation: any = {};

  constructor(
    protected _cdr: ChangeDetectorRef,
    protected _timeslotService: TimeslotService,
    protected _dateslotService: DateslotService,
    private _datetimeService: DatetimeService,
    private _i18nService: I18nService,
  ) { }

  openDateslotDialog() {
    this._dateslotService.openDateslotDialog(new FormGroup({}), this.min, this.max, this.allRequired, this.hasTime).afterClosed().subscribe(data => {
      console.log('data', data);
      if (!!data) {
        this.formArray.push(data.group);
        this.formArray.controls.sort((a, b) => {

          let daf = a.get(this.fromField).value;
          let dbf = b.get(this.fromField).value;

          daf = daf ? new Date(daf).toISOString() : '';
          dbf = dbf ? new Date(dbf).toISOString() : '';

          let dat = a.get(this.toField).value;
          let dbt = b.get(this.toField).value;

          dat = dat ? new Date(dat).toISOString() : '';
          dbt = dbt ? new Date(dbt).toISOString() : '';

          return (daf.localeCompare(dbf)) || (dat.localeCompare(dbt));
        });
        this.formArray.updateValueAndValidity();
        this.runCustomValidation();
      }
    });
  }

  ngAfterViewInit() {
    this.runCustomValidation();
  }

  ngOnChanges(changes) {
    if (changes.formArray || changes.min || changes.max) {
      this.runCustomValidation();
    }
  }

  addDateslot() {
    // this.formArray.push(new FormGroup(this._datetimeService.buildDateslot()));
    this.formArray.push(new FormGroup(this._dateslotService.buildRangeItem(null, {
      hasTime: this.hasTime,
    })));
    this.runCustomValidation();
  }

  removeEvent(i) {
    this.formArray.removeAt(i);
    this.runCustomValidation();
  }

  checkDate(value) {
    if (value) {
      return new Date(value);
    }
    return null;
  }

  get count() {
    return this.formArray.length;
  }

  get firstFrom() {
    return this.count && this.checkDate(this.formArray.controls[0].get(this.fromField).value);
  }

  get firstTo() {
    return this.count && this.checkDate(this.formArray.controls[0].get(this.toField).value);
  }

  get lastFrom() {
    return this.count && this.checkDate(this.formArray.controls[this.count - 1].get(this.fromField).value);
  }

  get lastTo() {
    return this.count && this.checkDate(this.formArray.controls[this.count - 1].get(this.toField).value);
  }

  first(index) {
    return index === 0;
  }

  last(index) {
    return index === this.count - 1;
  }

  from(index) {
    return this.formArray.controls[index].get(this.fromField);
  }

  to(index) {
    return this.formArray.controls[index].get(this.toField);
  }

  prevTo(index) {
    return this.formArray.controls[index - 1].get(this.toField);
  }

  nextFrom(index) {
    return this.formArray.controls[index + 1].get(this.fromField);
  }

  runCustomValidation() {
    this.validation = {};
    this.formArray.controls.forEach((group, index) => {
      if (!this.validation[index]) {
        this.validation[index] = {};
      }
      if (this.range) {
        this.validation[index].range = this.rangeValidation(index);
      }
      this.validation[index].fromMin = this.fromMinValidation(index);
      this.validation[index].fromMax = this.fromMaxValidation(index);
      this.validation[index].fromRequired = this.fromRequiredValidation(index);
      this.validation[index].toMin = this.toMinValidation(index);
      this.validation[index].toRequired = this.toRequiredValidation(index);
      this.validation[index].toMax = this.toMaxValidation(index);
    });
    this.formArray.updateValueAndValidity();
    this._cdr.detectChanges();
  }

  fromRequiredValidation(index) {
    return !this.first(index) || this.allRequired ? true : false;
  }

  toRequiredValidation(index) {
    return !this.last(index) || this.allRequired ? true : false;
  }

  findRange(value) {
    for (let i = 0; i < this.range.length; i++) {
      const item = this.range[i];
      const validFrom = !item.startAt || new Date(item.startAt) <= new Date(value);
      const validTo = !item.endAt || new Date(item.endAt) >= new Date(value);
      if (validFrom || validTo) {
        return item;
      }
    }
  }

  rangeValidation(index) {
    if (this.from(index).value) {
      return this.findRange(this.from(index).value);
    }
    if (this.to(index).value) {
      return this.findRange(this.to(index).value);
    }
  }

  rangeMin(index) {
    return !!this.validation[index].range && this.validation[index].range.startAt;
  }

  rangeMax(index) {
    return !!this.validation[index].range && this.validation[index].range.endAt;
  }

  fromMinValidation(index) {
    if (!this.first(index)) {
      return this.prevTo(index).value;
    }
    return this.rangeMin(index) || this.min;
  }

  fromMaxValidation(index) {
    if (this.to(index).value && this.to(index).value < this.from(index).value) {
      return this.to(index).value;
    }
    return this.max;
  }

  toMinValidation(index) {
    if (this.from(index).value) {
      return this.from(index).value;
    }
    return this.min;
  }

  toMaxValidation(index) {
    if (!this.last(index)) {
      return this.nextFrom(index).value;
    }
    return this.max;
  }

  fromChanged(event) {
    this.runCustomValidation();
    this.valueChanged.emit(event);
  }

  toChanged(event) {
    this.runCustomValidation();
    this.valueChanged.emit(event);
  }
}
