export const environment = {
  production: false,
  authBounceForceLogout: false,
  baseurl: 'https://api-dev-programmatic.fluidnext.com/',
  s3: { bucket: 'themadbox.fluid.programmatic' },
  mapsApiKey: 'AIzaSyD2LMqyYpryHajjvi5noGyMvPwZUjtumM4',
  s3BaseUrl: 'https://s3-eu-west-1.amazonaws.com/',
  languages: true,
  pusher_connections: {
    resource: {
      id: '331d32e2ae7f3b9815f7',
      cluster: 'mt1',
      forceTLS: true,
      channel: 'fpr_backoffice',
    },
    remote_fe: {
      id: 'f58cf30459b5bda95cf4',
      cluster: 'eu',
      forceTLS: true,
      debug: false,
    },
  },
  sentry: true,
  sentry_form: false,
  sentry_dsn: 'https://bd36f8ff89334839a43fb3d54947b726@o62535.ingest.sentry.io/5707095',
  hint_video: {
    brand_upload: '610279433c796b00693e3ae6',
    scheduling: '610279443c796b006a2bae18',
    date_hour: '610279433c796b0075409ff2',
  },
  notificationsCheck: true,
  changelog: '1.16.0-beta.45',
};
