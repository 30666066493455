import { registerLocaleData } from '@angular/common';
// IMPORT GLOBAL LOCALES
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
// IMPORT LOCALES FOR TIMEAGO MODULE
import { strings as timeagoIt } from 'ngx-timeago/language-strings/it';
import { strings as timeagoEn } from 'ngx-timeago/language-strings/en';
import { strings as timeagoFr } from 'ngx-timeago/language-strings/fr';
import { strings as timeagoDe } from 'ngx-timeago/language-strings/de';
import { strings as timeagoEs } from 'ngx-timeago/language-strings/es';

// ADD NEW GLOBAL LANGUAGE
registerLocaleData(localeIt);
registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeEs);

export const FLUID_LANGUAGES: Language[] = [
  {
    code: 'it',
    name: 'Italiano',
    imagePath: 'assets/images/flags/it.png',
    native: true,
    components: {
      timeago: timeagoIt,
    },
  },
  {
    code: 'en',
    name: 'English',
    default: true,
    imagePath: 'assets/images/flags/us.png',
    components: {
      timeago: timeagoEn,
    },
  },
  {
    code: 'fr',
    name: 'Français',
    imagePath: 'assets/images/flags/fr.png',
    components: {
      timeago: timeagoFr,
    },
  },
  {
    code: 'de',
    name: 'Deutsch',
    imagePath: 'assets/images/flags/de.png',
    components: {
      timeago: timeagoDe,
    },
  },
  {
    code: 'es',
    name: 'Español',
    imagePath: 'assets/images/flags/es.png',
    components: {
      timeago: timeagoEs,
    },
  },
];

export interface Language {
  code: string;
  name: string;
  imagePath?: string;
  default?: boolean;
  native?: boolean;
  components?: {
    [componentName: string]: any;
  };
}
