import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { LS_HELP_CENTER } from 'app/modules/global/localstorage';
import { I18nService } from 'app/services/i18n.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HintDialogComponent } from './dialogs/hint-dialog/hint-dialog.component';
import { HINT_DATA } from './hint.data';
import {
  HintDataInterface,
  HintItem,
  HintDataItemInterface,
  HintItemInterface,
  HintServiceInterface,
} from './hint.interface';

@Injectable({
  providedIn: 'root',
})
export class HintService implements HintServiceInterface {
  data: HintDataInterface = HINT_DATA({ I18nService: this._i18nService });
  actionText = this._i18nService.translate(_('mostra altro'));
  tooltip = this._i18nService.translate(_('Scopri di più'));
  title = this._i18nService.translate(_('Info'));
  footer = this._i18nService.translate(_('Se hai ulteriori dubbi contatta il nostro servizio clienti.'));
  enabled: boolean;
  enabledChanges: BehaviorSubject<boolean>;
  constructor(private _i18nService: I18nService, private _matDialog: MatDialog) {
    this.enabled = this.getEnabled();
    this.enabledChanges = new BehaviorSubject<boolean>(this.enabled);
  }

  config(config: HintDataInterface): void {
    Object.assign(this, config);
  }

  setEnabled(v) {
    this.enabled = !!v;
    this.enabledChanges.next(this.enabled);
    window.localStorage.setItem(LS_HELP_CENTER, !!v ? '1' : '0');
  }

  getEnabled() {
    return !!JSON.parse(window.localStorage.getItem(LS_HELP_CENTER));
  }

  enable() {
    this.setEnabled(true);
  }

  disable() {
    this.setEnabled(false);
  }

  toggle() {
    this.setEnabled(!this.enabled);
  }

  get(key: string): HintDataItemInterface {
    return this.data[key] || null;
  }

  set(key: string, value: HintDataItemInterface): void {
    this.data[key] = value;
  }

  delete(key: string): void {
    delete this.data[key];
  }

  openDialog(item: HintItemInterface, opts?: MatDialogConfig<any>) {
    item = new HintItem(item);

    // console.log('open item0', item);

    const dialogOptions = Object.assign(
      {
        panelClass: ['hint-dialog'], //, 'yellow-100-bg'],
      },
      opts,
    );

    dialogOptions.data = item;

    if (item.video) {
      dialogOptions.panelClass.push('hint-video-dialog');
    }

    this._matDialog.open(HintDialogComponent, dialogOptions);
  }
}
