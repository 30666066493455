import { NgModule } from '@angular/core';
import { InputClearableDirective } from './input-clearable.directive';
import { InputClearComponentModule } from './input-clear/input-clear.module';

@NgModule({
  declarations: [
    InputClearableDirective
  ],
  imports: [
    InputClearComponentModule
  ],
  exports: [
    InputClearableDirective
  ]
})
export class InputOptionsModule {}
